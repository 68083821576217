import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PageIndexerService {
  private _indexes: any = {};

  constructor() {}

  public getIndex(name: string) {
    return this._indexes[name] || 0;
  }

  public setIndex(name: string, value: number) {
    this._indexes[name] = value;
  }
}
