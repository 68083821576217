<ekiba-nav-table
  [addItemName]="'Añadir usuario'"
  [showFilter]="false"
  (addedItem)="addUser()"
>
</ekiba-nav-table>
<div class="page-container">
  <div class="page-container__header">
    <span>{{ Labels.Listado }}</span>
  </div>
  <div class="page-container__body">
    <ekiba-master-table
      *ngIf="data$ | async as data"
      [dataSource]="formattedUsers"
      [showFilters]="true"
      [showEditButton]="false"
      [columns]="columns"
      [redirection]="editUrl"
      [redirectionType]="RedirectionType.Edit"
      (deletedRow)="deleteUser($event)"
    >
    </ekiba-master-table>
  </div>
</div>
