import { Domains, FieldTypes } from 'src/app/constants/constants';
import {
  EntityField,
  EntityFieldGroup,
} from 'src/app/pages/add-edit-entity/add-edit-entity.component';
import { environment } from 'src/environments/environment';

export const PRODUCTOS_FIELDS: EntityField[] = [
  {
    name: 'nombre',
    displayName: 'Nombre',
    type: FieldTypes.Translatable,
    editDisabledForDefaultTranslationEntityField: true,
    group: 1,
  },
  {
    name: 'urlkey',
    displayName: 'Url',
    type: FieldTypes.Translatable,
    editDisabledForDefaultTranslationEntityField: true,
    group: 1,
  },
  {
    name: 'filler2',
    displayName: 'Filler',
    type: FieldTypes.Filler,
    group: 1,
  },
  {
    name: 'filler3',
    displayName: 'Filler',
    type: FieldTypes.Filler,
    group: 1,
  },
  {
    name: 'cuadro',
    displayName: 'Cuadro',
    type: FieldTypes.Selector,
    isSingleSelector: true,
    required: true,
    optionsUrl: `${environment.api}${Domains.Dropdown}?table=${Domains.Cuadros}`,
    doubleLength: true,
    disabled: true,
    group: 1,
  },
  {
    name: 'grupoCambio',
    displayName: 'Grupo de Cambio',
    type: FieldTypes.Selector,
    isSingleSelector: true,
    required: true,
    optionsUrl: `${environment.api}${Domains.Dropdown}?table=${Domains.GruposDeCambio}`,
    doubleLength: true,
    disabled: true,
    group: 1,
  },
  {
    name: 'descripcion',
    displayName: 'Descripción',
    type: FieldTypes.TranslatableTextarea,
    group: 1,
  },
  {
    name: 'horquilla',
    displayName: 'Horquilla',
    type: FieldTypes.Selector,
    isSingleSelector: true,
    optionsUrl: `${environment.api}${Domains.DropdownComponent}?tipo=Horquilla`,
    doubleLength: true,
    disabled: true,
    group: 2,
  },
  {
    name: 'material',
    displayName: 'Material',
    type: FieldTypes.Selector,
    isSingleSelector: true,
    optionsUrl: `${environment.api}${Domains.DropdownComponent}?tipo=Material`,
    doubleLength: true,
    disabled: true,
    group: 2,
  },
  {
    name: 'manillar',
    displayName: 'Manillar',
    type: FieldTypes.Selector,
    isSingleSelector: true,
    optionsUrl: `${environment.api}${Domains.DropdownComponent}?tipo=Manillar`,
    doubleLength: true,
    disabled: true,
    group: 2,
  },
  {
    name: 'potencia',
    displayName: 'Potencia',
    type: FieldTypes.Selector,
    isSingleSelector: true,
    optionsUrl: `${environment.api}${Domains.DropdownComponent}?tipo=Potencia`,
    doubleLength: true,
    disabled: true,
    group: 2,
  },
  {
    name: 'tijaSillin',
    displayName: 'Tija de sillín',
    type: FieldTypes.Selector,
    isSingleSelector: true,
    optionsUrl: `${environment.api}${Domains.DropdownComponent}?tipo=Tija sillín`,
    doubleLength: true,
    disabled: true,
    group: 2,
  },
  {
    name: 'sillin',
    displayName: 'Sillín',
    type: FieldTypes.Selector,
    isSingleSelector: true,
    optionsUrl: `${environment.api}${Domains.DropdownComponent}?tipo=Sillín`,
    doubleLength: true,
    disabled: true,
    group: 2,
  },
  {
    name: 'rearShox',
    displayName: 'Rear Shox',
    type: FieldTypes.Selector,
    isSingleSelector: true,
    optionsUrl: `${environment.api}${Domains.DropdownComponent}?tipo=Rear Shox`,
    doubleLength: true,
    disabled: true,
    group: 2,
  },
  {
    name: 'patilla',
    displayName: 'Patilla',
    type: FieldTypes.Selector,
    isSingleSelector: true,
    optionsUrl: `${environment.api}${Domains.DropdownComponent}?tipo=Patilla`,
    doubleLength: true,
    disabled: true,
    group: 2,
  },
  {
    name: 'motor',
    displayName: 'Motor',
    type: FieldTypes.Selector,
    isSingleSelector: true,
    optionsUrl: `${environment.api}${Domains.DropdownComponent}?tipo=Motor`,
    doubleLength: true,
    disabled: true,
    group: 2,
  },
  {
    name: 'bateria',
    displayName: 'Batería',
    type: FieldTypes.Selector,
    isSingleSelector: true,
    optionsUrl: `${environment.api}${Domains.DropdownComponent}?tipo=Bateria`,
    doubleLength: true,
    disabled: true,
    group: 2,
  },
  {
    name: 'pantalla',
    displayName: 'Pantalla',
    type: FieldTypes.Selector,
    isSingleSelector: true,
    optionsUrl: `${environment.api}${Domains.DropdownComponent}?tipo=Pantalla`,
    doubleLength: true,
    disabled: true,
    group: 2,
  },
  {
    name: 'cargador',
    displayName: 'Cargador',
    type: FieldTypes.Selector,
    isSingleSelector: true,
    optionsUrl: `${environment.api}${Domains.DropdownComponent}?tipo=Cargador`,
    doubleLength: true,
    disabled: true,
    group: 2,
  },
  {
    name: 'desviador',
    displayName: 'Desviador',
    type: FieldTypes.Selector,
    isSingleSelector: true,
    optionsUrl: `${environment.api}${Domains.DropdownComponent}?tipo=Desviador`,
    doubleLength: true,
    disabled: true,
    group: 2,
  },
  {
    name: 'neumatico',
    displayName: 'Neumático',
    type: FieldTypes.Selector,
    isSingleSelector: true,
    optionsUrl: `${environment.api}${Domains.DropdownComponent}?tipo=Neumático`,
    doubleLength: true,
    disabled: true,
    group: 2,
  },
  {
    name: 'cambio',
    displayName: 'Cambio',
    type: FieldTypes.Selector,
    isSingleSelector: true,
    optionsUrl: `${environment.api}${Domains.DropdownComponent}?tipo=Cambio`,
    doubleLength: true,
    disabled: true,
    group: 3,
  },
  {
    name: 'maneta',
    displayName: 'Manetas',
    type: FieldTypes.Selector,
    isSingleSelector: true,
    optionsUrl: `${environment.api}${Domains.DropdownComponent}?tipo=Manetas`,
    doubleLength: true,
    disabled: true,
    group: 3,
  },
  {
    name: 'pinon',
    displayName: 'Piñones',
    type: FieldTypes.Selector,
    isSingleSelector: true,
    optionsUrl: `${environment.api}${Domains.DropdownComponent}?tipo=Piñones`,
    doubleLength: true,
    disabled: true,
    group: 3,
  },
  {
    name: 'cadena',
    displayName: 'Cadena',
    type: FieldTypes.Selector,
    isSingleSelector: true,
    optionsUrl: `${environment.api}${Domains.DropdownComponent}?tipo=Cadena`,
    doubleLength: true,
    disabled: true,
    group: 3,
  },
  {
    name: 'biela',
    displayName: 'Biela',
    type: FieldTypes.Selector,
    isSingleSelector: true,
    optionsUrl: `${environment.api}${Domains.DropdownComponent}?tipo=Biela`,
    doubleLength: true,
    disabled: true,
    group: 3,
  },
  {
    name: 'freno',
    displayName: 'Frenos',
    type: FieldTypes.Selector,
    isSingleSelector: true,
    optionsUrl: `${environment.api}${Domains.DropdownComponent}?tipo=Frenos`,
    doubleLength: true,
    disabled: true,
    group: 3,
  },
  {
    name: 'productoRelacionado',
    displayName: 'Producto relacionado',
    type: FieldTypes.Selector,
    isSingleSelector: true,
    optionsUrl: `${environment.api}gp_producto`,
    doubleLength: true,
    disabled: false,
    group: 3,
  },
];

export const PRODUCTOS_GROUPS: EntityFieldGroup[] = [
  {
    label: 'Información básica del producto',
    id: 1,
  },
  {
    label: 'Información del cuadro',
    id: 2,
  },
  {
    label: 'Información del grupo de cambio',
    id: 3,
  },
];

export const PRIMES_BASE_URL: string = `${environment.api}${Domains.PRIMES}`;
export const MODELOS_BASE_URL: string = `${environment.api}${Domains.Modelos}`;
export const ATRIBUTOS_BASE_URL: string = `${environment.api}${Domains.Atributos}`;
export const VARIANTES_BASE_URL: string = `${environment.api}${Domains.Variantes}`;
export const DISPONIBILIDAD_BASE_URL: string = `${environment.api}${Domains.Disponibilidad}`;
export const PLAN_DE_PRODUCCION_BASE_URL: string = `${environment.api}${Domains.PlanDeProduccion}`;
export const PRODUCTO_TRADUCCIONES_URL: string = `${environment.api}${Domains.TraduccionesShared}?table=gp_producto`;
