import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { Rutas, SKIP_AUTH } from '../constants/constants';
import { AuthService } from '../shared/auth.service';

@Injectable({ providedIn: 'root' })
export class AuthorizationGuard implements CanActivate {
  constructor(
    private readonly _authService: AuthService,
    private readonly _router: Router
  ) {}

  public canActivate(
    _route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ): boolean | Observable<boolean> {
    if (SKIP_AUTH) return true;
    return this._authService.authPermissions$.pipe(
      filter((value) => !!(value && value.length && value.length > 0)),
      map((value) => {
        const permissions = value;
        if (!permissions || !permissions.length || permissions.length === 0) {
          return this._goToNotLoged();
        }
        const urlWithoutId = _state.url.replace(/[0-9]/g, '');
        if (
          !permissions.some(
            (_route) =>
              `/${_route.ruta}` === urlWithoutId || // Without id
              `/${_route.ruta}/` === urlWithoutId // With id
          )
        ) {
          return this._goToNotAllowed();
        }
        return true;
      })
    );
  }

  private _goToNotAllowed(): boolean {
    this._router.navigate([Rutas.NoAutorizado]);
    return false;
  }

  private _goToNotLoged(): boolean {
    this._router.navigate([Rutas.Login]);
    return false;
  }
}
