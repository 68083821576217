import { ColumnTypes, TableHeaders } from 'ekiba-master-table';
import { Domains } from 'src/app/constants/constants';
import { AbstractBerria } from 'src/app/constants/models';
import { environment } from 'src/environments/environment';

export const TABLE_HEADERS_CUADROS: TableHeaders[] = [
  {
    name: 'id',
    displayName: 'ID',
    type: ColumnTypes.Standard,
  },
  {
    name: 'codigo',
    displayName: 'Código',
    type: ColumnTypes.Standard,
  },
  {
    name: 'nombre',
    displayName: 'Nombre',
    type: ColumnTypes.Standard,
  },
  {
    name: 'descripcion',
    displayName: 'Descripción',
    type: ColumnTypes.Standard,
  },
];

export const CUADROS_BASE_URL: string = `${environment.api}${Domains.Cuadros}`;

export interface Cuadro extends AbstractBerria {
  nombre: string;
}
