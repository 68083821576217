<h1 mat-dialog-title>
  {{ data?.action === "add" ? "Añadir" : "Editar" }} Atributo
</h1>
<div mat-dialog-content>
  <div class="dropdowns-container">
    <mat-form-field
      floatLabel="always"
      appearance="outline"
      *ngIf="atributos$ | async as atributos"
    >
      <mat-label>Atributo</mat-label>
      <mat-select
        [(ngModel)]="atributo"
        [compareWith]="objectComparisonFunctionAtributo"
        (selectionChange)="changeAtributo($event.value)"
      >
        <mat-option *ngFor="let option of atributos" [value]="option">
          {{ option?.nombre || option?.descripcion || option }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <ng-container *ngIf="atributo">
      <mat-form-field
        floatLabel="always"
        appearance="outline"
        *ngIf="valores$ | async as valores"
      >
        <mat-label>Valor</mat-label>
        <mat-select
          [(ngModel)]="valor"
          [compareWith]="objectComparisonFunction"
        >
          <mat-option *ngFor="let option of valores" [value]="option">
            {{ option?.nombre || option?.descripcion || option }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>
  </div>
</div>
<div class="buttons-section" mat-dialog-actions>
  <button
    type="submit"
    class="cancel-button"
    mat-raised-button
    color="secondary"
    (click)="closeDialog()"
  >
    Salir
  </button>
  <button
    type="submit"
    class="confirm-button"
    mat-raised-button
    color="primary"
    (click)="confirm()"
  >
    Confirmar
  </button>
</div>
