import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import {
  WarningPopupComponent,
  WarningPopupInput,
} from '../popups/warning/warning-popup.component';

const SUCCESS_KEY = 'success';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  public constructor(private readonly _dialog: MatDialog) {}

  public intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap((genericResponse: any) => {
        if (
          genericResponse &&
          genericResponse.body &&
          SUCCESS_KEY in genericResponse.body &&
          genericResponse.body[SUCCESS_KEY] === false
        ) {
          const data: WarningPopupInput = {
            headerMessage: '🚧 Advertencia 🚧',
            errorMessage: genericResponse.body.errorMessage,
            conflictLines: genericResponse.body.conflictLines,
          };
          this._dialog.open(WarningPopupComponent, {
            width: '800px',
            data,
          });
        }
      }),
      catchError((error) => {
        if (
          !(
            error instanceof HttpErrorResponse &&
            (error.error === 401 || error.status === 401)
          ) &&
          error.error !== 500 &&
          error.status !== 0
        ) {
          const data: WarningPopupInput = {
            headerMessage: `🚧 Error ${
              error.error?.statusCode || error.status
            } 🚧`,
            errorMessage: `${
              error.error?.error ? error.error?.error + ':' : ''
            } ${error.error?.message || error.error?.errorMessage}`,
          };
          this._dialog.open(WarningPopupComponent, {
            width: '800px',
            data,
          });
        } else if (
          !(error instanceof HttpErrorResponse) ||
          error.error === 500 ||
          error.status === 0
        ) {
          const data: WarningPopupInput = {
            headerMessage: `🚧 Error de conexión 🚧`,
            errorMessage: `No se pudo establecer la conexión con el servidor. Por favor, vuelva a intentarlo en unos minutos.`,
          };
          this._dialog.open(WarningPopupComponent, {
            width: '800px',
            data,
          });
        }

        return throwError(error);
      })
    );
  }
}
