<ekiba-nav-table
  [addItemName]="'Guardar'"
  [showFilter]="false"
  (addedItem)="saveData()"
>
</ekiba-nav-table>
<div class="page-container" style="margin-bottom: 20px">
  <div class="page-container__header">
    <span
      >Información de cuadro:
      {{ entityToEdit?.nombre || entityForm?.get("nombre")?.value }}</span
    >
  </div>
  <div
    class="page-container__body"
    *ngIf="action === 'add' || (action === 'edit' && entityToEdit)"
  >
    <form class="flex-container" *ngIf="entityForm" [formGroup]="entityForm">
      <ng-container *ngFor="let field of fields">
        <ekiba-translatable-field
          *ngIf="field.type === fieldTypes.Translatable"
          [campo]="field.name"
          [languages]="idiomas$"
          tabla="gp_cuadro"
          [translations]="traducciones$"
          [disabled]="field.disabled || false"
          [required]="field.required || false"
          [defaultValue]="entityForm.get(field.name)!.value"
          [displayName]="field.displayName"
          [touched]="touched$"
        ></ekiba-translatable-field>
        <ekiba-translatable-textarea
          *ngIf="field.type === fieldTypes.TranslatableTextarea"
          [campo]="field.name"
          [languages]="idiomas$"
          tabla="gp_cuadro"
          [translations]="traducciones$"
          [disabled]="field.disabled || false"
          [required]="field.required || false"
          [defaultValue]="entityForm.get(field.name)!.value"
          [displayName]="field.displayName"
          [touched]="touched$"
        ></ekiba-translatable-textarea>
        <div
          *ngIf="field.type === fieldTypes.Filler"
          class="filler-field"
        ></div>
        <mat-form-field
          floatLabel="always"
          *ngIf="field.type === fieldTypes.Standard"
          appearance="outline"
        >
          <mat-label
            >{{ field.displayName }}{{ field.required && "*" }}</mat-label
          >
          <input matInput [formControlName]="field.name" autocomplete="off" />
        </mat-form-field>
        <ekiba-dropdown
          *ngIf="field.type === fieldTypes.Selector"
          [selectedArray]="field.options!"
          [loadedValues]="entityForm.get(field.name)!.value"
          [multipleSelect]="!field.isSingleSelector"
          [placeholder]="
            field.isSingleSelector
              ? 'Selecciona un elemento'
              : 'Selecciona elementos'
          "
          [noEntriesFound]="'No se encontraron elementos'"
          title="{{ field.displayName }}{{ field.required && '*' }}"
          (changedValue)="updateField($event, field)"
          [includeEmptyOption]="true"
          floatLabel="always"
          [required]="field.required || false"
          [disabled]="field.disabled || false"
          [touched]="touched$"
        >
        </ekiba-dropdown>
        <mat-checkbox
          *ngIf="field.type === fieldTypes.Boolean"
          [formControlName]="field.name"
          color="primary"
          >{{ field.displayName }}{{ field.required && "*" }}</mat-checkbox
        >
        <div *ngIf="field.type === fieldTypes.Image">
          <mat-label
            >{{ field.displayName }}{{ field.required && "*" }}</mat-label
          >
          <input type="file" (change)="fileChange($event)" />
        </div>
        <p *ngIf="field.type === fieldTypes.Image">
          Previsualización de {{ field.displayName }}
        </p>
        <img
          class="image-field-preview"
          [src]="entityForm.get(field.name)?.value"
          *ngIf="field.type === fieldTypes.Image"
        />
      </ng-container>
      <div class="filler-field"></div>
      <div class="filler-field"></div>
    </form>
    <div class="flex-container" style="margin-top: 20px">
      <div class="table-container">
        <h2>Servicios de pintura y colores</h2>
        <ekiba-nav-table
          [addItemName]="'Añadir Servicio de pintura'"
          [showFilter]="false"
          (addedItem)="addServicioDePintura()"
          class="negative-margin"
        >
        </ekiba-nav-table>
        <table
          style="margin-bottom: 20px"
          class="custom-table"
          *ngIf="serviciosDePintura$ | async as sdps"
        >
          <tr>
            <th class="align-left">Nombre</th>
            <th class="align-right">Por defecto</th>
            <th class="align-center">Acciones</th>
          </tr>
          <tr *ngIf="!sdps?.length">
            <td colspan="3" class="align-left">
              No hay servicios de pintura en este cuadro
            </td>
          </tr>
          <tr *ngFor="let sdp of getNonDeletedSdps()">
            <td class="align-left">
              {{ sdp.servicioPintura?.nombre }}
            </td>
            <td class="align-left">
              {{ sdp?.default ? "Sí" : "No" }}
            </td>

            <td class="actions-column">
              <button
                class="configurar-colores-button"
                (click)="configureColors(sdp)"
              >
                <span class="material-icons configurar-colores-button__icon">
                  settings
                </span>
                <span class="configurar-colores-button__text"
                  >Configurar colores</span
                >
              </button>
              <mat-icon
                class="edit-sdp-button"
                (click)="editServicioDePintura(sdp)"
                >edit</mat-icon
              >
              <mat-icon
                class="edit-sdp-button"
                (click)="deleteServicioDePintura(sdp)"
                >delete</mat-icon
              >
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</div>

<div class="page-container" style="margin-bottom: 20px">
  <div class="page-container__header page-container__header--grey">
    <span>Especificaciones</span>
  </div>
  <div class="page-container__body">
    <h2>{{ labels.Geometria }}</h2>
    <div class="flex-container">
      <div class="geometria-and-ergonomia-container">
        <table
          style="margin-bottom: 20px"
          class="custom-table"
          *ngIf="geometrias$ | async as geometrias"
        >
          <tr>
            <th colspan="3" class="align-left">Nombre</th>
            <th *ngFor="let header of geometriasHeaders">{{ header }}</th>
          </tr>
          <tr *ngIf="!geometrias?.length">
            <td colspan="3" class="align-left">
              No hay geometrías en este cuadro
            </td>
          </tr>
          <tr *ngFor="let geometria of geometrias">
            <td colspan="3" class="align-left">
              {{ geometria.nombre || geometria.tipo?.nombre }}
            </td>
            <td class="align-center" *ngFor="let talla of geometria.tallas">
              <input type="text" class="cell-input" [(ngModel)]="talla.valor" />
            </td>
          </tr>
        </table>

        <h2>{{ labels.Ergonomia }}</h2>
        <table class="custom-table" *ngIf="ergonomias$ | async as ergonomias">
          <tr>
            <th colspan="3" class="align-left">Nombre</th>
            <th *ngFor="let header of ergonomiasHeaders">{{ header }}</th>
          </tr>
          <tr *ngIf="!ergonomias?.length">
            <td colspan="3" class="align-left">
              No hay ergonomias en este cuadro
            </td>
          </tr>
          <tr *ngFor="let ergonomia of ergonomias">
            <td
              colspan="3"
              class="align-left"
              *ngIf="ergonomia.celltype !== 'editable'"
            >
              {{ ergonomia.nombre || ergonomia.tipo?.nombre }}
            </td>
            <td colspan="3" *ngIf="ergonomia.celltype === 'editable'">
              <input
                type="text"
                class="cell-input cell-input--full-width"
                [(ngModel)]="ergonomia.nombre"
              />
            </td>
            <td class="align-center" *ngFor="let talla of ergonomia.tallas">
              <input type="text" class="cell-input" [(ngModel)]="talla.valor" />
            </td>
          </tr>
        </table>
      </div>

      <div class="image-and-save-button-container">
        <div class="cuadro-image-container">
          <div
            style="
              visibility: hidden;
              position: absolute;
              top: -20000px;
              left: -20000px;
            "
          >
            <input
              type="file"
              (change)="fileChange($event)"
              #geometriaImagenInput
            />
          </div>
          <img
            *ngIf="entityToEdit && entityToEdit['geometriaImagen-image']"
            [src]="entityToEdit && entityToEdit['geometriaImagen-image']"
          />
          <mat-icon
            *ngIf="entityToEdit && entityToEdit['geometriaImagen-image']"
            (click)="deleteImage()"
            class="delete-image-button"
            mat-mini-fab
            >delete</mat-icon
          >
          <button
            class="update-image-button"
            (click)="geometriaImagenInput.click()"
          >
            {{
              entityToEdit && entityToEdit["geometriaImagen-image"]
                ? "Actualizar"
                : "Añadir imagen"
            }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<ekiba-nav-table
  [addItemName]="'Guardar'"
  [showFilter]="false"
  (addedItem)="saveData()"
>
</ekiba-nav-table>
