import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { filter, map, tap } from 'rxjs/operators';
import { Domains } from 'src/app/constants/constants';
import { EntityApiCalls } from 'src/app/shared/entity.api-calls';
import { environment } from 'src/environments/environment';
import { AddEditEntityComponent } from '../../add-edit-entity/add-edit-entity.component';
import {
  DISPONIBILIDAD_BASE_URL,
  PLAN_DE_PRODUCCION_BASE_URL,
} from '../productos/add-edit-producto/add-edit-producto.constants';

@Component({
  templateUrl: './edit-variante.component.html',
  styleUrls: [
    './variantes.component.scss',
    '../../add-edit-entity/add-edit-entity.component.scss',
  ],
})
export class EditVarianteComponent
  extends AddEditEntityComponent
  implements OnInit
{
  public disponibilidades$: Observable<any[]> | undefined;
  public planDeProduccion$: Observable<any[]> | undefined;

  constructor(
    protected readonly route: ActivatedRoute,
    protected readonly api: EntityApiCalls,
    protected readonly router: Router,
    protected readonly dialog: MatDialog
  ) {
    super(route, api, router, dialog);
    this.formBuilt$
      .pipe(
        filter((formBuilt$) => formBuilt$),
        tap(() => {
          if (this._entityToEdit) {
            const tallas$ = this.api.getData(
              `${environment.api}${Domains.Dropdown}?table=${Domains.Tallas}`
            );
            tallas$.subscribe((tallas: any) => {
              this.disponibilidades$ = this.api
                .getData(DISPONIBILIDAD_BASE_URL)
                .pipe(
                  map((disponibilidades: any) => {
                    return disponibilidades.filter(
                      (disponibilidad: any) =>
                        disponibilidad.variante?.id === this._entityToEdit.id
                    );
                  }),
                  tap((disponibilidades: any) => {
                    disponibilidades.forEach((disponibilidad: any) => {
                      disponibilidad.variante.talla = tallas.find(
                        (talla: any) =>
                          talla.id === disponibilidad.variante?.tallaId
                      )?.nombre;
                      disponibilidad.colorFondo.tipo = 'Fondo';
                      disponibilidad.colorCalca1.tipo = 'Calca 1';
                      disponibilidad.colorCalca2.tipo = 'Calca 2';
                      disponibilidad.colorColor.tipo = 'Color';
                      disponibilidad.colorAcabado.tipo = 'Acabado';
                    });
                  })
                );

              this.planDeProduccion$ = this.api
                .getData(PLAN_DE_PRODUCCION_BASE_URL)
                .pipe(
                  map((planesDeProduccion: any) => {
                    return planesDeProduccion.filter(
                      (planDeProduccion: any) =>
                        planDeProduccion.variante?.id === this._entityToEdit?.id
                    );
                  }),
                  tap((planesDeProduccion: any) => {
                    planesDeProduccion.forEach((planDeProduccion: any) => {
                      if (planDeProduccion.variante) {
                        planDeProduccion.variante.talla = tallas.find(
                          (talla: any) =>
                            talla.id === planDeProduccion.variante?.tallaId
                        )?.nombre;
                      }
                      if (planDeProduccion.colorFondo)
                        planDeProduccion.colorFondo.tipo = 'Fondo';
                      if (planDeProduccion.colorCalca1)
                        planDeProduccion.colorCalca1.tipo = 'Calca 1';
                      if (planDeProduccion.colorCalca2)
                        planDeProduccion.colorCalca2.tipo = 'Calca 2';
                      if (planDeProduccion.colorColor)
                        planDeProduccion.colorColor.tipo = 'Color';
                      if (planDeProduccion.colorAcabado)
                        planDeProduccion.colorAcabado.tipo = 'Acabado';
                    });
                  })
                );
            });
          }
        })
      )
      .subscribe();
  }
}
