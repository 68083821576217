import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Rutas, Labels } from 'src/app/constants/constants';
import { Entity } from 'src/app/shared/entity';
import { Role } from 'src/app/constants/models';
import { ROLES_BASE_URL, TABLE_HEADERS_ROLES } from './roles.constants';
import { RedirectionType } from 'ekiba-master-table';
@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
})
export class RolesComponent extends Entity implements OnInit {
  public readonly Labels: typeof Labels = Labels;
  public readonly RedirectionType: typeof RedirectionType = RedirectionType;
  public readonly editUrl: string = `${Rutas.Usuarios}/${Rutas.Roles}/${Rutas.Edit}`;
  constructor(private router: Router) {
    super();
  }

  public ngOnInit(): void {
    this.columns = TABLE_HEADERS_ROLES;
    this.data$ = this.getData();
    this._baseUrl = ROLES_BASE_URL;
    this._loadData();
  }

  public ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  public addRole(): void {
    this.router.navigate([`${Rutas.Usuarios}/${Rutas.Roles}/${Rutas.Add}`]);
  }

  public deleteRole(role: Role): void {
    this._deleteData(role);
  }
}
