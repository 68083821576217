import { Component, Inject, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Action } from './activate-producto-modelos-variantes.constants';

export interface ActivateProductoModelosVariantesInput {
  productoId: number;
  message: string;
}

export interface ActivateProductoModelosVariantesOutput {
  event: string;
  activarModelosB2b: boolean;
  activarVariantesB2b: boolean;
  activarModelosB2c: boolean;
  activarVariantesB2c: boolean;
}

@Component({
  templateUrl: './activate-producto-modelos-variantes.popup.html',
  styleUrls: ['./activate-producto-modelos-variantes.popup.scss'],
})
export class ActivateProductoModelosVariantesPopup {
  public cancelled = false;
  public activarModelosB2b: boolean = false;
  public activarVariantesB2b: boolean = false;
  public activarModelosB2c: boolean = false;
  public activarVariantesB2c: boolean = false;

  public constructor(
    public dialogRef: MatDialogRef<ActivateProductoModelosVariantesPopup>,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: ActivateProductoModelosVariantesInput
  ) {
    dialogRef.disableClose = true;
  }

  public closeDialog(): void {
    this.cancelled = true;
    this.dialogRef.close({ event: Action.cancel });
  }

  public confirm(): void {
    this.dialogRef.close({
      event: Action.confirm,
      activarModelosB2b: this.activarVariantesB2b,
      activarVariantesB2b: this.activarVariantesB2b,
      activarModelosB2c: this.activarVariantesB2c,
      activarVariantesB2c: this.activarVariantesB2c,
    });
  }
}
