import { Component, Inject, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Domains } from 'src/app/constants/constants';
import { EntityApiCalls } from 'src/app/shared/entity.api-calls';
import { environment } from 'src/environments/environment';

export interface AddEditProductoPrimePopupInput {
  action: string;
  prime: any;
}

export interface AddEditProductoPrimePopupOutput {
  prime: any;
}

@Component({
  templateUrl: './add-edit-producto-prime.component.html',
  styleUrls: ['./add-edit-producto-prime.component.scss'],
})
export class AddEditProductoPrimePopup {
  prime: any;
  primes$: Observable<any> | undefined;
  default: boolean = false;

  public constructor(
    public dialogRef: MatDialogRef<AddEditProductoPrimePopup>,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: AddEditProductoPrimePopupInput,
    private _api: EntityApiCalls
  ) {
    this.primes$ = this._api
      .getData(`${environment.api}${Domains.Dropdown}?table=${Domains.PRIMES}`)
      .pipe(
        map((primes: any) => {
          return primes.map((prime: any) => {
            return { nombre: prime.nombre, primeId: prime.id };
          });
        })
      );
    this.prime = this.data.prime;
    this.default = this.prime?.default;
  }

  public closeDialog(): void {
    this.dialogRef.close(false);
  }

  public confirm(): void {
    this.dialogRef.close({
      prime: { ...this.prime, default: this.default },
    });
  }

  public objectComparisonFunction(item: any, selected: any) {
    return typeof item === 'object'
      ? item.primeId === selected.primeId
      : item === selected;
  }
}
