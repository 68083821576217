import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RedirectionType } from 'ekiba-master-table';

import { Labels, Rutas, SKIP_AUTH } from 'src/app/constants/constants';
import { AuthService } from 'src/app/shared/auth.service';
import { Entity } from 'src/app/shared/entity';
import { PageIndexerService } from 'src/app/shared/page-indexer.service';
import {
  Categoria,
  CATEGORIAS_BASE_URL,
  TABLE_HEADERS_CATEGORIAS,
} from './categorias.constants';
@Component({
  templateUrl: './categorias.component.html',
})
export class CategoriasComponent extends Entity implements OnInit {
  categorias: Categoria[] = [];
  formattedCategorias!: Categoria[];
  public readonly Labels: typeof Labels = Labels;
  public readonly Rutas: typeof Rutas = Rutas;
  public readonly RedirectionType: typeof RedirectionType = RedirectionType;
  public readonly authURL: string = `${Rutas.ConfiguracionDeProductos}/${Rutas.Categorias}`;
  public readonly editUrl: string = `${this.authURL}/${Rutas.Edit}`;
  public SKIP_AUTH: boolean = SKIP_AUTH;

  constructor(
    public auth: AuthService,
    public pageIndexer: PageIndexerService,
    private _router: Router
  ) {
    super();
  }

  public ngOnInit(): void {
    this.columns = TABLE_HEADERS_CATEGORIAS;
    this._baseUrl = CATEGORIAS_BASE_URL;
    this.data$ = this.getData();
    this._loadData();
    this.data$.subscribe((data) => {
      this.categorias = data;
      this.formattedCategorias = this.formatCategorias(data);
    });
  }

  public formatCategorias(data: Categoria[]): Categoria[] {
    return data.map((item) => {
      return {
        ...item,
        padre: item.padre?.nombre,
        activoB2b: !!item.activoB2b,
        activoB2c: !!item.activoB2c,
        rutaCompleta: this._getNombreCompleto(item.id),
      };
    });
  }

  private _getNombreCompleto(categoriaId: any) {
    let categoria: Categoria;
    categoria = this.categorias.find((c: Categoria) => c.id === categoriaId)!;
    let nombre = categoria?.nombre;
    if (categoria?.padre) {
      nombre = `${this._getNombreCompleto(categoria.padreId)} > ${nombre}`;
    }
    return nombre;
  }

  public ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  public add(): void {
    this._router.navigate([
      `${Rutas.ConfiguracionDeProductos}/${Rutas.Categorias}/${Rutas.Add}`,
    ]);
  }

  public delete(categoria: Categoria): void {
    this._deleteData(categoria);
  }
}
