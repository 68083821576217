import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Labels, Rutas, SKIP_AUTH } from 'src/app/constants/constants';
import { AuthService } from 'src/app/shared/auth.service';
import { Entity } from 'src/app/shared/entity';
import { PageIndexerService } from 'src/app/shared/page-indexer.service';
import {
  TAREAS_BASE_URL,
  TABLE_HEADERS_TAREAS,
  Tareas,
} from './tareas.constants';
@Component({
  templateUrl: './tareas.component.html',
  styleUrls: ['./tareas.component.scss'],
})
export class TareasComponent extends Entity implements OnInit {
  formattedTareas!: Tareas[];
  public readonly Labels: typeof Labels = Labels;
  public readonly Rutas: typeof Rutas = Rutas;
  public readonly authURL: string = `${Rutas.Integraciones}/${Rutas.Tareas}`;
  public SKIP_AUTH: boolean = SKIP_AUTH;

  constructor(
    public auth: AuthService,
    private _router: Router,
    public pageIndexer: PageIndexerService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.columns = TABLE_HEADERS_TAREAS;
    this._baseUrl = TAREAS_BASE_URL;
    this.data$ = this.getData();
    this._loadData();
    this.data$.subscribe((data) => {
      this.formattedTareas = this.formatTareas(data);
    });
  }

  public formatTareas(data: Tareas[]): Tareas[] {
    return data;
  }

  public ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  public add(): void {
    this._router.navigate([
      `${Rutas.Integraciones}/${Rutas.Tareas}/${Rutas.Add}`,
    ]);
  }

  public delete(lareas: Tareas): void {
    this._deleteData(lareas);
  }

  public update(lareas: Tareas): void {
    this._router.navigate([
      `${Rutas.Integraciones}/${Rutas.Tareas}/${Rutas.Edit}/${lareas.id}`,
    ]);
  }
}
