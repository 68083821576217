<h1 mat-dialog-title>
  {{ data?.action === "add" ? "Añadir" : "Editar" }} Biela
</h1>
<div mat-dialog-content>
  <mat-form-field
    floatLabel="always"
    appearance="outline"
    *ngIf="bielas$ | async as bielas"
  >
    <mat-label>Biela</mat-label>
    <mat-select [(ngModel)]="biela" [compareWith]="objectComparisonFunction">
      <mat-option *ngFor="let option of bielas" [value]="option">
        {{ option?.nombre || option?.descripcion || option }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-checkbox *ngIf="biela" color="primary" [(ngModel)]="default"
    >Es biela por defecto</mat-checkbox
  >
</div>
<div class="buttons-section" mat-dialog-actions>
  <button
    type="submit"
    class="cancel-button"
    mat-raised-button
    color="secondary"
    (click)="closeDialog()"
  >
    Salir
  </button>
  <button
    type="submit"
    class="confirm-button"
    mat-raised-button
    color="primary"
    (click)="confirm()"
  >
    Confirmar
  </button>
</div>
