import { Component, OnInit } from '@angular/core';

@Component({
  templateUrl: './no-autorizado.component.html',
  styleUrls: ['./no-autorizado.component.scss'],
})
export class NoAutorizadoComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
