import { Component, Inject, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { Domains } from 'src/app/constants/constants';
import { EntityApiCalls } from 'src/app/shared/entity.api-calls';
import { environment } from 'src/environments/environment';

export interface AddEditProductoModeloPopupInput {
  action: string;
  modelo: any;
}

export interface AddEditProductoModeloPopupOutput {
  modelo: any;
}

@Component({
  templateUrl: './add-edit-producto-modelo.component.html',
  styleUrls: ['./add-edit-producto-modelo.component.scss'],
})
export class AddEditProductoModeloPopup {
  modelo: any;
  modelos$: Observable<any> | undefined;
  ruedas$: Observable<any> | undefined;
  rueda: any;
  orden: any;

  public constructor(
    public dialogRef: MatDialogRef<AddEditProductoModeloPopup>,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: AddEditProductoModeloPopupInput,
    private _api: EntityApiCalls
  ) {
    this.modelos$ = this._api.getData(
      `${environment.api}${Domains.Dropdown}?table=${Domains.Modelos}`
    );
    this.ruedas$ = this._api.getData(
      `${environment.api}${Domains.Dropdown}?table=${Domains.Ruedas}`
    );
    this.modelo = this.data.modelo;
    this.rueda = this.data.modelo?.rueda;
    this.orden = this.data.modelo.orden;
  }

  public closeDialog(): void {
    this.dialogRef.close(false);
  }

  public confirm(): void {
    this.dialogRef.close({
      modelo: {
        ...this.modelo,
        rueda: this.rueda,
        orden: this.orden,
      },
    });
  }

  public objectComparisonFunction(item: any, selected: any) {
    return typeof item === 'object'
      ? item.id === selected.id
      : item === selected;
  }
}
