import { Component, OnInit } from '@angular/core';
import { RedirectionType } from 'ekiba-master-table';

import { Labels, Rutas, SKIP_AUTH } from 'src/app/constants/constants';
import { AuthService } from 'src/app/shared/auth.service';
import { Entity } from 'src/app/shared/entity';
import { PageIndexerService } from 'src/app/shared/page-indexer.service';
import {
  Variante,
  VARIANTES_BASE_URL,
  TABLE_HEADERS_VARIANTES,
} from './variantes.constants';
@Component({
  templateUrl: './variantes.component.html',
  styleUrls: ['./variantes.component.scss'],
})
export class VariantesComponent extends Entity implements OnInit {
  formattedVariantes!: Variante[];
  public readonly Labels: typeof Labels = Labels;
  public readonly Rutas: typeof Rutas = Rutas;
  public readonly RedirectionType: typeof RedirectionType = RedirectionType;
  public readonly authURL: string = `${Rutas.ConfiguracionDeProductos}/${Rutas.Variantes}`;
  public readonly editUrl: string = `${this.authURL}/${Rutas.Edit}`;
  public SKIP_AUTH: boolean = SKIP_AUTH;

  constructor(
    public auth: AuthService,
    public pageIndexer: PageIndexerService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.columns = TABLE_HEADERS_VARIANTES;
    this._baseUrl = VARIANTES_BASE_URL;
    this.data$ = this.getData();
    this._loadData();
    this.data$.subscribe((data) => {
      this.formattedVariantes = this.formatVariantes(data);
    });
  }

  public formatVariantes(data: Variante[]): Variante[] {
    return data.map((item: any) => {
      return {
        ...item,
        producto: item.producto?.nombre,
        modelo: item.modelo?.nombre,
        talla: item.talla?.nombre,
        activoB2b: !!item.activoB2b,
        activoB2c: !!item.activoB2c,
      };
    });
  }

  public ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }
}
