import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RedirectionType } from 'ekiba-master-table';

import { Labels, Rutas, SKIP_AUTH } from 'src/app/constants/constants';
import { AuthService } from 'src/app/shared/auth.service';
import { Entity } from 'src/app/shared/entity';
import { PageIndexerService } from 'src/app/shared/page-indexer.service';
import {
  Cuadro,
  CUADROS_BASE_URL,
  TABLE_HEADERS_CUADROS,
} from './cuadros.constants';
@Component({
  templateUrl: './cuadros.component.html',
})
export class CuadrosComponent extends Entity implements OnInit {
  formattedCuadros!: Cuadro[];
  public readonly Labels: typeof Labels = Labels;
  public readonly Rutas: typeof Rutas = Rutas;
  public readonly RedirectionType: typeof RedirectionType = RedirectionType;
  public readonly authURL: string = `${Rutas.ConfiguracionDeMaestros}/${Rutas.Cuadros}`;
  public readonly editUrl: string = `${this.authURL}/${Rutas.Edit}`;
  public readonly SKIP_AUTH: boolean = SKIP_AUTH;

  constructor(
    public auth: AuthService,
    public pageIndexer: PageIndexerService,
    private _router: Router
  ) {
    super();
  }

  public ngOnInit(): void {
    this.columns = TABLE_HEADERS_CUADROS;
    this._baseUrl = CUADROS_BASE_URL;
    this.data$ = this.getData();
    this._loadData();
    this.data$.subscribe((data) => {
      this.formattedCuadros = this.formatCuadros(data);
    });
  }

  public formatCuadros(data: Cuadro[]): Cuadro[] {
    return data.map((item: any) => {
      return {
        ...item,
        horquilla: item.horquilla?.descripcion,
        material: item.material?.descripcion,
        manillar: item.manillar?.descripcion,
        potencia: item.potencia?.descripcion,
        tijaSillin: item.tijaSillin?.descripcion,
        sillin: item.sillin?.descripcion,
        rearShox: item.rearShox?.descripcion,
        patilla: item.patilla?.descripcion,
      };
    });
  }

  public ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  public delete(cuadro: Cuadro): void {
    this._deleteData(cuadro);
  }

  public add(): void {
    this._router.navigate([
      `${Rutas.ConfiguracionDeMaestros}/${Rutas.Cuadros}/${Rutas.Add}`,
    ]);
  }
}
