import { ColumnTypes, TableHeaders } from 'ekiba-master-table';
import { Domains, FieldTypes } from 'src/app/constants/constants';
import { AbstractBerria } from 'src/app/constants/models';
import { environment } from 'src/environments/environment';
import {
  EntityField,
  EntityFieldGroup,
} from '../../add-edit-entity/add-edit-entity.component';

export const TABLE_HEADERS_GRUPO_CLIENTES: TableHeaders[] = [
  {
    name: 'id',
    displayName: 'ID',
    type: ColumnTypes.Standard,
  },
  {
    name: 'codigo',
    displayName: 'Código',
    type: ColumnTypes.Standard,
  },
  {
    name: 'nombre',
    displayName: 'Nombre',
    type: ColumnTypes.Standard,
  },
];

export const GRUPO_CLIENTES_BASE_URL: string = `${environment.api}${Domains.GrupoClientes}`;

export const GRUPO_CLIENTES_FIELDS: EntityField[] = [
  {
    name: 'nombre',
    displayName: 'Nombre',
    type: FieldTypes.Translatable,
    editDisabledForDefaultTranslationEntityField: true,
    group: 1,
  },
];

export const GRUPO_CLIENTESES_GROUPS: EntityFieldGroup[] = [
  {
    label: 'Información general',
    id: 1,
  },
];

export interface GrupoClientes extends AbstractBerria {
  nombre: string;
}
