<ekiba-nav-table
  [addItemName]="'Guardar'"
  [showFilter]="false"
  (addedItem)="updatePermissions()"
>
</ekiba-nav-table>
<div class="page-container">
  <div class="page-container__header">
    <span>{{ Labels.Permisos }}</span>
  </div>
  <div class="page-container__body">
    <form *ngIf="permissionForm" [formGroup]="permissionForm">
      <mat-form-field
        floatLabel="always"
        *ngIf="roles$ | async as roles"
        appearance="outline"
      >
        <mat-label>Roles *</mat-label>
        <mat-select formControlName="roleSelected">
          <ng-container *ngFor="let role of roles">
            <mat-option [value]="role.id">
              {{ role.nombre }}
            </mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
      <mat-tree
        [dataSource]="dataSource"
        [treeControl]="treeControl"
        style="background-color: #fcfcfc"
      >
        <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
          <button mat-icon-button disabled></button>
          <mat-checkbox formControlName="{{ 'permission' + node.id }}">
            {{ node.nombre }}
          </mat-checkbox>
        </mat-tree-node>
        <mat-tree-node
          *matTreeNodeDef="let node; when: hasChild"
          matTreeNodePadding
        >
          <button
            mat-icon-button
            matTreeNodeToggle
            [attr.aria-label]="'Toggle ' + node.nombre"
          >
            <mat-icon class="mat-icon-rtl-mirror">
              {{
                treeControl.isExpanded(node) ? "expand_more" : "chevron_right"
              }}
            </mat-icon>
          </button>
          <button
            matTreeNodeToggle
            mat-button
            *ngIf="node.type || node.level == 0"
          >
            <mat-icon>folder</mat-icon>
            {{ node.nombre }}
          </button>
          <mat-checkbox
            *ngIf="!node.type && node.level != 0"
            formControlName="{{ 'permission' + node.id }}"
          >
            {{ node.nombre }}
          </mat-checkbox>
        </mat-tree-node>
      </mat-tree>
    </form>
  </div>
</div>
