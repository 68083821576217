import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RedirectionType } from 'ekiba-master-table';

import { Labels, Rutas, SKIP_AUTH } from 'src/app/constants/constants';
import { AuthService } from 'src/app/shared/auth.service';
import { Entity } from 'src/app/shared/entity';
import { PageIndexerService } from 'src/app/shared/page-indexer.service';
import {
  Atributo,
  ATRIBUTOS_BASE_URL,
  TABLE_HEADERS_ATRIBUTOS,
} from './atributos.constants';
@Component({
  templateUrl: './atributos.component.html',
})
export class AtributosComponent extends Entity implements OnInit {
  formattedAtributos!: Atributo[];
  public readonly Labels: typeof Labels = Labels;
  public readonly Rutas: typeof Rutas = Rutas;
  public readonly RedirectionType: typeof RedirectionType = RedirectionType;
  public readonly authURL: string = `${Rutas.ConfiguracionDeMaestros}/${Rutas.Atributos}`;
  public readonly editUrl: string = `${this.authURL}/${Rutas.Edit}`;
  public SKIP_AUTH: boolean = SKIP_AUTH;

  constructor(
    public readonly auth: AuthService,
    private readonly _router: Router,
    public readonly pageIndexer: PageIndexerService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.columns = TABLE_HEADERS_ATRIBUTOS;
    this._baseUrl = ATRIBUTOS_BASE_URL;
    this.data$ = this.getData();
    this._loadData();
    this.data$.subscribe((data) => {
      this.formattedAtributos = this.formatAtributos(data);
    });
  }

  public formatAtributos(data: Atributo[]): Atributo[] {
    return data.map((item: any) => {
      return {
        ...item,
        atributoOpciones:
          item.atributoOpciones?.length &&
          item.atributoOpciones.map((a: any) => a.nombre).join(', '),
      };
    });
  }

  public ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  public add(): void {
    this._router.navigate([
      `${Rutas.ConfiguracionDeMaestros}/${Rutas.Atributos}/${Rutas.Add}`,
    ]);
  }

  public delete(atributo: Atributo): void {
    this._deleteData(atributo);
  }
}
