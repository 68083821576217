<h1 mat-dialog-title>
  {{ data?.action === "add" ? "Añadir" : "Editar" }} Servicio de pintura
</h1>
<div mat-dialog-content>
  <ng-container *ngIf="!noMoreSdpsToAdd; else error">
    <mat-form-field
      floatLabel="always"
      appearance="outline"
      *ngIf="sdp$ | async as cuadrosdp"
    >
      <mat-label>Nombre</mat-label>
      <mat-select [(ngModel)]="sdp" [compareWith]="objectComparisonFunction">
        <mat-option *ngFor="let option of cuadrosdp" [value]="option">
          {{ option?.nombre || option?.descripcion || option }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-checkbox color="primary" [(ngModel)]="default"
      >Es servicio de pintura por defecto</mat-checkbox
    >
  </ng-container>
  <ng-template #error
    >No hay más servicios de pintura disponibles para añadir a este
    cuadro</ng-template
  >
</div>
<div class="buttons-section" mat-dialog-actions>
  <button
    type="submit"
    class="cancel-button"
    mat-raised-button
    color="secondary"
    (click)="closeDialog()"
  >
    Salir
  </button>
  <button
    type="submit"
    class="confirm-button"
    mat-raised-button
    color="primary"
    (click)="confirm()"
    *ngIf="!noMoreSdpsToAdd"
  >
    Confirmar
  </button>
</div>
