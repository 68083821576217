<ekiba-nav-table
  [addItemName]="'Añadir valor de atributo'"
  [showFilter]="false"
  (addedItem)="add()"
  *ngIf="auth.hasPermissionTo(authURL, Rutas.Add)"
>
</ekiba-nav-table>
<div class="page-container">
  <div class="page-container__header">
    <span>{{ Labels.ValoresDeAtributo }}</span>
  </div>
  <div class="page-container__body">
    <ekiba-master-table
      *ngIf="data$ | async as data"
      [dataSource]="formattedValoresDeAtributo"
      [showFilters]="true"
      [showEditButton]="false"
      [showDeleteButton]="auth.hasPermissionTo(authURL, Rutas.Delete)"
      [columns]="columns"
      [redirection]="
        SKIP_AUTH || auth.hasPermissionTo(authURL, Rutas.Edit) ? editUrl : ''
      "
      [redirectionType]="RedirectionType.Edit"
      [pageIndex]="pageIndexer.getIndex(Labels.ValoresDeAtributo)"
      (deletedRow)="delete($event)"
      (changedPage)="
        pageIndexer.setIndex(Labels.ValoresDeAtributo, $event.pageIndex)
      "
    >
    </ekiba-master-table>
  </div>
</div>
