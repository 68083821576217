import { Component, Inject, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DropdownElement } from 'ekiba-dropdown';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Domains } from 'src/app/constants/constants';
import { EntityApiCalls } from 'src/app/shared/entity.api-calls';
import { environment } from 'src/environments/environment';

export interface AddEditProductoAtributoPopupInput {
  action: string;
  atributo: any;
}

export interface AddEditProductoAtributoPopupOutput {
  atributo: any;
}

@Component({
  templateUrl: './add-edit-producto-atributo.component.html',
  styleUrls: ['./add-edit-producto-atributo.component.scss'],
})
export class AddEditProductoAtributoPopup {
  atributo: any = {};
  atributos$: Observable<any[]> | undefined;
  valor: any[] = [];
  valores$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  _valores: any[] = [];

  public constructor(
    public dialogRef: MatDialogRef<AddEditProductoAtributoPopupOutput>,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: AddEditProductoAtributoPopupInput,
    private _api: EntityApiCalls
  ) {
    this._api
      .getData(`${environment.api}${Domains.AtributoOpciones}`)
      .subscribe((opciones: any[]) => {
        this._valores = opciones;
        if (this.atributo) {
          this.valores$.next(
            this._valores.filter(
              (opcion: any) => opcion.atributoId === this.atributo.atributoId
            )
          );
        }
      });
    this.atributos$ = this._api
      .getData(
        `${environment.api}${Domains.Dropdown}?table=${Domains.Atributos}`
      )
      .pipe(
        map((atributos: any) => {
          return atributos.map((atributo: any) => {
            return { nombre: atributo.nombre, atributoId: atributo.id };
          });
        })
      );
    this.atributo = this.data?.atributo;
    this.valor = this.atributo?.valor;
  }

  public closeDialog(): void {
    this.dialogRef.close(false);
  }

  public confirm(): void {
    this.dialogRef.close({
      atributo: this.atributo,
      valor: this.valor,
    });
  }

  public changeAtributo(atributo: DropdownElement | DropdownElement[]) {
    this.atributo = atributo;
    this.valores$.next(
      this._valores.filter(
        (opcion: any) => opcion.atributoId === this.atributo.atributoId
      )
    );
  }

  public objectComparisonFunctionAtributo(item: any, selected: any) {
    return typeof item === 'object'
      ? item.atributoId === selected.atributoId
      : item === selected;
  }

  public objectComparisonFunction(item: any, selected: any) {
    return typeof item === 'object'
      ? item.id === selected.id
      : item === selected;
  }
}
