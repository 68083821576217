import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Labels, Rutas, SKIP_AUTH } from 'src/app/constants/constants';
import { Entity } from 'src/app/shared/entity';
import { User } from 'src/app/constants/models';
import {
  Campana,
  CAMPANAS_BASE_URL,
  TABLE_HEADERS_CAMPANAS,
} from './familias.constants';
import { AuthService } from 'src/app/shared/auth.service';
import { PageIndexerService } from 'src/app/shared/page-indexer.service';
@Component({
  templateUrl: './familias.component.html',
})
export class FamiliasComponent extends Entity implements OnInit {
  formattedFamilias!: Campana[];
  public readonly Labels: typeof Labels = Labels;
  public readonly Rutas: typeof Rutas = Rutas;
  public readonly authURL: string = `${Rutas.ConfiguracionDeProductos}/${Rutas.Familias}`;
  public SKIP_AUTH: boolean = SKIP_AUTH;

  constructor(
    public auth: AuthService,
    public pageIndexer: PageIndexerService,
    private _router: Router
  ) {
    super();
  }

  public ngOnInit(): void {
    this.columns = TABLE_HEADERS_CAMPANAS;
    this._baseUrl = CAMPANAS_BASE_URL;
    this.data$ = this.getData();
    this._loadData();
    this.data$.subscribe((data) => {
      this.formattedFamilias = this.formatFamilias(data);
    });
  }

  public formatFamilias(data: Campana[]): Campana[] {
    return data.map((item) => {
      return { ...item, cuadro: item.cuadro?.nombre };
    });
  }

  public ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  public update(user: User): void {
    this._router.navigate([
      `${Rutas.ConfiguracionDeProductos}/${Rutas.Familias}/${Rutas.Edit}/${user.id}`,
    ]);
  }
}
