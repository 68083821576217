<ekiba-nav-table
  [addItemName]="'Añadir idioma'"
  [showFilter]="false"
  (addedItem)="add()"
  *ngIf="auth.hasPermissionTo(authURL, Rutas.Add)"
>
</ekiba-nav-table>
<div class="page-container">
  <div class="page-container__header">
    <span>{{ Labels.HistoricoDeSincronizacion }}</span>
  </div>
  <div class="page-container__body">
    <ekiba-master-table
      *ngIf="data$ | async as data"
      [dataSource]="formattedHistoricoDeSincronizacion"
      [showFilters]="true"
      [showDefaultActions]="false"
      [columns]="columns"
    >
    </ekiba-master-table>
  </div>
</div>
