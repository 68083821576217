<div class="page-container">
  <div class="page-container__header">
    <span>{{ Labels.Clientes }}</span>
  </div>
  <div class="page-container__body">
    <ekiba-master-table
      *ngIf="data$ | async as data"
      [dataSource]="formattedClientes"
      [showFilters]="true"
      [showDeleteButton]="false"
      [showEditButton]="false"
      [customActions]="customActions"
      [columns]="columns"
      [pageIndex]="pageIndexer.getIndex(Labels.Clientes)"
      (changedPage)="pageIndexer.setIndex(Labels.Clientes, $event.pageIndex)"
    >
    </ekiba-master-table>
  </div>
</div>
