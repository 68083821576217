import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RedirectionType } from 'ekiba-master-table';
import { Language, Translation } from 'ekiba-translatable-field';
import { BehaviorSubject } from 'rxjs';

import { Domains, Labels, Rutas, SKIP_AUTH } from 'src/app/constants/constants';
import { AuthService } from 'src/app/shared/auth.service';
import { Entity } from 'src/app/shared/entity';
import { PageIndexerService } from 'src/app/shared/page-indexer.service';
import { environment } from 'src/environments/environment';
import {
  TRADUCCIONES_BASE_URL,
  TABLE_HEADERS_TRADUCCIONES,
} from './traducciones.constants';
@Component({
  templateUrl: './traducciones.component.html',
  styleUrls: ['./traducciones.component.scss'],
})
export class TraduccionesComponent extends Entity implements OnInit {
  formattedTraducciones!: Translation[];
  public readonly Labels: typeof Labels = Labels;
  public readonly Rutas: typeof Rutas = Rutas;
  public readonly RedirectionType: typeof RedirectionType = RedirectionType;
  public readonly authURL: string = `${Rutas.ConfiguracionGeneral}/${Rutas.Traducciones}`;
  public readonly editUrl: string = `${this.authURL}/${Rutas.Edit}`;
  public SKIP_AUTH: boolean = SKIP_AUTH;

  public idiomas$: BehaviorSubject<Language[]> = new BehaviorSubject<
    Language[]
  >([]);

  constructor(
    public auth: AuthService,
    private _router: Router,
    public pageIndexer: PageIndexerService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.columns = TABLE_HEADERS_TRADUCCIONES;
    this._baseUrl = TRADUCCIONES_BASE_URL;
    this.data$ = this.getData();
    this._loadData();
    this.data$.subscribe((data) => {
      this.api
        .getData(`${environment.api}${Domains.IdiomasShared}`)
        .subscribe((res) => {
          this.idiomas$.next(res);
          this.formattedTraducciones = this.formatTraducciones(data);
        });
    });
  }

  public formatTraducciones(data: Translation[]): Translation[] {
    return data.map((traduccion: Translation) => {
      return {
        ...traduccion,
        idioma: this.idiomas$.value?.find(
          (idioma: Language) => idioma.id === traduccion.idiomaId
        )?.nombre,
      };
    });
  }

  public ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  public add(): void {
    this._router.navigate([
      `${Rutas.ConfiguracionGeneral}/${Rutas.Traducciones}/${Rutas.Add}`,
    ]);
  }

  public delete(traduccion: Translation): void {
    this._deleteData(traduccion);
  }
}
