<div class="page-container">
  <div class="page-container__header">
    <span>{{ Labels.GrupoClientes }}</span>
  </div>
  <div class="page-container__body">
    <ekiba-master-table
      *ngIf="data$ | async as data"
      [dataSource]="formattedGruposClientes"
      [showFilters]="true"
      [showDeleteButton]="false"
      [showEditButton]="false"
      [customActions]="customActions"
      [columns]="columns"
      [pageIndex]="pageIndexer.getIndex(Labels.GrupoClientes)"
      (changedPage)="
        pageIndexer.setIndex(Labels.GrupoClientes, $event.pageIndex)
      "
    >
    </ekiba-master-table>
  </div>
</div>
