import { Component, OnInit } from '@angular/core';
import { RedirectionType } from 'ekiba-master-table';

import { Labels, Rutas, SKIP_AUTH } from 'src/app/constants/constants';
import { AuthService } from 'src/app/shared/auth.service';
import { Entity } from 'src/app/shared/entity';
import { PageIndexerService } from 'src/app/shared/page-indexer.service';
import {
  ServicioDePintura,
  SERVICIOS_DE_PINTURA_BASE_URL,
  TABLE_HEADERS_SERVICIOS_DE_PINTURA,
} from './servicios-de-pintura.constants';
@Component({
  templateUrl: './servicios-de-pintura.component.html',
})
export class ServiciosDePinturaComponent extends Entity implements OnInit {
  formattedServiciosDePintura!: ServicioDePintura[];
  public readonly Labels: typeof Labels = Labels;
  public readonly Rutas: typeof Rutas = Rutas;
  public readonly RedirectionType: typeof RedirectionType = RedirectionType;
  public readonly authURL: string = `${Rutas.ConfiguracionDeMaestros}/${Rutas.ServiciosDePintura}`;
  public readonly editUrl: string = `${this.authURL}/${Rutas.Edit}`;
  public SKIP_AUTH: boolean = SKIP_AUTH;

  constructor(
    public auth: AuthService,
    public pageIndexer: PageIndexerService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.columns = TABLE_HEADERS_SERVICIOS_DE_PINTURA;
    this._baseUrl = SERVICIOS_DE_PINTURA_BASE_URL;
    this.data$ = this.getData();
    this._loadData();
    this.data$.subscribe((data) => {
      this.formattedServiciosDePintura = this.formatServiciosDePintura(data);
    });
  }

  public formatServiciosDePintura(
    data: ServicioDePintura[]
  ): ServicioDePintura[] {
    return data;
  }

  public ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }
}
