import { Component } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Language, Translation } from 'ekiba-translatable-field';
import { filter } from 'rxjs/internal/operators/filter';
import { tap } from 'rxjs/internal/operators/tap';
import { Domains, FieldTypes, Rutas } from 'src/app/constants/constants';
import { TiposColor } from 'src/app/popups/configuracion-de-colores/configuracion-de-colores.dialog';
import { EntityApiCalls } from 'src/app/shared/entity.api-calls';
import { environment } from 'src/environments/environment';
import { AddEditEntityComponent } from '../../add-edit-entity/add-edit-entity.component';
import { Color, COLORES_BASE_URL } from '../colores/colores.constants';

@Component({
  templateUrl: './add-edit-servicio-de-pintura.component.html',
  styleUrls: [
    './add-edit-servicio-de-pintura.component.scss',
    '../../add-edit-entity/add-edit-entity.component.scss',
  ],
})
export class AddEditServicioDePinturaComponent extends AddEditEntityComponent {
  public colores: any[] = [];
  public coloresOriginal: any[] = [];

  constructor(
    protected readonly route: ActivatedRoute,
    protected readonly api: EntityApiCalls,
    protected readonly router: Router,
    protected readonly dialog: MatDialog
  ) {
    super(route, api, router, dialog);
    this.formBuilt$
      .pipe(
        filter((formBuilt$) => formBuilt$),
        tap(() => {
          if (this._entityToEdit) {
            this._loadColores();
          }
        })
      )
      .subscribe();
  }

  public saveData(): void {
    if (this.entityForm.valid) {
      const formFieldsMap: any = {};
      this.fields.forEach((field) => {
        formFieldsMap[field.name] = this.entityForm.getRawValue()[field.name];
        if (
          field.type === FieldTypes.Translatable ||
          field.type === FieldTypes.TranslatableTextarea
        ) {
          if (!field.editDisabledForDefaultTranslationEntityField) {
            formFieldsMap[field.name] =
              this._traducciones.find(
                (traduccion: Translation) =>
                  traduccion.campo === field.name &&
                  traduccion.idiomaId ===
                    this.idiomas$?.value?.find(
                      (idioma: Language) => idioma.codigo === 'es'
                    )?.id
              )?.valorVarchar || formFieldsMap[field.name];
          } else {
            formFieldsMap[field.name] = this._entityToEdit[field.name];
          }
        }
      });
      const data: any = {
        ...formFieldsMap,
        id: this._id,
        pinturaColoresIds: this._mapSDPColoresToSave(),
      };

      this.api.updateData(data, this._baseUrl).subscribe(() => {
        this._traducciones = this._traducciones.map(
          (traduccion: Translation) => ({
            ...traduccion,
            registroId: this._id,
          })
        );
        this.api
          .addData(
            { traducciones: this._traducciones },
            `${environment.api}${Domains.PostTraduccionesMultiple}`
          )
          .subscribe();
        this.router.navigate([`${this._returnUrl}/${Rutas.List}`]);
      });
    } else {
      this.entityForm.markAllAsTouched();
      this.touched$.next(true);
    }
  }

  public toggleAllColors(event: MatCheckboxChange, tipo: string) {
    this.colores?.forEach((colores: any) => {
      colores.forEach((color: any) => {
        if (color.tipo === tipo) color.active = event.checked;
      });
    });
  }

  private _loadColores(): void {
    const colores$ = this.api.getData(COLORES_BASE_URL);
    colores$.subscribe((colores) => {
      this.coloresOriginal = [
        ...colores.filter((color: Color) => !color.bloqueado),
      ];

      colores = this._mapColoresToTableStructure();
      colores = colores.map((color) => {
        return color.map((col: any) => {
          const sdpcolor = this._entityToEdit.pinturaColores?.find(
            (c: any) => c.color?.id === col.color.id && c.tipo === col.tipo
          );
          return sdpcolor ? { ...sdpcolor, active: true } : col;
        });
      });
      this._entityToEdit.pinturaColores = colores;
      this.colores = colores;
    });
  }

  private _mapColoresToTableStructure(): any[] {
    return this.coloresOriginal.map((color) => {
      return [
        { color, default: 0, active: 0, tipo: TiposColor.Fondo },
        { color, default: 0, active: 0, tipo: TiposColor.Color },
        { color, default: 0, active: 0, tipo: TiposColor.Calca1 },
        { color, default: 0, active: 0, tipo: TiposColor.Calca2 },
        { color, default: 0, active: 0, tipo: TiposColor.Acabado },
      ];
    });
  }

  private _mapSDPColoresToSave(): any {
    return this._entityToEdit.pinturaColores
      ?.map((color: any) =>
        color
          .filter((tipoColor: any) => tipoColor.active)
          .map((tipoColor: any) => {
            let tipoColorReturn: any = {
              tipo: tipoColor.tipo,
              default: tipoColor.default,
              colorId: tipoColor.color?.id,
            };
            if (tipoColor.id) {
              tipoColorReturn = { ...tipoColorReturn, id: tipoColor.id };
            }
            return tipoColorReturn;
          })
      )
      .flat();
  }

  public setDefault(tipo: {
    active: boolean;
    color: any;
    default: number;
    tipo: string;
  }) {
    this._entityToEdit.pinturaColores?.forEach((colores: any) => {
      colores.forEach((color: any) => {
        if (color.tipo === tipo.tipo) color.default = 0;
      });
    });
    tipo.default = 1;
  }
}
