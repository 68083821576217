import { Component, Inject, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Domains } from 'src/app/constants/constants';
import { EntityApiCalls } from 'src/app/shared/entity.api-calls';
import { environment } from 'src/environments/environment';

export interface AddEditProductoTecnologiaPopupInput {
  action: string;
  tecnologia: any;
}

export interface AddEditProductoTecnologiaPopupOutput {
  tecnologia: any;
}

@Component({
  templateUrl: './add-edit-producto-tecnologia.component.html',
  styleUrls: ['./add-edit-producto-tecnologia.component.scss'],
})
export class AddEditProductoTecnologiaPopup {
  tecnologia: any;
  tecnologias$: Observable<any> | undefined;
  orden: number = 0;

  public constructor(
    public dialogRef: MatDialogRef<AddEditProductoTecnologiaPopup>,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: AddEditProductoTecnologiaPopupInput,
    private _api: EntityApiCalls
  ) {
    this.tecnologias$ = this._api
      .getData(
        `${environment.api}${Domains.Dropdown}?table=${Domains.Tecnologias}`
      )
      .pipe(
        map((tecnologias: any) => {
          return tecnologias.map((tecnologia: any) => {
            return { nombre: tecnologia.nombre, tecnologiaId: tecnologia.id };
          });
        })
      );
    this.tecnologia = this.data.tecnologia;
    this.orden = this.tecnologia?.orden;
  }

  public closeDialog(): void {
    this.dialogRef.close(false);
  }

  public confirm(): void {
    this.dialogRef.close({
      tecnologia: { ...this.tecnologia, orden: this.orden },
    });
  }

  public objectComparisonFunction(item: any, selected: any) {
    return typeof item === 'object'
      ? item.tecnologiaId === selected.tecnologiaId
      : item === selected;
  }
}
