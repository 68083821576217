import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RedirectionType } from 'ekiba-master-table';

import { Labels, Rutas, SKIP_AUTH } from 'src/app/constants/constants';
import { AuthService } from 'src/app/shared/auth.service';
import { Entity } from 'src/app/shared/entity';
import { PageIndexerService } from 'src/app/shared/page-indexer.service';
import {
  Producto,
  PRODUCTOS_BASE_URL,
  TABLE_HEADERS_PRODUCTOS,
} from './productos.constants';
@Component({
  templateUrl: './productos.component.html',
})
export class ProductosComponent extends Entity implements OnInit {
  formattedProductos!: Producto[];
  public readonly Labels: typeof Labels = Labels;
  public readonly Rutas: typeof Rutas = Rutas;
  public readonly RedirectionType: typeof RedirectionType = RedirectionType;
  public readonly authURL: string = `${Rutas.ConfiguracionDeProductos}/${Rutas.Productos}`;
  public readonly editUrl: string = `${this.authURL}/${Rutas.Edit}`;
  public SKIP_AUTH: boolean = SKIP_AUTH;

  constructor(
    public auth: AuthService,
    public pageIndexer: PageIndexerService,
    private _router: Router
  ) {
    super();
  }

  public ngOnInit(): void {
    this.columns = TABLE_HEADERS_PRODUCTOS;
    this._baseUrl = PRODUCTOS_BASE_URL;
    this.data$ = this.getData();
    this._loadData();
    this.data$.subscribe((data) => {
      this.formattedProductos = this.formatProductos(data);
    });
  }

  public formatProductos(data: Producto[]): Producto[] {
    return data.map((item: Producto) => {
      return {
        ...item,
        familia: item.familia?.nombre,
        cuadro: item.cuadro?.nombre,
        activoB2b: !!item.activoB2b,
        activoB2c: !!item.activoB2c,
      };
    });
  }

  public ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  public update(producto: Producto): void {
    this._router.navigate([
      `${Rutas.ConfiguracionDeProductos}/${Rutas.Productos}/${Rutas.Edit}/${producto.id}`,
    ]);
  }
}
