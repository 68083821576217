import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RedirectionType } from 'ekiba-master-table';

import { Labels, Rutas, SKIP_AUTH } from 'src/app/constants/constants';
import { AuthService } from 'src/app/shared/auth.service';
import { Entity } from 'src/app/shared/entity';
import { PageIndexerService } from 'src/app/shared/page-indexer.service';
import {
  IDIOMAS_BASE_URL,
  TABLE_HEADERS_IDIOMAS,
  Idioma,
} from './idiomas.constants';
@Component({
  templateUrl: './idiomas.component.html',
})
export class IdiomasComponent extends Entity implements OnInit {
  formattedIdiomas!: Idioma[];
  public readonly Labels: typeof Labels = Labels;
  public readonly Rutas: typeof Rutas = Rutas;
  public readonly RedirectionType: typeof RedirectionType = RedirectionType;
  public readonly authURL: string = `${Rutas.ConfiguracionGeneral}/${Rutas.Idiomas}`;
  public readonly editUrl: string = `${this.authURL}/${Rutas.Edit}`;
  public SKIP_AUTH: boolean = SKIP_AUTH;

  constructor(
    public auth: AuthService,
    private _router: Router,
    public pageIndexer: PageIndexerService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.columns = TABLE_HEADERS_IDIOMAS;
    this._baseUrl = IDIOMAS_BASE_URL;
    this.data$ = this.getData();
    this._loadData();
    this.data$.subscribe((data) => {
      this.formattedIdiomas = this.formatIdiomas(data);
    });
  }

  public formatIdiomas(data: Idioma[]): Idioma[] {
    return data.map((item: any) => {
      return {
        ...item,
        activo: !!item.activo,
      };
    });
  }

  public ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  public add(): void {
    this._router.navigate([
      `${Rutas.ConfiguracionGeneral}/${Rutas.Idiomas}/${Rutas.Add}`,
    ]);
  }

  public delete(idioma: Idioma): void {
    this._deleteData(idioma);
  }
}
