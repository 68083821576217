<div class="error-container">
  <button class="go-back-button" mat-raised-button routerLink="">
    <mat-icon>arrow_back_ios</mat-icon> Volver
  </button>
  <img src="assets/img/logo-berria.svg" alt="Berria" />
  <h2>¡Ups! Esta página no existe.</h2>

  <p>
    La página a la que intenta acceder no existe. Por favor, compruebe que está
    accediendo a la ruta correcta. la ruta a la que intenta acceder es correcta
    y ve esta página, por favor contacte con el administrador del sitio web.
  </p>
</div>
