import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DropdownElement } from 'ekiba-dropdown';
import { RedirectionType } from 'ekiba-master-table';

import { Labels, Rutas, SKIP_AUTH } from 'src/app/constants/constants';
import { AuthService } from 'src/app/shared/auth.service';
import { Entity } from 'src/app/shared/entity';
import { PageIndexerService } from 'src/app/shared/page-indexer.service';
import { EntityField } from '../../add-edit-entity/add-edit-entity.component';
import {
  COMPONENTES_BASE_URL,
  TABLE_HEADERS_COMPONENTES,
  Componente,
  COMPONENTES_FIELDS,
} from './componentes.constants';
@Component({
  templateUrl: './componentes.component.html',
})
export class ComponentesComponent extends Entity implements OnInit {
  formattedComponentes!: Componente[];
  public readonly Labels: typeof Labels = Labels;
  public readonly Rutas: typeof Rutas = Rutas;
  public readonly RedirectionType: typeof RedirectionType = RedirectionType;
  public readonly authURL: string = `${Rutas.ConfiguracionDeMaestros}/${Rutas.Componentes}`;
  public readonly editUrl: string = `${this.authURL}/${Rutas.Edit}`;
  public SKIP_AUTH: boolean = SKIP_AUTH;

  constructor(
    public auth: AuthService,
    public pageIndexer: PageIndexerService,
    private _router: Router
  ) {
    super();
  }

  public ngOnInit(): void {
    this.columns = TABLE_HEADERS_COMPONENTES;
    this._baseUrl = COMPONENTES_BASE_URL;
    this.data$ = this.getData();
    this._loadData();
    this.data$.subscribe((data) => {
      this.formattedComponentes = this.formatComponentes(data);
    });
  }

  public formatComponentes(data: Componente[]): Componente[] {
    const fields = COMPONENTES_FIELDS;
    return data.map((item: Componente) => ({
      ...item,
      tipo: item.tipo
        ? fields
            .find((field: EntityField) => field.name === 'tipo')
            ?.options!.find(
              (option: DropdownElement) => option.nombre === item.tipo
            ) || ''
        : '',
    }));
  }

  public ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  public add(): void {
    this._router.navigate([
      `${Rutas.ConfiguracionDeMaestros}/${Rutas.Componentes}/${Rutas.Add}`,
    ]);
  }

  public delete(componente: Componente): void {
    this._deleteData(componente);
  }
}
