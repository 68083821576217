import { Component, Inject, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Domains } from 'src/app/constants/constants';
import { EntityApiCalls } from 'src/app/shared/entity.api-calls';
import { environment } from 'src/environments/environment';

export interface AddEditProductoBielaPopupInput {
  action: string;
  biela: any;
}

export interface AddEditProductoBielaPopupOutput {
  biela: any;
}

@Component({
  templateUrl: './add-edit-producto-biela.component.html',
  styleUrls: ['./add-edit-producto-biela.component.scss'],
})
export class AddEditProductoBielaPopup {
  biela: any;
  bielas$: Observable<any> | undefined;
  default: boolean = false;

  public constructor(
    public dialogRef: MatDialogRef<AddEditProductoBielaPopup>,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: AddEditProductoBielaPopupInput,
    private _api: EntityApiCalls
  ) {
    this.bielas$ = this._api
      .getData(`${environment.api}${Domains.Dropdown}?table=${Domains.Bielas}`)
      .pipe(
        map((bielas: any) => {
          return bielas.map((biela: any) => {
            return { nombre: biela.nombre, bielaId: biela.id };
          });
        })
      );
    this.biela = this.data.biela;
    this.default = this.biela?.default;
  }

  public closeDialog(): void {
    this.dialogRef.close(false);
  }

  public confirm(): void {
    this.dialogRef.close({
      biela: { ...this.biela, default: this.default },
    });
  }

  public objectComparisonFunction(item: any, selected: any) {
    return typeof item === 'object'
      ? item.bielaId === selected.bielaId
      : item === selected;
  }
}
