<h1 mat-dialog-title>Editar Modelo</h1>
<div mat-dialog-content>
  <mat-form-field
    floatLabel="always"
    appearance="outline"
    *ngIf="modelos$ | async as modelos"
  >
    <mat-label>Modelo</mat-label>
    <mat-select [(ngModel)]="modelo" [compareWith]="objectComparisonFunction">
      <mat-option *ngFor="let option of modelos" [value]="option">
        {{ option?.nombre || option?.descripcion || option }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field
    floatLabel="always"
    appearance="outline"
    *ngIf="ruedas$ | async as ruedas"
  >
    <mat-label>Rueda</mat-label>
    <mat-select [(ngModel)]="rueda" [compareWith]="objectComparisonFunction">
      <mat-option *ngFor="let rueda of ruedas" [value]="rueda">
        {{ rueda?.nombre || rueda?.descripcion || rueda }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field  
  floatLabel="always"
  appearance="outline">
    <mat-label>Orden</mat-label>
    <input matInput [(ngModel)]="orden" type="number" />
  </mat-form-field>
</div>


<div class="buttons-section" mat-dialog-actions>
  <button
    type="submit"
    class="cancel-button"
    mat-raised-button
    color="secondary"
    (click)="closeDialog()"
  >
    Salir
  </button>
  <button
    type="submit"
    class="confirm-button"
    mat-raised-button
    color="primary"
    (click)="confirm()"
  >
    Confirmar
  </button>
</div>
