import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Domains, Rutas } from '../constants/constants';
import { LoginService } from '../pages/login/login.service';
import { WarningPopupComponent } from '../popups/warning/warning-popup.component';

@Injectable()
export class NotAuthorizedInterceptor implements HttpInterceptor {
  public constructor(
    //private readonly _authenticationService: AuthenticationService,
    private readonly router: Router,
    private readonly loginService: LoginService,
    private readonly dialog: MatDialog
  ) {}

  public intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error) => {
        if (
          error instanceof HttpErrorResponse &&
          (error.error === 401 || error.status === 401)
        ) {
          this.handle401Error(error);
        }
        return throwError(error);
      })
    );
  }

  private handle401Error(error: any): any {
    if (
      error.url === `${environment.api}${Domains.Autenticacion}/${Domains.Auth}`
    ) {
      const dialog = this.dialog.open(WarningPopupComponent, {
        data: {
          headerMessage: 'Su sesión ha caducado',
          errorMessage: 'Vuelva a iniciar sesión',
        },
      });
      dialog.afterClosed().subscribe(() => {
        this.loginService.logout();
        this.router.navigate([Rutas.Login]);
      });
    } else {
      this.router.navigate([Domains.NoAutorizado]);
    }
  }
}
