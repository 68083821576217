<div class="error-container">
  <button class="go-back-button" mat-raised-button routerLink="">
    <mat-icon>arrow_back_ios</mat-icon> Volver
  </button>
  <img src="assets/img/logo-berria.svg" alt="Berria" />
  <h2>No está autorizado para acceder a esta página.</h2>

  <p>
    No cuenta con los permisos necesarios para acceder a esta página. Si
    considera que esto es un error, por favor contacte con el administrador del
    sitio web.
  </p>
</div>
