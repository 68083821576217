import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Language, Translation } from 'ekiba-translatable-field';
import { filter, tap } from 'rxjs/operators';
import { Domains, FieldTypes, Rutas } from 'src/app/constants/constants';
import { EntityApiCalls } from 'src/app/shared/entity.api-calls';
import { environment } from 'src/environments/environment';
import { AddEditEntityComponent } from '../../add-edit-entity/add-edit-entity.component';

@Component({
  templateUrl: '../../add-edit-entity/add-edit-entity.component.html',
  styleUrls: ['../../add-edit-entity/add-edit-entity.component.scss'],
})
export class AddEditComponentesComponent
  extends AddEditEntityComponent
  implements OnInit
{
  constructor(
    protected readonly route: ActivatedRoute,
    protected readonly api: EntityApiCalls,
    protected readonly router: Router,
    protected readonly dialog: MatDialog
  ) {
    super(route, api, router, dialog);
    this.formBuilt$
      .pipe(
        filter((formBuilt$) => formBuilt$),
        tap(() => {
          if (this._entityToEdit) {
            if (this._entityToEdit.tipo) {
              this._entityToEdit.tipo = this.fields
                .find((field: any) => field.name === 'tipo')!
                .options!.find(
                  (tipo: any) => tipo.nombre === this._entityToEdit.tipo
                );
            }
            this.entityForm.patchValue(this._entityToEdit);
          }
        })
      )
      .subscribe();
  }

  public saveData(): void {
    if (this.entityForm.valid) {
      const formFieldsMap: any = {};
      this.fields.forEach((field) => {
        formFieldsMap[field.name] = this.entityForm.getRawValue()[field.name];
        // Send only ids for array and object fields
        if (
          field.type === FieldTypes.Translatable ||
          field.type === FieldTypes.TranslatableTextarea
        ) {
          formFieldsMap[field.name] =
            this._traducciones.find(
              (traduccion: Translation) =>
                traduccion.campo === field.name &&
                traduccion.idiomaId ===
                  this.idiomas$?.value?.find(
                    (idioma: Language) => idioma.codigo === 'es'
                  )?.id
            )?.valorVarchar || formFieldsMap[field.name];
        } else if (field.type === FieldTypes.Boolean) {
          formFieldsMap[field.name] = formFieldsMap[field.name] ? 1 : 0;
        }
        if (field.name === 'tipo') {
          formFieldsMap[field.name] = formFieldsMap[field.name].nombre;
        }
        // if (!formFieldsMap[field.name]) {
        //   delete formFieldsMap[field.name];
        // }
      });
      const data: any = {
        ...formFieldsMap,
        id: this._id,
      };

      switch (this.action) {
        case 'add':
          this.api.addData(data, this._baseUrl).subscribe((res: any) => {
            this._traducciones.forEach((traduccion: Translation) => {
              traduccion.registroId = res?.id;
              if (!!traduccion.id || traduccion.id === 0) {
                this.api
                  .updateData(
                    traduccion,
                    `${environment.api}${Domains.Traducciones}`
                  )
                  .subscribe();
              } else {
                this.api
                  .addData(
                    traduccion,
                    `${environment.api}${Domains.Traducciones}`
                  )
                  .subscribe((res) => {
                    if (res) {
                      traduccion.id = res.id;
                    }
                  });
              }
            });
            this.router.navigate([`${this._returnUrl}/${Rutas.List}`]);
          });
          break;
        case 'edit':
          this.api.updateData(data, this._baseUrl).subscribe(() => {
            this._traducciones.forEach((traduccion: Translation) => {
              traduccion.registroId = this._id;
              if (!!traduccion.id || traduccion.id === 0) {
                this.api
                  .updateData(
                    traduccion,
                    `${environment.api}${Domains.Traducciones}`
                  )
                  .subscribe();
              } else {
                this.api
                  .addData(
                    traduccion,
                    `${environment.api}${Domains.Traducciones}`
                  )
                  .subscribe((res) => {
                    if (res) {
                      traduccion.id = res.id;
                    }
                  });
              }
            });
            this.router.navigate([`${this._returnUrl}/${Rutas.List}`]);
          });
          break;
      }
    } else {
      this.entityForm.markAllAsTouched();
      this.touched$.next(true);
    }
  }
}
