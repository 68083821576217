import { Component, OnInit } from '@angular/core';
import { RedirectionType } from 'ekiba-master-table';

import { Labels, Rutas, SKIP_AUTH } from 'src/app/constants/constants';
import { AuthService } from 'src/app/shared/auth.service';
import { Entity } from 'src/app/shared/entity';
import { PageIndexerService } from 'src/app/shared/page-indexer.service';
import {
  Potencia,
  POTENCIAS_BASE_URL,
  TABLE_HEADERS_POTENCIAS,
} from './potencias.constants';
@Component({
  templateUrl: './potencias.component.html',
})
export class PotenciasComponent extends Entity implements OnInit {
  formattedPotencias!: Potencia[];
  public readonly Labels: typeof Labels = Labels;
  public readonly Rutas: typeof Rutas = Rutas;
  public readonly RedirectionType: typeof RedirectionType = RedirectionType;
  public readonly authURL: string = `${Rutas.ConfiguracionDeMaestros}/${Rutas.Potencias}`;
  public readonly editUrl: string = `${this.authURL}/${Rutas.Edit}`;
  public SKIP_AUTH: boolean = SKIP_AUTH;

  constructor(
    public auth: AuthService,
    public pageIndexer: PageIndexerService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.columns = TABLE_HEADERS_POTENCIAS;
    this._baseUrl = POTENCIAS_BASE_URL;
    this.data$ = this.getData();
    this._loadData();
    this.data$.subscribe((data) => {
      this.formattedPotencias = this.formatPotencias(data);
    });
  }

  public formatPotencias(data: Potencia[]): Potencia[] {
    return data;
  }

  public ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }
}
