import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RedirectionType } from 'ekiba-master-table';

import { Labels, Rutas, SKIP_AUTH } from 'src/app/constants/constants';
import { AuthService } from 'src/app/shared/auth.service';
import { Entity } from 'src/app/shared/entity';
import { PageIndexerService } from 'src/app/shared/page-indexer.service';
import {
  GRUPOS_DE_CAMBIO_BASE_URL,
  TABLE_HEADERS_GRUPOS_DE_CAMBIO,
  GrupoDeCambio,
} from './grupos-de-cambio.constants';
@Component({
  templateUrl: './grupos-de-cambio.component.html',
  styleUrls: ['./grupos-de-cambio.component.scss'],
})
export class GruposDeCambioComponent extends Entity implements OnInit {
  formattedGruposDeCambio!: GrupoDeCambio[];
  public readonly Labels: typeof Labels = Labels;
  public readonly Rutas: typeof Rutas = Rutas;
  public readonly RedirectionType: typeof RedirectionType = RedirectionType;
  public readonly authURL: string = `${Rutas.ConfiguracionDeMaestros}/${Rutas.GruposDeCambio}`;
  public readonly editUrl: string = `${this.authURL}/${Rutas.Edit}`;
  public SKIP_AUTH: boolean = SKIP_AUTH;

  constructor(
    public auth: AuthService,
    public pageIndexer: PageIndexerService,
    private _router: Router
  ) {
    super();
  }

  public ngOnInit(): void {
    this.columns = TABLE_HEADERS_GRUPOS_DE_CAMBIO;
    this._baseUrl = GRUPOS_DE_CAMBIO_BASE_URL;
    this.data$ = this.getData();
    this._loadData();
    this.data$.subscribe((data) => {
      this.formattedGruposDeCambio = this.formatGruposDeCambio(data);
    });
  }

  public formatGruposDeCambio(data: GrupoDeCambio[]): GrupoDeCambio[] {
    return data.map((item: any) => {
      return {
        ...item,
        biela: item.biela?.descripcion,
        cadena: item.cadena?.descripcion,
        cambio: item.cambio?.descripcion,
        freno: item.freno?.descripcion,
        maneta: item.maneta?.descripcion,
        pinon: item.pinon?.descripcion,
      };
    });
  }

  public ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  public add(): void {
    this._router.navigate([
      `${Rutas.ConfiguracionDeMaestros}/${Rutas.GruposDeCambio}/${Rutas.Add}`,
    ]);
  }

  public delete(grupoDeCambio: GrupoDeCambio): void {
    this._deleteData(grupoDeCambio);
  }
}
