import { ColumnTypes, TableHeaders } from 'ekiba-master-table';
import { Domains, FieldTypes } from 'src/app/constants/constants';
import { AbstractBerria } from 'src/app/constants/models';
import { environment } from 'src/environments/environment';
import {
  EntityField,
  EntityFieldGroup,
} from '../../add-edit-entity/add-edit-entity.component';

export const TABLE_HEADERS_CATEGORIAS: TableHeaders[] = [
  {
    name: 'id',
    displayName: 'ID',
    type: ColumnTypes.Standard,
  },
  {
    name: 'codigo',
    displayName: 'Código',
    type: ColumnTypes.Standard,
  },
  {
    name: 'nombre',
    displayName: 'Nombre',
    type: ColumnTypes.Standard,
  },
  {
    name: 'rutaCompleta',
    displayName: 'Ruta Completa',
    type: ColumnTypes.Standard,
  },
  {
    name: 'activoB2b',
    displayName: 'Activo B2B',
    type: ColumnTypes.Boolean,
  },
  {
    name: 'activoB2c',
    displayName: 'Activo B2C',
    type: ColumnTypes.Boolean,
  },
  {
    name: 'padre',
    displayName: 'Padre',
    type: ColumnTypes.Standard,
  },
  {
    name: 'orden',
    displayName: 'Orden',
    type: ColumnTypes.Standard,
  },
  {
    name: 'urlkey',
    displayName: 'URL',
    type: ColumnTypes.Standard,
  },
];

export const CATEGORIAS_BASE_URL: string = `${environment.api}${Domains.Categorias}`;

export const CATEGORIAS_FIELDS: EntityField[] = [
  {
    name: 'codigo',
    displayName: 'Código',
    type: FieldTypes.Standard,
    disabled: true,
    group: 1,
  },
  {
    name: 'nombre',
    displayName: 'Nombre',
    type: FieldTypes.Translatable,
    editDisabledForDefaultTranslationEntityField: true,
    group: 1,
  },
  {
    name: 'imagen',
    displayName: 'Imagen',
    type: FieldTypes.Image,
    imageUploadUrl: `${environment.api}${Domains.UploadCategoriaImagen}`,
    group: 1,
  },
  {
    name: 'activoB2b',
    displayName: 'Activo B2B',
    type: FieldTypes.Boolean,
    group: 1,
  },
  {
    name: 'activoB2c',
    displayName: 'Activo B2C',
    type: FieldTypes.Boolean,
    group: 1,
  },
  {
    name: 'descripcion',
    displayName: 'Descripción',
    type: FieldTypes.TranslatableTextarea,
    group: 1,
  },
  {
    name: 'padre',
    displayName: 'Padre',
    type: FieldTypes.Selector,
    isSingleSelector: true,
    optionsUrl: `${environment.api}${Domains.Dropdown}?table=${Domains.Categorias}`,
    group: 2,
    fullLength: true,
  },
  {
    name: 'orden',
    displayName: 'Orden',
    type: FieldTypes.Number,
    required: true,
    group: 2,
  },
  {
    name: 'urlkey',
    displayName: 'URL',
    type: FieldTypes.Translatable,
    required: true,
    group: 2,
  },
  {
    name: 'metaTitulo',
    displayName: 'Meta-título',
    type: FieldTypes.Translatable,
    group: 2,
  },
  {
    name: 'metaDescripcion',
    displayName: 'Meta-descripción',
    type: FieldTypes.Translatable,
    group: 2,
  },
  {
    name: 'metaKeywords',
    displayName: 'Meta-keywords',
    type: FieldTypes.Translatable,
    group: 2,
  },
];

export const CATEGORIAS_GROUPS: EntityFieldGroup[] = [
  {
    label: 'Información general',
    id: 1,
  },
  {
    label: 'Información específica',
    id: 2,
  },
];

export interface Categoria extends AbstractBerria {
  nombre: string;
  padre: any;
  padreId?: any;
  orden: number;
  urlkey: string;
  activoB2b: any;
  activoB2c: any;
  imagen: string;
}
