import { TableHeaders } from 'ekiba-master-table';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable } from 'rxjs/internal/Observable';
import { Subject } from 'rxjs/internal/Subject';
import { takeUntil } from 'rxjs/operators';
import { Status } from '../constants/constants';
import { AppInjector } from './app-injector';
import { EntityApiCalls } from './entity.api-calls';

export class Entity {
  protected api!: EntityApiCalls;
  private _data$ = new BehaviorSubject<any[]>([]);

  public columns: TableHeaders[] = [];
  public data$!: Observable<any[]>;

  protected _baseUrl: string = '';
  protected _destroyed$ = new Subject();

  constructor() {
    const injector = AppInjector.getInjector();

    this.api = injector.get(EntityApiCalls); // Manual Dependency Injection
  }

  public enableDisableData(data: any): void {
    if (data.status === Status.enable) {
      data.status = Status.disable;
    } else {
      data.status = Status.enable;
    }
    this._updateData(data);
  }

  public getData(): Observable<any[]> {
    return this._data$.asObservable();
  }

  protected _loadData() {
    return this.api
      .getData(this._baseUrl)
      .pipe(takeUntil(this._destroyed$))
      .subscribe(
        (data: any[]) => this._data$.next(data),
        (error: any) => console.error(error)
      );
  }

  protected _updateData(data: any, refresh = true) {
    this.api.updateData(data, this._baseUrl).subscribe((updatedData: any) => {
      if (refresh) {
        const data = this._data$.getValue();
        const indexOfUpdated = data.findIndex(
          (item) => item.id === updatedData.id
        );

        data[indexOfUpdated] = updatedData;
        this._data$.next([...data]);
      }
    });
  }

  protected _addData(data: any, refresh = true) {
    this.api.addData(data, this._baseUrl).subscribe(({ id }) => {
      if (refresh) {
        const addedrole: any = { ...data, id };
        const currentValue = this._data$.getValue();
        this._data$.next([...currentValue, addedrole]);
      }
    });
  }

  protected _deleteData(data: any) {
    this.api.deleteData(data, this._baseUrl).subscribe(() => {
      const currentValue = this._data$.getValue();
      this._data$.next(
        currentValue.filter((dataToRemove) => dataToRemove.id !== data.id)
      );
    });
  }
}
