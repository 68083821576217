import { Component } from '@angular/core';
import { MenuItem, NavbarService } from 'ekiba-navbar';
import { User } from 'src/app/constants/models';
import { AUTH_ROUTES, Rutas, Labels } from './constants/constants';
import { Router } from '@angular/router';
import { LoginService } from './pages/login/login.service';
import { delay, filter, tap } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialog } from './popups/confirm-dialog/confirm-dialog.component';
import { LoadingService } from './interceptors/loading.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'berria-gestor-de-productos';
  loading: boolean = false;

  constructor(
    public navbarService: NavbarService,
    private _dialog: MatDialog,
    private _login: LoginService,
    private _router: Router,
    private _loading: LoadingService
  ) {
    this._login.logged$
      .pipe(
        tap((logged) => {
          if (logged.logged) {
            this.userData = logged.user;
          }
        })
      )
      .subscribe();
    this._loading.loadingSub
      .pipe(delay(0)) // This prevents a ExpressionChangedAfterItHasBeenCheckedError for subsequent requests
      .subscribe((loading) => {
        this.loading = loading;
      });
    this.navbarService.loadMenuItems(this.menuItems);
  }

  public menuItems: MenuItem[] = [
    /* Clientes */
    {
      label: Labels.GestionDeClientes,
      icon: 'groups',
      routes: [],
      menuItems: [
        {
          label: Labels.Clientes,
          routes: [Rutas.GestionDeClientes, Rutas.Clientes, Rutas.List],
        },
        {
          label: Labels.Direcciones,
          routes: [Rutas.GestionDeClientes, Rutas.Direcciones, Rutas.List],
        },
        {
          label: Labels.GrupoClientes,
          routes: [Rutas.GestionDeClientes, Rutas.GrupoClientes, Rutas.List],
        },
      ],
    },
    /* Productos */
    {
      label: Labels.ConfiguracionDeProductos,
      icon: 'pedal_bike',
      routes: [],
      menuItems: [
        {
          label: Labels.Categorias,
          routes: [
            Rutas.ConfiguracionDeProductos,
            Rutas.Categorias,
            Rutas.List,
          ],
        },
        {
          label: Labels.Familias,
          routes: [Rutas.ConfiguracionDeProductos, Rutas.Familias, Rutas.List],
        },
        {
          label: Labels.Productos,
          routes: [Rutas.ConfiguracionDeProductos, Rutas.Productos, Rutas.List],
        },
        {
          label: Labels.Variantes,
          routes: [Rutas.ConfiguracionDeProductos, Rutas.Variantes, Rutas.List],
        },
      ],
    },
    /* Maestros */
    {
      label: Labels.ConfiguracionDeMaestros,
      icon: 'build_circle',
      routes: [],
      menuItems: [
        {
          label: Labels.Atributos,
          routes: [Rutas.ConfiguracionDeMaestros, Rutas.Atributos, Rutas.List],
        },
        {
          label: Labels.ValoresDeAtributo,
          routes: [
            Rutas.ConfiguracionDeMaestros,
            Rutas.ValoresDeAtributo,
            Rutas.List,
          ],
        },
        {
          label: Labels.Bielas,
          routes: [Rutas.ConfiguracionDeMaestros, Rutas.Bielas, Rutas.List],
        },
        {
          label: Labels.Colores,
          routes: [Rutas.ConfiguracionDeMaestros, Rutas.Colores, Rutas.List],
        },
        {
          label: Labels.Componentes,
          routes: [
            Rutas.ConfiguracionDeMaestros,
            Rutas.Componentes,
            Rutas.List,
          ],
        },
        {
          label: Labels.Cuadros,
          routes: [Rutas.ConfiguracionDeMaestros, Rutas.Cuadros, Rutas.List],
        },
        {
          label: Labels.GruposDeCambio,
          routes: [
            Rutas.ConfiguracionDeMaestros,
            Rutas.GruposDeCambio,
            Rutas.List,
          ],
        },
        {
          label: Labels.Manillares,
          routes: [Rutas.ConfiguracionDeMaestros, Rutas.Manillares, Rutas.List],
        },
        {
          label: Labels.Potencias,
          routes: [Rutas.ConfiguracionDeMaestros, Rutas.Potencias, Rutas.List],
        },
        {
          label: Labels.Ruedas,
          routes: [Rutas.ConfiguracionDeMaestros, Rutas.Ruedas, Rutas.List],
        },
        {
          label: Labels.ServiciosDePintura,
          routes: [
            Rutas.ConfiguracionDeMaestros,
            Rutas.ServiciosDePintura,
            Rutas.List,
          ],
        },
        {
          label: Labels.Tallas,
          routes: [Rutas.ConfiguracionDeMaestros, Rutas.Tallas, Rutas.List],
        },
        {
          label: Labels.Tecnologias,
          routes: [
            Rutas.ConfiguracionDeMaestros,
            Rutas.Tecnologias,
            Rutas.List,
          ],
        },
      ],
    },
    /* General */
    {
      label: Labels.ConfiguracionGeneral,
      icon: 'language',
      routes: [],
      menuItems: [
        {
          label: Labels.Idiomas,
          routes: [Rutas.ConfiguracionGeneral, Rutas.Idiomas, Rutas.List],
        },
        {
          label: Labels.Tiendas,
          routes: [Rutas.ConfiguracionGeneral, Rutas.Tiendas, Rutas.List],
        },
        {
          label: Labels.Traducciones,
          routes: [Rutas.ConfiguracionGeneral, Rutas.Traducciones, Rutas.List],
        },
      ],
    },
    /* Integraciones */
    {
      label: Labels.Integraciones,
      icon: 'qr_code',
      routes: [],
      menuItems: [
        {
          label: Labels.Tareas,
          routes: [Rutas.Integraciones, Rutas.Tareas, Rutas.List],
        },
      ],
    },
    /* Usuarios */
    {
      label: Labels.Usuarios,
      icon: 'person',
      routes: [],
      menuItems: [
        {
          label: Labels.Listado,
          routes: [Rutas.Usuarios, Rutas.Listado, Rutas.List],
        },
        {
          label: Labels.Roles,
          routes: [Rutas.Usuarios, Rutas.Roles, Rutas.List],
        },
        {
          label: Labels.Permisos,
          routes: [Rutas.Usuarios, Rutas.Permisos, Rutas.List],
        },
      ],
    },
  ];

  public userData: User = {
    nombre: '',
    roles: [{ nombre: '' }],
  };

  public logOut() {
    const dialog = this._dialog.open(ConfirmDialog, {
      data: {
        texto: '¿Está seguro de que desea cerrar sesión?',
      },
    });

    dialog
      .afterClosed()
      .pipe(
        filter((value) => value),
        tap(() => {
          this._login.logout();
          this._router.navigate(['/login']);
        })
      )
      .subscribe();
  }

  public hasError(): boolean {
    const hasError = AUTH_ROUTES.some((route) => route === this._router.url);
    return hasError;
  }
}
