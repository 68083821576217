import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { LoggedSubject } from './login.models';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  public logged$: BehaviorSubject<LoggedSubject> =
    new BehaviorSubject<LoggedSubject>({
      logged: false,
      berriaGestorDeProductosToken: '',
      user: { nombre: '', roles: [] },
    });

  constructor(private readonly _http: HttpClient) {}

  public login(data: any, url: string): Observable<any> {
    return this._http.post<any>(url, data);
  }

  public logout(): void {
    this.logged$.next({
      logged: false,
      berriaGestorDeProductosToken: '',
      user: { nombre: '', roles: [] },
    });
    localStorage.removeItem('berriaGestorDeProductosLogin');
  }
}
