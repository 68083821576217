import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RedirectionType } from 'ekiba-master-table';

import { Labels, Rutas, SKIP_AUTH } from 'src/app/constants/constants';
import { AuthService } from 'src/app/shared/auth.service';
import { Entity } from 'src/app/shared/entity';
import { PageIndexerService } from 'src/app/shared/page-indexer.service';
import {
  TIENDAS_BASE_URL,
  TABLE_HEADERS_TIENDAS,
  Tienda,
} from './tiendas.constants';
@Component({
  templateUrl: './tiendas.component.html',
})
export class TiendasComponent extends Entity implements OnInit {
  formattedTiendas!: Tienda[];
  public readonly Labels: typeof Labels = Labels;
  public readonly Rutas: typeof Rutas = Rutas;
  public readonly RedirectionType: typeof RedirectionType = RedirectionType;
  public readonly authURL: string = `${Rutas.ConfiguracionGeneral}/${Rutas.Tiendas}`;
  public readonly editUrl: string = `${this.authURL}/${Rutas.Edit}`;
  public SKIP_AUTH: boolean = SKIP_AUTH;

  constructor(
    public auth: AuthService,
    private _router: Router,
    public pageIndexer: PageIndexerService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.columns = TABLE_HEADERS_TIENDAS;
    this._baseUrl = TIENDAS_BASE_URL;
    this.data$ = this.getData();
    this._loadData();
    this.data$.subscribe((data) => {
      this.formattedTiendas = this.formatTiendas(data);
    });
  }

  public formatTiendas(data: Tienda[]): Tienda[] {
    return data.map((item: any) => {
      return {
        ...item,
        activo: !!item.activo,
      };
    });
  }

  public ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  public add(): void {
    this._router.navigate([
      `${Rutas.ConfiguracionGeneral}/${Rutas.Tiendas}/${Rutas.Add}`,
    ]);
  }

  public delete(tienda: Tienda): void {
    this._deleteData(tienda);
  }
}
