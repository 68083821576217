import { Route, Routes } from '@angular/router';
import { Rutas } from './constants/constants';
import { AddEditEntityComponent } from './pages/add-edit-entity/add-edit-entity.component';
import { NoAutorizadoComponent } from './pages/errors/no-autorizado/no-autorizado.component';
import { PaginaNoEncontradaComponent } from './pages/errors/pagina-no-encontrada/pagina-no-encontrada.component';
import { BielasComponent } from './pages/configuracion-de-maestros/bielas/bielas.component';
import { FamiliasComponent } from './pages/configuracion-de-maestros/familias/familias.component';
import { GruposDeCambioComponent } from './pages/configuracion-de-maestros/grupos-de-cambio/grupos-de-cambio.component';
import { IdiomasComponent } from './pages/configuracion-general/idiomas/idiomas.component';
import { ComponentesComponent } from './pages/configuracion-de-maestros/componentes/componentes.component';
import { PotenciasComponent } from './pages/configuracion-de-maestros/potencias/potencias.component';
import { ServiciosDePinturaComponent } from './pages/configuracion-de-maestros/servicios-de-pintura/servicios-de-pintura.component';
import { TallasComponent } from './pages/configuracion-de-maestros/tallas/tallas.component';
import { LoginComponent } from './pages/login/login.component';
import { PermissionsComponent } from './pages/gestion-de-usuarios/permissions/permissions.component';
import { RolesComponent } from './pages/gestion-de-usuarios/roles/roles.component';
import { UsuariosComponent } from './pages/gestion-de-usuarios/users-list/usuarios.component';
import { HomeComponent } from './pages/home/home.component';
import { LoginGuard } from './guards/login.guard';
import { AuthorizationGuard } from './guards/authorization.guard';
import { CategoriasComponent } from './pages/configuracion-de-productos/categorias/categorias.component';
import { ColoresComponent } from './pages/configuracion-de-maestros/colores/colores.component';
import { ManillaresComponent } from './pages/configuracion-de-maestros/manillares/manillares.component';
import { AtributosComponent } from './pages/configuracion-de-maestros/atributos/atributos.component';
import { CuadrosComponent } from './pages/configuracion-de-maestros/cuadros/cuadros.component';
import { RuedasComponent } from './pages/configuracion-de-maestros/ruedas/ruedas.component';
import { ProductosComponent } from './pages/configuracion-de-productos/productos/productos.component';
import { VariantesComponent } from './pages/configuracion-de-productos/variantes/variantes.component';
import { TiendasComponent } from './pages/configuracion-general/tiendas/tiendas.component';
import { TareasComponent } from './pages/integraciones/tareas/tareas.component';
import { AddEditCuadroComponent } from './pages/configuracion-de-maestros/cuadros/add-edit-cuadro/add-edit-cuadro.component';
import { ValoresDeAtributosComponent } from './pages/configuracion-de-maestros/valores-de-atributo/valores-de-atributo.component';
import { AddEditProductoComponent } from './pages/configuracion-de-productos/productos/add-edit-producto/add-edit-producto.component';
import { AddEditComponentesComponent } from './pages/configuracion-de-maestros/componentes/add-edit-componentes.component';
import { NavbarGuard } from 'ekiba-navbar';
import { TraduccionesComponent } from './pages/configuracion-general/traducciones/traducciones.component';
import { AddEditTraduccionesComponent } from './pages/configuracion-general/traducciones/add-edit-traducciones.component';
import { ClientesComponent } from './pages/gestion-de-clientes/clientes/clientes.component';
import { DireccionesComponent } from './pages/gestion-de-clientes/direcciones/direcciones.component';
import { GruposClientesComponent } from './pages/gestion-de-clientes/grupos-clientes/grupos-clientes.component';
import { TecnologiasComponent } from './pages/configuracion-de-maestros/tecnologias/tecnologias.component';
import { EditVarianteComponent } from './pages/configuracion-de-productos/variantes/edit-variante.component';
import { AddEditServicioDePinturaComponent } from './pages/configuracion-de-maestros/servicios-de-pintura/add-edit-servicio-de-pintura.component';
import { AddEditCategoriasComponent } from './pages/configuracion-de-productos/categorias/add-edit-categoria.component';

/* Pages */

const _home: Route = {
  path: 'inicio',
  component: HomeComponent,
  canActivate: [NavbarGuard, LoginGuard],
};

const _login: Route = {
  path: 'login',
  component: LoginComponent,
};

const _errors: Routes = [
  {
    path: Rutas.PaginaNoEncontrada,
    component: PaginaNoEncontradaComponent,
  },
  {
    path: Rutas.NoAutorizado,
    component: NoAutorizadoComponent,
  },
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: Rutas.PaginaNoEncontrada,
    pathMatch: 'full',
  },
];

const _roles: Routes = [
  {
    path: Rutas.List,
    component: RolesComponent,
    data: { linkActive: 'D1-P5-Id1' },
    canActivate: [NavbarGuard, LoginGuard, AuthorizationGuard],
  },
  {
    path: `${Rutas.Add}`,
    component: AddEditEntityComponent,
    data: { linkActive: 'D1-P5-Id1' },
    canActivate: [NavbarGuard, LoginGuard, AuthorizationGuard],
  },
  {
    path: `${Rutas.Edit}/:id`,
    component: AddEditEntityComponent,
    data: { linkActive: 'D1-P5-Id1' },
    canActivate: [NavbarGuard, LoginGuard, AuthorizationGuard],
  },
];

const _listado: Routes = [
  {
    path: Rutas.List,
    component: UsuariosComponent,
    data: { linkActive: 'D1-P5-Id0' },
    canActivate: [NavbarGuard, LoginGuard, AuthorizationGuard],
  },
  {
    path: `${Rutas.Add}`,
    component: AddEditEntityComponent,
    data: { linkActive: 'D1-P5-Id0' },
    canActivate: [NavbarGuard, LoginGuard, AuthorizationGuard],
  },
  {
    path: `${Rutas.Edit}/:id`,
    component: AddEditEntityComponent,
    data: { linkActive: 'D1-P5-Id0' },
    canActivate: [NavbarGuard, LoginGuard, AuthorizationGuard],
  },
];

const _permisos: Route = {
  path: `${Rutas.Permisos}/${Rutas.List}`,
  component: PermissionsComponent,
  canActivate: [NavbarGuard, LoginGuard, AuthorizationGuard],
};

const _familias: Routes = [
  {
    path: Rutas.List,
    component: FamiliasComponent,
    data: { linkActive: 'D1-P1-Id1' },
    canActivate: [NavbarGuard, LoginGuard, AuthorizationGuard],
  },
];

const _tallas: Routes = [
  {
    path: Rutas.List,
    component: TallasComponent,
    data: { linkActive: 'D1-P2-Id11' },
    canActivate: [NavbarGuard, LoginGuard, AuthorizationGuard],
  },
  {
    path: `${Rutas.Edit}/:id`,
    component: AddEditEntityComponent,
    data: { linkActive: 'D1-P2-Id11' },
    canActivate: [NavbarGuard, LoginGuard, AuthorizationGuard],
  },
];

const _serviciosDePintura: Routes = [
  {
    path: Rutas.List,
    component: ServiciosDePinturaComponent,
    data: { linkActive: 'D1-P2-Id10' },
    canActivate: [NavbarGuard, LoginGuard, AuthorizationGuard],
  },
  {
    path: `${Rutas.Edit}/:id`,
    component: AddEditServicioDePinturaComponent,
    data: { linkActive: 'D1-P2-Id10' },
    canActivate: [NavbarGuard, LoginGuard, AuthorizationGuard],
  },
];

const _bielas: Routes = [
  {
    path: Rutas.List,
    component: BielasComponent,
    data: { linkActive: 'D1-P2-Id2' },
    canActivate: [NavbarGuard, LoginGuard, AuthorizationGuard],
  },
  {
    path: `${Rutas.Edit}/:id`,
    component: AddEditEntityComponent,
    data: { linkActive: 'D1-P2-Id2' },
    canActivate: [NavbarGuard, LoginGuard, AuthorizationGuard],
  },
];

const _potencias: Routes = [
  {
    path: Rutas.List,
    component: PotenciasComponent,
    data: { linkActive: 'D1-P2-Id8' },
    canActivate: [NavbarGuard, LoginGuard, AuthorizationGuard],
  },
  {
    path: `${Rutas.Edit}/:id`,
    component: AddEditEntityComponent,
    data: { linkActive: 'D1-P2-Id8' },
    canActivate: [NavbarGuard, LoginGuard, AuthorizationGuard],
  },
];

const _componentes: Routes = [
  {
    path: Rutas.List,
    component: ComponentesComponent,
    data: { linkActive: 'D1-P2-Id4' },
    canActivate: [NavbarGuard, LoginGuard, AuthorizationGuard],
  },
  {
    path: `${Rutas.Edit}/:id`,
    component: AddEditComponentesComponent,
    data: { linkActive: 'D1-P2-Id4' },
    canActivate: [NavbarGuard, LoginGuard, AuthorizationGuard],
  },
];

const _colores: Routes = [
  {
    path: Rutas.List,
    component: ColoresComponent,
    data: { linkActive: 'D1-P2-Id3' },
    canActivate: [NavbarGuard, LoginGuard, AuthorizationGuard],
  },
  {
    path: `${Rutas.Edit}/:id`,
    component: AddEditEntityComponent,
    data: { linkActive: 'D1-P2-Id3' },
    canActivate: [NavbarGuard, LoginGuard, AuthorizationGuard],
  },
];

const _gruposDeCambio: Routes = [
  {
    path: Rutas.List,
    component: GruposDeCambioComponent,
    data: { linkActive: 'D1-P2-Id6' },
    canActivate: [NavbarGuard, LoginGuard, AuthorizationGuard],
  },
  {
    path: `${Rutas.Edit}/:id`,
    component: AddEditEntityComponent,
    data: { linkActive: 'D1-P2-Id6' },
    canActivate: [NavbarGuard, LoginGuard, AuthorizationGuard],
  },
];

const _manillares: Routes = [
  {
    path: Rutas.List,
    component: ManillaresComponent,
    data: { linkActive: 'D1-P2-Id7' },
    canActivate: [NavbarGuard, LoginGuard, AuthorizationGuard],
  },
  {
    path: `${Rutas.Add}`,
    component: AddEditEntityComponent,
    data: { linkActive: 'D1-P2-Id7' },
    canActivate: [NavbarGuard, LoginGuard, AuthorizationGuard],
  },
  {
    path: `${Rutas.Edit}/:id`,
    component: AddEditEntityComponent,
    data: { linkActive: 'D1-P2-Id7' },
    canActivate: [NavbarGuard, LoginGuard, AuthorizationGuard],
  },
];

const _atributos: Routes = [
  {
    path: Rutas.List,
    component: AtributosComponent,
    data: { linkActive: 'D1-P2-Id0' },
    canActivate: [NavbarGuard, LoginGuard, AuthorizationGuard],
  },
  {
    path: `${Rutas.Add}`,
    component: AddEditEntityComponent,
    data: { linkActive: 'D1-P2-Id0' },
    canActivate: [NavbarGuard, LoginGuard, AuthorizationGuard],
  },
  {
    path: `${Rutas.Edit}/:id`,
    component: AddEditEntityComponent,
    data: { linkActive: 'D1-P2-Id0' },
    canActivate: [NavbarGuard, LoginGuard, AuthorizationGuard],
  },
];

const _valoresDeAtributo: Routes = [
  {
    path: Rutas.List,
    component: ValoresDeAtributosComponent,
    data: { linkActive: 'D1-P2-Id1' },
    canActivate: [NavbarGuard, LoginGuard, AuthorizationGuard],
  },
  {
    path: `${Rutas.Add}`,
    component: AddEditEntityComponent,
    data: { linkActive: 'D1-P2-Id1' },
    canActivate: [NavbarGuard, LoginGuard, AuthorizationGuard],
  },
  {
    path: `${Rutas.Edit}/:id`,
    component: AddEditEntityComponent,
    data: { linkActive: 'D1-P2-Id1' },
    canActivate: [NavbarGuard, LoginGuard, AuthorizationGuard],
  },
];

const _cuadros: Routes = [
  {
    path: Rutas.List,
    component: CuadrosComponent,
    data: { linkActive: 'D1-P2-Id5' },
    canActivate: [NavbarGuard, LoginGuard, AuthorizationGuard],
  },
  {
    path: `${Rutas.Edit}/:id`,
    component: AddEditCuadroComponent,
    data: { linkActive: 'D1-P2-Id5' },
    canActivate: [NavbarGuard, LoginGuard, AuthorizationGuard],
  },
];

const _ruedas: Routes = [
  {
    path: Rutas.List,
    component: RuedasComponent,
    data: { linkActive: 'D1-P2-Id9' },
    canActivate: [NavbarGuard, LoginGuard, AuthorizationGuard],
  },
  {
    path: `${Rutas.Edit}/:id`,
    component: AddEditEntityComponent,
    data: { linkActive: 'D1-P2-Id9' },
    canActivate: [NavbarGuard, LoginGuard, AuthorizationGuard],
  },
];

const _idiomas: Routes = [
  {
    path: Rutas.List,
    component: IdiomasComponent,
    data: { linkActive: 'D1-P3-Id0' },
    canActivate: [NavbarGuard, LoginGuard, AuthorizationGuard],
  },
  {
    path: `${Rutas.Add}`,
    component: AddEditEntityComponent,
    data: { linkActive: 'D1-P3-Id0' },
    canActivate: [NavbarGuard, LoginGuard, AuthorizationGuard],
  },
  {
    path: `${Rutas.Edit}/:id`,
    component: AddEditEntityComponent,
    data: { linkActive: 'D1-P3-Id0' },
    canActivate: [NavbarGuard, LoginGuard, AuthorizationGuard],
  },
];

const _tiendas: Routes = [
  {
    path: Rutas.List,
    component: TiendasComponent,
    data: { linkActive: 'D1-P3-Id1' },
    canActivate: [NavbarGuard, LoginGuard, AuthorizationGuard],
  },
  {
    path: `${Rutas.Add}`,
    component: AddEditEntityComponent,
    data: { linkActive: 'D1-P3-Id1' },
    canActivate: [NavbarGuard, LoginGuard, AuthorizationGuard],
  },
  {
    path: `${Rutas.Edit}/:id`,
    component: AddEditEntityComponent,
    data: { linkActive: 'D1-P3-Id1' },
    canActivate: [NavbarGuard, LoginGuard, AuthorizationGuard],
  },
];

const _traducciones: Routes = [
  {
    path: Rutas.List,
    component: TraduccionesComponent,
    data: { linkActive: 'D1-P3-Id3' },
    canActivate: [NavbarGuard, LoginGuard, AuthorizationGuard],
  },
  {
    path: `${Rutas.Add}`,
    component: AddEditTraduccionesComponent,
    data: { linkActive: 'D1-P3-Id3' },
    canActivate: [NavbarGuard, LoginGuard, AuthorizationGuard],
  },
  {
    path: `${Rutas.Edit}/:id`,
    component: AddEditTraduccionesComponent,
    data: { linkActive: 'D1-P3-Id3' },
    canActivate: [NavbarGuard, LoginGuard, AuthorizationGuard],
  },
];

const _categorias: Routes = [
  {
    path: Rutas.List,
    component: CategoriasComponent,
    data: { linkActive: 'D1-P1-Id0' },
    canActivate: [NavbarGuard, LoginGuard, AuthorizationGuard],
  },
  {
    path: `${Rutas.Add}`,
    component: AddEditCategoriasComponent,
    data: { linkActive: 'D1-P1-Id0' },
    canActivate: [NavbarGuard, LoginGuard, AuthorizationGuard],
  },
  {
    path: `${Rutas.Edit}/:id`,
    component: AddEditCategoriasComponent,
    data: { linkActive: 'D1-P1-Id0' },
    canActivate: [NavbarGuard, LoginGuard, AuthorizationGuard],
  },
];

const _productos: Routes = [
  {
    path: Rutas.List,
    component: ProductosComponent,
    data: { linkActive: 'D1-P1-Id2' },
    canActivate: [NavbarGuard, LoginGuard, AuthorizationGuard],
  },
  {
    path: `${Rutas.Add}`,
    component: AddEditProductoComponent,
    data: { linkActive: 'D1-P1-Id2' },
    canActivate: [NavbarGuard, LoginGuard, AuthorizationGuard],
  },
  {
    path: `${Rutas.Edit}/:id`,
    component: AddEditProductoComponent,
    data: { linkActive: 'D1-P1-Id2' },
    canActivate: [NavbarGuard, LoginGuard, AuthorizationGuard],
  },
];

const _variantes: Routes = [
  {
    path: Rutas.List,
    component: VariantesComponent,
    data: { linkActive: 'D1-P1-Id3' },
    canActivate: [NavbarGuard, LoginGuard, AuthorizationGuard],
  },
  {
    path: `${Rutas.Add}`,
    component: AddEditEntityComponent,
    data: { linkActive: 'D1-P1-Id3' },
    canActivate: [NavbarGuard, LoginGuard, AuthorizationGuard],
  },
  {
    path: `${Rutas.Edit}/:id`,
    component: EditVarianteComponent,
    data: { linkActive: 'D1-P1-Id3' },
    canActivate: [NavbarGuard, LoginGuard, AuthorizationGuard],
  },
];

const _tareas: Routes = [
  {
    path: Rutas.List,
    component: TareasComponent,
    data: { linkActive: 'D1-P4-Id0' },
    canActivate: [NavbarGuard, LoginGuard, AuthorizationGuard],
  },
];

const _clientes: Routes = [
  {
    path: Rutas.List,
    component: ClientesComponent,
    data: { linkActive: 'D1-P0-Id0' },
    canActivate: [NavbarGuard, LoginGuard, AuthorizationGuard],
  },
  {
    path: `${Rutas.View}/:id`,
    component: AddEditEntityComponent,
    data: { linkActive: 'D1-P0-Id0' },
    canActivate: [NavbarGuard, LoginGuard, AuthorizationGuard],
  },
];

const _direcciones: Routes = [
  {
    path: Rutas.List,
    component: DireccionesComponent,
    data: { linkActive: 'D1-P0-Id1' },
    canActivate: [NavbarGuard, LoginGuard, AuthorizationGuard],
  },
  {
    path: `${Rutas.View}/:id`,
    component: AddEditEntityComponent,
    data: { linkActive: 'D1-P0-Id1' },
    canActivate: [NavbarGuard, LoginGuard, AuthorizationGuard],
  },
];

const _gruposClientes: Routes = [
  {
    path: Rutas.List,
    component: GruposClientesComponent,
    data: { linkActive: 'D1-P0-Id2' },
    canActivate: [NavbarGuard, LoginGuard, AuthorizationGuard],
  },
  {
    path: `${Rutas.View}/:id`,
    component: AddEditEntityComponent,
    data: { linkActive: 'D1-P0-Id2' },
    canActivate: [NavbarGuard, LoginGuard, AuthorizationGuard],
  },
];

const _tecnologias: Routes = [
  {
    path: Rutas.List,
    component: TecnologiasComponent,
    data: { linkActive: 'D1-P2-Id12' },
    canActivate: [NavbarGuard, LoginGuard, AuthorizationGuard],
  },
  {
    path: `${Rutas.Add}`,
    component: AddEditEntityComponent,
    data: { linkActive: 'D1-P2-Id12' },
    canActivate: [NavbarGuard, LoginGuard, AuthorizationGuard],
  },
  {
    path: `${Rutas.Edit}/:id`,
    component: AddEditEntityComponent,
    data: { linkActive: 'D1-P2-Id12' },
    canActivate: [NavbarGuard, LoginGuard, AuthorizationGuard],
  },
];

/* Modules */

const _usuarios: Route = {
  path: Rutas.Usuarios,
  children: [
    {
      path: Rutas.Roles,
      children: _roles,
    },
    {
      path: Rutas.Listado,
      children: _listado,
    },
    _permisos,
  ],
};

const _configuracionDeProductos: Route = {
  path: Rutas.ConfiguracionDeMaestros,
  children: [
    {
      path: Rutas.Tallas,
      children: _tallas,
    },
    {
      path: Rutas.ServiciosDePintura,
      children: _serviciosDePintura,
    },
    {
      path: Rutas.Bielas,
      children: _bielas,
    },
    {
      path: Rutas.Potencias,
      children: _potencias,
    },
    {
      path: Rutas.Componentes,
      children: _componentes,
    },
    {
      path: Rutas.Colores,
      children: _colores,
    },
    {
      path: Rutas.GruposDeCambio,
      children: _gruposDeCambio,
    },
    {
      path: Rutas.Manillares,
      children: _manillares,
    },
    {
      path: Rutas.Atributos,
      children: _atributos,
    },
    {
      path: Rutas.Cuadros,
      children: _cuadros,
    },
    {
      path: Rutas.Ruedas,
      children: _ruedas,
    },
    {
      path: Rutas.Tecnologias,
      children: _tecnologias,
    },
    {
      path: Rutas.ValoresDeAtributo,
      children: _valoresDeAtributo,
    },
  ],
};

const _gestionDeProductos: Route = {
  path: Rutas.ConfiguracionDeProductos,
  children: [
    {
      path: Rutas.Categorias,
      children: _categorias,
    },
    {
      path: Rutas.Familias,
      children: _familias,
    },
    {
      path: Rutas.Productos,
      children: _productos,
    },
    {
      path: Rutas.Variantes,
      children: _variantes,
    },
  ],
};

const _integraciones: Route = {
  path: Rutas.Integraciones,
  children: [
    {
      path: Rutas.Tareas,
      children: _tareas,
    },
  ],
};

const _configuracionGeneral: Route = {
  path: Rutas.ConfiguracionGeneral,
  children: [
    {
      path: Rutas.Idiomas,
      children: _idiomas,
    },
    {
      path: Rutas.Tiendas,
      children: _tiendas,
    },
    {
      path: Rutas.Traducciones,
      children: _traducciones,
    },
  ],
};

const _gestionDeClientes: Route = {
  path: Rutas.GestionDeClientes,
  children: [
    {
      path: Rutas.Clientes,
      children: _clientes,
    },
    {
      path: Rutas.Direcciones,
      children: _direcciones,
    },
    {
      path: Rutas.GrupoClientes,
      children: _gruposClientes,
    },
  ],
};

export const routes: Routes = [
  _home,
  _login,
  _usuarios,
  _configuracionDeProductos,
  _gestionDeProductos,
  _integraciones,
  _configuracionGeneral,
  _gestionDeClientes,
  ..._errors,
];
