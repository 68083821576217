export const MAX_LENGTH = 250;
export const SKIP_AUTH: boolean = false;

export enum Status {
  enable = 1,
  disable = 0,
}

export enum Labels {
  // Menu
  Usuarios = 'Gestión de usuarios',
  ConfiguracionDeMaestros = 'Configuración maestros',
  ConfiguracionDeProductos = 'Configuración productos',
  ConfiguracionGeneral = 'Configuración general',
  Integraciones = 'Integraciones',
  GestionDeClientes = 'Gestion de clientes',

  Listado = 'Usuarios',
  Roles = 'Roles',
  Permisos = 'Permisos',
  Familias = 'Familias',
  Tallas = 'Tallas',
  ServiciosDePintura = 'Servicios de pintura',
  Bielas = 'Bielas',
  Potencias = 'Potencias',
  Llantas = 'Llantas',
  Neumaticos = 'Neumaticos',
  GruposDeCambio = 'Grupos de cambio',
  Idiomas = 'Idiomas',
  Inicio = 'Inicio',
  Categorias = 'Categorías',
  Componentes = 'Componentes',
  Colores = 'Colores',
  Manillares = 'Manillares',
  Atributos = 'Atributos',
  Cuadros = 'Cuadros',
  Ruedas = 'Ruedas',
  Productos = 'Productos',
  Variantes = 'Variantes',
  Tiendas = 'Tiendas',
  Configuracion = 'Configuracion',
  ListadoDePendientes = 'Listado de Pendientes',
  HistoricoDeSincronizacion = 'Histórico De Sincronizacion',
  ValoresDeAtributo = 'Valores de Atributo',
  Geometria = 'Geometría',
  Ergonomia = 'Ergonomía',
  Traducciones = 'Traducciones',
  Tareas = 'Tareas',
  Clientes = 'Clientes',
  Direcciones = 'Direcciones',
  GrupoClientes = 'Grupos de clientes',
  Tecnologias = 'Tecnologias',

  // Actions
  Add = 'Añadir',
  Delete = 'Borrar',
  Edit = 'Editar',
  View = 'Ver',

  // Buttons
  Guardar = 'Guardar',
  Cancelar = 'Cancelar',
}

export enum Domains {
  Usuarios = 'gestion-de-usuarios',
  ConfiguracionDeMaestros = 'configuracion-de-productos',
  ConfiguracionDeProductos = 'gestion-de-productos',
  ConfiguracionGeneral = 'configuracion-general',
  Integraciones = 'integraciones',
  GestionDeClientes = 'gestion-b2b',

  Listado = 'usuarios',
  Roles = 'roles',
  Permisos = 'permisos',
  Familias = 'gp_familia',
  Tallas = 'gp_talla',
  ServiciosDePintura = 'gp_pintura',
  Bielas = 'gp_biela',
  Potencias = 'gp_potencia',
  Llantas = 'gp_llanta',
  Neumaticos = 'gp_neumatico',
  GruposDeCambio = 'gp_grupocambio',
  Idiomas = 'gp_idioma',
  IdiomasShared = 'gp_idioma/shared',
  Categorias = 'gp_categoria',
  Componentes = 'gp_componente',
  Colores = 'gp_color',
  Manillares = 'gp_manillar',
  Atributos = 'gp_atributo',
  AtributoOpciones = 'gp_atributo_opcion',
  Cuadros = 'gp_cuadro',
  CuadrosFull = 'gp_cuadro/full',
  Ruedas = 'gp_rueda',
  Productos = 'gp_producto',
  Variantes = 'gp_producto_variante',
  Tiendas = 'gp_tienda',
  Configuracion = 'gp_configuracion',
  ListadoDePendientes = 'gp_c2_pendiente',
  HistoricoDeSincronizacion = 'gp_c2_pendiente',
  Modelos = 'gp_producto_modelo',
  Geometria = 'gp_cuadro_geometria',
  TiposGeometria = 'gp_cuadro_geometria_tipo',
  Traducciones = 'gp_traducciones',
  TraduccionesShared = 'gp_traducciones/shared',
  Dropdown = 'generic/dropdown',
  DropdownComponent = 'generic/dropdownComponentDescription',
  UploadProductoImagen = 'gp_producto/updateProductPicture',
  UploadColorImagen = 'gp_color/updateColorPicture',
  UploadCuadroImagen = 'gp_cuadro/updateGeomPicture',
  UploadGrupocambioImagen = 'gp_grupocambio/updateChangeGroupPicture',
  UploadRuedaImagen = 'gp_rueda/updateRuedaPicture',
  UploadTecnologiaImagen = 'gp_tecnologia/updateTechnologyPicture',
  UploadCategoriaImagen = 'gp_categoria/updateCategoryPicture',
  Tareas = 'gp_c2_pendiente',
  PRIMES = 'gp_prime',
  Clientes = 'gp_b2b_cliente',
  Direcciones = 'gp_b2b_direccion',
  GrupoClientes = 'gp_b2b_grupocliente',
  Tecnologias = 'gp_tecnologia',
  Disponibilidad = 'gp_b2b_disponibilidad',
  PlanDeProduccion = 'gp_b2b_plan_produccion',
  PostTraduccionesMultiple = 'gp_traducciones/multiple',

  PaginaNoEncontrada = 'pagina-no-encontrada',
  NoAutorizado = 'no-autorizado',
  Autenticacion = 'autenticacion',
  Auth = 'authorizations',
  Login = 'login',
  Inicio = 'inicio',

  Add = 'add',
  Delete = 'delete',
  Edit = 'edit',
  View = 'view',
}

export enum Rutas {
  Usuarios = 'gestion-de-usuarios',
  ConfiguracionDeMaestros = 'configuracion-de-maestros',
  ConfiguracionDeProductos = 'configuracion-de-productos',
  ConfiguracionGeneral = 'configuracion-general',
  Integraciones = 'integraciones',
  GestionDeClientes = 'gestion-clientes',

  Listado = 'usuario',
  Roles = 'rol',
  Permisos = 'permiso',
  Familias = 'familias',
  Tallas = 'tallas',
  ServiciosDePintura = 'pinturas',
  Bielas = 'bielas',
  Potencias = 'potencias',
  Llantas = 'llantas',
  Neumaticos = 'neumaticos',
  GruposDeCambio = 'grupo-cambios',
  Idiomas = 'idiomas',
  Categorias = 'categorias',
  Componentes = 'componentes',
  Colores = 'colores',
  Manillares = 'manillares',
  Atributos = 'atributos',
  Cuadros = 'cuadros',
  Ruedas = 'ruedas',
  Productos = 'productos',
  Variantes = 'variantes',
  Tiendas = 'tiendas',
  Configuracion = 'configuracion',
  ListadoDePendientes = 'listado-de-pendientes',
  HistoricoDeSincronizacion = 'historico-de_sincronizacion',
  ValoresDeAtributo = 'valores-de-atributo',
  Traducciones = 'traducciones',
  Tareas = 'tareas',
  Clientes = 'clientes',
  Direcciones = 'direcciones',
  GrupoClientes = 'grupos-clientes',
  Tecnologias = 'tecnologias',

  PaginaNoEncontrada = 'pagina-no-encontrada',
  NoAutorizado = 'no-autorizado',
  Login = 'login',
  Inicio = 'inicio',

  Add = 'add',
  Delete = 'delete',
  Edit = 'edit',
  View = 'view',
  List = 'list',
}

export enum FieldTypes {
  Date = 'date',
  Hour = 'hour',
  Standard = 'standard',
  Translatable = 'translatable',
  TranslatableTextarea = 'translatable-textarea',
  Boolean = 'boolean',
  Selector = 'selector',
  Image = 'image',
  Number = 'number',
  Filler = 'filler',
}

export const AUTH_ROUTES = [
  '/login',
  '/pagina-no-encontrada',
  '/no-autorizado',
];
