import { ColumnTypes, TableHeaders } from 'ekiba-master-table';
import { Domains, FieldTypes } from 'src/app/constants/constants';
import { AbstractBerria } from 'src/app/constants/models';
import { environment } from 'src/environments/environment';
import {
  EntityField,
  EntityFieldGroup,
} from '../../add-edit-entity/add-edit-entity.component';

export const TABLE_HEADERS_DIRECCIONES: TableHeaders[] = [
  {
    name: 'id',
    displayName: 'ID',
    type: ColumnTypes.Standard,
  },
  {
    name: 'codigo',
    displayName: 'Código',
    type: ColumnTypes.Standard,
  },
  {
    name: 'nombre',
    displayName: 'Nombre',
    type: ColumnTypes.Standard,
  },
  {
    name: 'cliente',
    displayName: 'Cliente',
    type: ColumnTypes.Standard,
  },
];

export const DIRECCIONES_BASE_URL: string = `${environment.api}${Domains.Direcciones}`;

export const DIRECCIONES_FIELDS: EntityField[] = [
  {
    name: 'codigo',
    displayName: 'Codigo',
    type: FieldTypes.Standard,
    disabled: true,
    group: 1,
  },
  {
    name: 'nombre',
    displayName: 'Nombre',
    type: FieldTypes.Translatable,
    editDisabledForDefaultTranslationEntityField: true,
    group: 1,
  },
  {
    name: 'cliente',
    displayName: 'Cliente',
    type: FieldTypes.Selector,
    isSingleSelector: true,
    optionsUrl: `${environment.api}${Domains.Clientes}`,
    group: 2,
  },
  {
    name: 'direccion',
    displayName: 'Dirección',
    type: FieldTypes.Standard,
    group: 2,
  },
  {
    name: 'telefono',
    displayName: 'Teléfono fijo',
    type: FieldTypes.Standard,
    group: 2,
  },
  {
    name: 'movil',
    displayName: 'Teléfono Móvil',
    type: FieldTypes.Standard,
    group: 2,
  },
  {
    name: 'pais',
    displayName: 'País',
    type: FieldTypes.Standard,
    group: 2,
  },
  {
    name: 'poblacion',
    displayName: 'Población',
    type: FieldTypes.Standard,
    group: 2,
  },
  {
    name: 'provincia',
    displayName: 'Provincia',
    type: FieldTypes.Standard,
    group: 2,
  },
];

export const DIRECCIONES_GROUPS: EntityFieldGroup[] = [
  {
    label: 'Información general',
    id: 1,
  },
  {
    label: 'Información específica',
    id: 2,
  },
];

export interface Direccion extends AbstractBerria {
  codigo: string;
  nombre: string;
  cliente: any;
  direccion: string;
  telefono: string;
  movil: string;
  pais: string;
  poblacion: string;
  provincia: string;
}
