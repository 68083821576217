import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {DropdownElement} from 'ekiba-dropdown';
import {Language, Translation} from 'ekiba-translatable-field';
import {BehaviorSubject, forkJoin, Observable} from 'rxjs';
import {filter, map, tap} from 'rxjs/operators';
import {Domains, FieldTypes, Labels, Rutas,} from 'src/app/constants/constants';
import {EntityField, EntityFieldGroup,} from 'src/app/pages/add-edit-entity/add-edit-entity.component';
import {Action} from 'src/app/popups/activate-producto-modelos-variantes/activate-producto-modelos-variantes.constants';
import {ActivateProductoModelosVariantesOutput, ActivateProductoModelosVariantesPopup,} from 'src/app/popups/activate-producto-modelos-variantes/activate-producto-modelos-variantes.popup';
import {AddEditProductoAtributoPopup} from 'src/app/popups/add-edit-producto-atributo/add-edit-producto-atributo.component';
import {AddEditProductoBielaPopup} from 'src/app/popups/add-edit-producto-biela/add-edit-producto-biela.component';
import {AddEditProductoCategoriaPopup} from 'src/app/popups/add-edit-producto-categoria/add-edit-producto-categoria.component';
import {AddEditProductoManillarPopup} from 'src/app/popups/add-edit-producto-manillar/add-edit-producto-manillar.component';
import {AddEditProductoModeloPopup} from 'src/app/popups/add-edit-producto-modelo/add-edit-producto-modelo.component';
import {AddEditProductoPotenciaPopup} from 'src/app/popups/add-edit-producto-potencia/add-edit-producto-potencia.component';
import {AddEditProductoPrimePopup} from 'src/app/popups/add-edit-producto-prime/add-edit-producto-prime.component';
import {AddEditProductoTecnologiaPopup} from 'src/app/popups/add-edit-producto-tecnologia/add-edit-producto-tecnologia.component';
import {ConfirmDialog} from 'src/app/popups/confirm-dialog/confirm-dialog.component';
import {WarningPopupComponent, WarningPopupInput,} from 'src/app/popups/warning/warning-popup.component';
import {EntityApiCalls} from 'src/app/shared/entity.api-calls';
import {environment} from 'src/environments/environment';

import {Categoria, CATEGORIAS_BASE_URL,} from '../../categorias/categorias.constants';
import {PRODUCTOS_BASE_URL} from '../productos.constants';

// import { PRODUCTOS_BASE_URL } from '../productos.constants';
import {ATRIBUTOS_BASE_URL, MODELOS_BASE_URL, PRIMES_BASE_URL, PRODUCTO_TRADUCCIONES_URL, PRODUCTOS_FIELDS, PRODUCTOS_GROUPS,} from './add-edit-producto.constants';

const INIT_PRODUCTO = {
  campana: {},
  familia: {},
  activoB2b: false,
  activoB2c: false,
  nombre: '',
  cuadro: {},
  bielas: [],
  potencias: [],
  manillares: [],
  prime: {},
};

@Component({
  templateUrl: './add-edit-producto.component.html',
  styleUrls: ['./add-edit-producto.component.scss'],
})
export class AddEditProductoComponent implements OnInit {
  public action!: string;
  public labels = Labels;
  public domains = Domains;
  public fields: EntityField[] = [];
  public groups: EntityFieldGroup[] = [];
  public fillerFields: number[][] = [];
  public fieldTypes: typeof FieldTypes = FieldTypes;
  public entityToEdit: any|undefined;
  public entityForm!: FormGroup;
  public planDeProduccion$: Observable<any[]>|undefined;
  public atributosOptions$: Observable<any[]>|undefined;
  public bielasOptions$: Observable<any[]>|undefined;
  public potenciasOptions$: Observable<any[]>|undefined;
  public manillaresOptions$: Observable<any[]>|undefined;
  public primesOptions$: Observable<any[]>|undefined;
  public cuadroOptions$: Observable<any[]>|undefined;
  public primeOptions: string[] = ['PRIME', 'NO PRIME'];
  public formattedModelos: string[] = [];
  public formattedAtributos$: BehaviorSubject<any[]> =
      new BehaviorSubject<any[]>([]);
  public traducciones$: BehaviorSubject<Translation[]> =
      new BehaviorSubject<Translation[]>([]);
  public idiomas$: BehaviorSubject<Language[]> =
      new BehaviorSubject<Language[]>([]);
  public _formattedAtributos: any[] = [];
  public modelos$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public variantes$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public bielas$: BehaviorSubject<any> = new BehaviorSubject([]);
  public atributos$: BehaviorSubject<any> = new BehaviorSubject([]);
  public potencias$: BehaviorSubject<any> = new BehaviorSubject([]);
  public manillares$: BehaviorSubject<any> = new BehaviorSubject([]);
  public primes$: BehaviorSubject<any> = new BehaviorSubject([]);
  public categorias$: BehaviorSubject<any> = new BehaviorSubject([]);
  public touched$: BehaviorSubject<boolean> =
      new BehaviorSubject<boolean>(false);

  private _id: number|undefined;
  private _baseUrl!: string;
  private _traducciones: Translation[] = [];
  private _cuadroIsValid = true;
  private _wasActivoB2b = false;
  private _wasActivoB2c = false;
  private _categorias: Categoria[] = [];
  constructor(
      private _api: EntityApiCalls, private _dialog: MatDialog,
      private _route: ActivatedRoute, private __router: Router) {}

  public ngOnInit(): void {
    this._baseUrl = `${environment.api}${Domains.Productos}`;
    this.fields = PRODUCTOS_FIELDS;
    this.groups = PRODUCTOS_GROUPS;
    this._route.paramMap.subscribe((params: any) => {
      const split = this.__router.url.split('/');
      if (params.get('id')) {
        this.action = split[split.length - 2];

        this._id = parseInt(params.get('id') as string);
        this._loadData();
        this._loadTraducciones();
      } else {
        this.action = split[split.length - 1];
        this.entityToEdit = INIT_PRODUCTO;
      }
    });
  }

  public saveData() {
    if (this.entityForm.getRawValue().cuadro?.id) {
      // Check if cuadro is valid
      this._api
          .getDataById(
              this.entityForm.getRawValue().cuadro?.id,
              `${environment.api}${Domains.Cuadros}/checkItContainsColor`)
          .subscribe((res) => {
            this._cuadroIsValid = res?.isValid || false;

            if (!this.entityToEdit.activoB2b || !this.entityToEdit.activoB2c ||
                (this.entityForm.valid && this._productIsValid())) {
              const formFieldsMap: any = {};
              this.fields.forEach((field) => {
                formFieldsMap[field.name] =
                    this.entityForm.getRawValue()[field.name];
                // Send only ids for array and object fields
                if (field.type === FieldTypes.Translatable ||
                    field.type === FieldTypes.TranslatableTextarea) {
                  if (!field.editDisabledForDefaultTranslationEntityField) {
                    formFieldsMap[field.name] =
                        this._traducciones
                            .find(
                                (traduccion: Translation) =>
                                    traduccion.campo === field.name &&
                                    traduccion.idiomaId ===
                                        this.idiomas$?.value
                                            ?.find(
                                                (idioma: Language) =>
                                                    idioma.codigo === 'es')
                                            ?.id)
                            ?.valorVarchar ||
                        formFieldsMap[field.name];
                  } else {
                    formFieldsMap[field.name] = this.entityToEdit[field.name];
                  }
                } else if (field.type === FieldTypes.Boolean) {
                  formFieldsMap[field.name] = formFieldsMap[field.name] ? 1 : 0;
                } else if (
                    formFieldsMap[field.name] &&
                    Array.isArray(formFieldsMap[field.name])) {
                  formFieldsMap[`${field.name}Ids`] =
                      formFieldsMap[field.name].map((f: any) => f.id);
                  delete formFieldsMap[field.name];
                } else if (
                    formFieldsMap[field.name] &&
                    typeof formFieldsMap[field.name] === 'object' &&
                    !Array.isArray(formFieldsMap[field.name])) {
                  formFieldsMap[`${field.name}Id`] =
                      formFieldsMap[field.name].id;
                  delete formFieldsMap[field.name];
                }
                // else if (!formFieldsMap[field.name]) {
                //   delete formFieldsMap[field.name];
                // }
              });
              const data = {
                ...this.entityToEdit,
                activoB2b: this.entityToEdit?.activoB2b ? 1 : 0,
                activoB2c: this.entityToEdit?.activoB2c ? 1 : 0,
                ...formFieldsMap,
                productoCategoriasIds:
                    this.categorias$.value
                        ?.filter(
                            (categoria: any) =>
                                (categoria.edited && !categoria.deleted) ||
                                (categoria.deleted && categoria.id))
                        ?.map((categoria: any) => {
                          const categoriaToSend: any = {
                            categoriaId: categoria.categoriaId,
                            orden: categoria.orden,
                            deleted: categoria.deleted || 0,
                          };
                          if (categoria.id) {
                            categoriaToSend.id = categoria.id;
                          }
                          return categoriaToSend;
                        }),
                productoTecnologiasIds:
                    this.entityToEdit.productoTecnologias
                        ?.filter(
                            (tecnologia: any) =>
                                (tecnologia.edited && !tecnologia.deleted) ||
                                (tecnologia.deleted && tecnologia.id))
                        ?.map((tecnologia: any) => {
                          const tecnologiaToSend: any = {
                            tecnologiaId: tecnologia.tecnologiaId,
                            orden: tecnologia.orden,
                            deleted: tecnologia.deleted || 0,
                          };
                          if (tecnologia.id) {
                            tecnologiaToSend.id = tecnologia.id;
                          }
                          return tecnologiaToSend;
                        }),
                productoBielasIds:
                    this.bielas$.value
                        ?.filter(
                            (biela: any) => (biela.edited && !biela.deleted) ||
                                (biela.deleted && biela.id))
                        ?.map((biela: any) => {
                          const bielaToSend: any = {
                            bielaId: biela.bielaId,
                            default: biela.default ? 1 : 0,
                            deleted: biela.deleted || 0,
                          };
                          if (biela.id) {
                            bielaToSend.id = biela.id;
                          }
                          return bielaToSend;
                        }),
                productoManillaresIds:
                    this.manillares$.value
                        ?.filter(
                            (manillar: any) =>
                                (manillar.edited && !manillar.deleted) ||
                                (manillar.deleted && manillar.id))
                        ?.map((manillar: any) => {
                          const manillarToSend: any = {
                            manillarId: manillar.manillarId,
                            default: manillar.default ? 1 : 0,
                            deleted: manillar.deleted || 0,
                          };
                          if (manillar.id) {
                            manillarToSend.id = manillar.id;
                          }
                          return manillarToSend;
                        }),
                productoPotenciasIds:
                    this.potencias$.value
                        ?.filter(
                            (potencia: any) =>
                                (potencia.edited && !potencia.deleted) ||
                                (potencia.deleted && potencia.id))
                        ?.map((potencia: any) => {
                          const potenciaToSend: any = {
                            potenciaId: potencia.potenciaId,
                            default: potencia.default ? 1 : 0,
                            deleted: potencia.deleted || 0,
                          };
                          if (potencia.id) {
                            potenciaToSend.id = potencia.id;
                          }
                          return potenciaToSend;
                        }),
                productoPrimesIds:
                    this.primes$.value
                        ?.filter(
                            (prime: any) => (prime.edited && !prime.deleted) ||
                                (prime.deleted && prime.id))
                        ?.map((prime: any) => {
                          const primeToSend: any = {
                            primeId: prime.primeId,
                            default: prime.default ? 1 : 0,
                            deleted: prime.deleted || 0,
                          };
                          if (prime.id) {
                            primeToSend.id = prime.id;
                          }
                          return primeToSend;
                        }),
                productoModelosIds:
                    this.modelos$.value?.filter((modelo: any) => modelo.edited)
                        ?.map((modelo: any) => {
                          const modeloToSend: any = {
                            modeloId: modelo.modeloId,
                            ruedaId: modelo.rueda.id,
                            orden: modelo.orden,
                          };
                          if (modelo.id) {
                            modeloToSend.id = modelo.id;
                          }
                          return modeloToSend;
                        }),
                productoAtributosIds:
                    this.atributos$.value
                        ?.filter(
                            (atributo: any) =>
                                atributo.edited && !atributo.deleted)
                        ?.map((atributo: any) => {
                          const atributoToSend: any = {
                            deleted: atributo.deleted || 0,
                            valorId: atributo.valor?.id,
                            atributoId: atributo.atributoId,
                          };
                          if (atributo.id) {
                            atributoToSend.id = atributo.id;
                          }
                          return atributoToSend;
                        }),
              };
              delete data.variantes;
              delete data.bielas;
              delete data.productoBielas;
              delete data.manillares;
              delete data.productoManillares;
              delete data.potencias;
              delete data.productoPotencias;
              delete data.productoCategorias;
              delete data.productoAtributos;
              delete data.productoModelos;
              delete data.productoTiendas;
              delete data.productoVariantes;
              delete data.pvp;
              delete data.resumen;
              delete data.sku;
              delete data.urlkey;
              delete data.webId;
              delete data.familia;
              delete data.familiaId;
              delete data.descripcion;
              delete data.cuadro;
              delete data.modelos;
              delete data.imagen;
              delete data.nombre;
              delete data.cuadro;
              delete data.grupoCambio;
              delete data.horquilla;
              delete data.motor;
              delete data.bateria;
              delete data.pantalla;
              delete data.cargador;
              delete data.potencia;
              delete data.tijaSillin;
              delete data.sillin;
              delete data.rearShox;
              delete data.patilla;
              delete data.categoria;
              delete data.categorias;
              delete data.productoPrimes;
              delete data.primes;
              delete data.atributos;
              delete data.biela;
              delete data.cadena;
              delete data.cambio;
              delete data.frenos;
              delete data.manetas;
              delete data.manillar;
              delete data.material;
              delete data.pinones;
              delete data.manillarId;
              if (this.entityToEdit['imagen-image'] === null &&
                  this.entityToEdit['imagen-file'] === null) {
                data.imagen = null;
              }
              this._traducciones =
                  this._traducciones.map((traduccion: Translation) => ({
                                           ...traduccion,
                                           registroId: this._id,
                                         }));
              this._api
                  .addData(
                      {traducciones: this._traducciones},
                      `${environment.api}${Domains.PostTraduccionesMultiple}`)
                  .subscribe();
              const apiCalls$: Observable<any>[] = [];
              let waitForModalClose = false;
              this._api.updateData(data, PRODUCTOS_BASE_URL)
                  .subscribe((res: any) => {
                    if (res.success) {
                      if (this.entityToEdit['imagen-file']) {
                        const file = this.entityToEdit['imagen-file'];
                        apiCalls$.push(this._api.uploadImage(
                            file,
                            `${environment.api}${Domains.UploadProductoImagen}`,
                            this.entityToEdit?.id));
                      }

                      if ((this.entityToEdit.activoB2b &&
                           !this._wasActivoB2b) ||
                          (this.entityToEdit.activoB2c &&
                           !this._wasActivoB2c)) {
                        waitForModalClose = true;
                        const dialog = this._dialog.open(
                            ActivateProductoModelosVariantesPopup, {
                              data: {
                                productoId: this.entityToEdit.id,
                                message:
                                    '¿Desea activar los modelos / variantes asociados a este producto?',
                              },
                            });

                        dialog.afterClosed().subscribe(
                            (res: ActivateProductoModelosVariantesOutput) => {
                              if (res?.event === Action.confirm) {
                                apiCalls$.push(this._api.addData(
                                    {
                                      productId: this.entityToEdit.id,
                                      activateModels: res?.activarModelosB2b,
                                      activateVariants:
                                          res?.activarVariantesB2b,
                                    },
                                    `${environment.api}${
                                        Domains
                                            .Productos}/triggerRelationsB2b`));
                                apiCalls$.push(this._api.addData(
                                    {
                                      productId: this.entityToEdit.id,
                                      activateModels: res?.activarModelosB2c,
                                      activateVariants:
                                          res?.activarVariantesB2c,
                                    },
                                    `${environment.api}${
                                        Domains
                                            .Productos}/triggerRelationsB2c`));
                              }
                              if (apiCalls$.length) {
                                forkJoin(apiCalls$)
                                    .pipe(tap(() => {
                                      this.__router.navigate([
                                        Rutas.ConfiguracionDeProductos,
                                        Rutas.Productos,
                                        Rutas.List,
                                      ]);
                                    }))
                                    .subscribe();
                              } else {
                                this.__router.navigate([
                                  Rutas.ConfiguracionDeProductos,
                                  Rutas.Productos,
                                  Rutas.List,
                                ]);
                              }
                            });
                      }
                    }
                    if (!waitForModalClose) {
                      if (apiCalls$.length) {
                        forkJoin(apiCalls$)
                            .pipe(tap(() => {
                              this.__router.navigate([
                                Rutas.ConfiguracionDeProductos,
                                Rutas.Productos,
                                Rutas.List,
                              ]);
                            }))
                            .subscribe();
                      } else {
                        this.__router.navigate([
                          Rutas.ConfiguracionDeProductos,
                          Rutas.Productos,
                          Rutas.List,
                        ]);
                      }
                    }
                  });
            } else {
              this.entityForm.markAllAsTouched();
              this.touched$.next(true);
              this._handleInvalidSubmit();
            }
          });
    } else {
      this.entityForm.markAllAsTouched();
      this.touched$.next(true);
      this._handleInvalidSubmit();
    }
  }

  public addAtributo(): void {
    const dialog = this._dialog.open(AddEditProductoAtributoPopup, {
      data: {action: 'add'},
      width: '400px',
      height: '400px',
    });
    dialog.afterClosed().subscribe((res) => {
      if (res) {
        this.atributos$.next([
          ...this.atributos$.value,
          {
            ...res.atributo,
            valor: res.valor,
            edited: 1,
          },
        ]);
      }
    });
  }

  public editAtributo(atributo: any) {
    const dialog = this._dialog.open(AddEditProductoAtributoPopup, {
      data: {action: 'add', atributo},
      width: '400px',
      height: '400px',
    });
    dialog.afterClosed().subscribe((res) => {
      if (res) {
        let newAtributo =
            this.atributos$.value.find((a: any) => a.id === atributo.id);
        if (!newAtributo) {
          newAtributo = this.atributos$.value.find(
              (a: any) => a.atributoId === atributo.atributoId);
        }
        for (const k in res.atributo) {
          newAtributo[k] = res.atributo[k];
        }
        newAtributo.valor = res.valor;
        newAtributo.edited = 1;
        this.atributos$.next([...this.atributos$.value]);
      }
    });
  }

  public deleteAtributo(atributo: any) {
    const dialog = this._dialog.open(ConfirmDialog, {
      data: {
        texto: `¿Está seguro de que desea eliminar el atributo ${
            atributo.nombre} del producto?`,
      },
    });
    dialog.afterClosed().subscribe((res) => {
      if (res) {
        let index = this.atributos$.value.findIndex(
            (a: any) => a.id === atributo.id && !a.deleted);
        if (!index) {
          index = this.atributos$.value.findIndex(
              (a: any) => a.atributoId === atributo.atributoId && !a.deleted);
        }
        const newAtributos = [...this.atributos$.value];
        newAtributos[index].deleted = 1;
        this.atributos$.next([...newAtributos]);
      }
    });
  }

  public fileChange(event: any) {
    const fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      const file: File = fileList[0];
      this.entityToEdit['imagen-file'] = file;
      const reader = new FileReader();
      reader.onload = (() => {
        return (e: any) => {
          this.entityToEdit['imagen-image'] = e.target.result;
        };
      })();
      reader.readAsDataURL(file);
    }
  }

  public addTecnologia(): void {
    const dialog = this._dialog.open(AddEditProductoTecnologiaPopup, {
      data: {action: 'add'},
      width: '400px',
      height: '400px',
    });
    dialog.afterClosed().subscribe((res) => {
      if (res) {
        if (res.tecnologia?.default) {
          this.entityToEdit.productoTecnologias?.forEach(
              (tecnologia: any) => (tecnologia.default = 0));
          res.tecnologia.default = 1;
        }
        this.entityToEdit.productoTecnologias = [
          ...this.entityToEdit.productoTecnologias,
          {
            ...res.tecnologia,
            edited: 1,
          },
        ];
      }
    });
  }

  public editTecnologia(tecnologia: any) {
    const dialog = this._dialog.open(AddEditProductoTecnologiaPopup, {
      data: {action: 'add', tecnologia},
      width: '400px',
      height: '400px',
    });
    dialog.afterClosed().subscribe((res) => {
      if (res) {
        let newTecnologia = this.entityToEdit.productoTecnologias?.find(
            (b: any) => b.id && b.id === tecnologia.id && !b.deleted);
        if (!newTecnologia) {
          newTecnologia = this.entityToEdit.productoTecnologias?.find(
              (b: any) => b.tecnologiaId === b.tecnologiaId && !b.deleted);
          newTecnologia = this.entityToEdit.productoTecnologias[newTecnologia];
        }
        for (const k in res.tecnologia) {
          newTecnologia[k] = res.tecnologia[k];
        }
        newTecnologia.edited = 1;
        if (newTecnologia.default) {
          this.entityToEdit.productoTecnologias?.forEach(
              (tecnologia: any) => (tecnologia.default = 0));
          newTecnologia.default = 1;
        }
        this.entityToEdit.productoTecnologias = [
          ...this.entityToEdit.productoTecnologias,
        ];
      }
    });
  }

  public deleteTecnologia(tecnologia: any) {
    const dialog = this._dialog.open(ConfirmDialog, {
      data: {
        texto: `¿Está seguro de que desea eliminar la tecnologia ${
            tecnologia.nombre} del producto?`,
      },
    });
    dialog.afterClosed().subscribe((res) => {
      if (res) {
        let index = this.entityToEdit.productoTecnologias?.findIndex(
            (b: any) => b.id === tecnologia.id && !b.deleted);
        if (!index) {
          index = this.entityToEdit.productoTecnologias?.findIndex(
              (a: any) =>
                  a.tecnologiaId === tecnologia.tecnologiaId && !a.deleted);
        }
        const newTecnologias = [...this.entityToEdit.productoTecnologias];
        newTecnologias[index].deleted = 1;
        this.entityToEdit.productoTecnologias = [...newTecnologias];
      }
    });
  }

  public addCategoria(): void {
    const dialog = this._dialog.open(AddEditProductoCategoriaPopup, {
      data: {action: 'add'},
      width: '400px',
      height: '400px',
    });
    dialog.afterClosed().subscribe((res) => {
      if (res) {
        if (res.categoria?.default) {
          this.categorias$.value?.forEach(
              (categoria: any) => (categoria.default = 0));
          res.categoria.default = 1;
        }
        this.categorias$.next([
          ...this.categorias$.value,
          {
            ...res.categoria,
            nombreCompleto:
                this._getNombreCompletoCategoria(res.categoria.categoriaId),
            edited: 1,
          },
        ]);
      }
    });
  }

  public editCategoria(categoria: any) {
    const dialog = this._dialog.open(AddEditProductoCategoriaPopup, {
      data: {action: 'add', categoria},
      width: '400px',
      height: '400px',
    });
    dialog.afterClosed().subscribe((res) => {
      if (res) {
        let newCategoria = this.categorias$.value.find(
            (b: any) => b.id && b.id === categoria.id && !b.deleted);
        if (!newCategoria) {
          newCategoria = this.categorias$.value.find(
              (b: any) => b.categoriaId === b.categoriaId && !b.deleted);
        }
        for (const k in res.categoria) {
          newCategoria[k] = res.categoria[k];
        }
        newCategoria.edited = 1;
        if (newCategoria.default) {
          this.categorias$.value?.forEach(
              (categoria: any) => (categoria.default = 0));
          newCategoria.default = 1;
        }
        (newCategoria.nombreCompleto =
             this._getNombreCompletoCategoria(newCategoria.categoriaId)),
            this.categorias$.next([...this.categorias$.value]);
      }
    });
  }

  public deleteCategoria(categoria: any) {
    const dialog = this._dialog.open(ConfirmDialog, {
      data: {
        texto: `¿Está seguro de que desea eliminar la categoría ${
            categoria.nombre} del producto?`,
      },
    });
    dialog.afterClosed().subscribe((res) => {
      if (res) {
        let index = this.categorias$.value.findIndex(
            (b: any) => b.id === categoria.id && !b.deleted);
        if (!index) {
          index = this.categorias$.value.findIndex(
              (a: any) =>
                  a.categoriaId === categoria.categoriaId && !a.deleted);
        }
        const newCategorias = [...this.categorias$.value];
        newCategorias[index].deleted = 1;
        this.categorias$.next([...newCategorias]);
      }
    });
  }

  public addBiela(): void {
    const dialog = this._dialog.open(AddEditProductoBielaPopup, {
      data: {action: 'add'},
      width: '400px',
      height: '400px',
    });
    dialog.afterClosed().subscribe((res) => {
      if (res) {
        if (res.biela?.default) {
          this.bielas$.value?.forEach((biela: any) => (biela.default = 0));
          res.biela.default = 1;
        }
        this.bielas$.next([
          ...this.bielas$.value,
          {
            ...res.biela,
            edited: 1,
          },
        ]);
      }
    });
  }

  public editBiela(biela: any) {
    const dialog = this._dialog.open(AddEditProductoBielaPopup, {
      data: {action: 'add', biela},
      width: '400px',
      height: '400px',
    });
    dialog.afterClosed().subscribe((res) => {
      if (res) {
        let newBiela = this.bielas$.value.find(
            (b: any) => b.id && b.id === biela.id && !b.deleted);
        if (!newBiela) {
          newBiela = this.bielas$.value.find(
              (b: any) => b.bielaId === biela.bielaId && !b.deleted);
        }
        for (const k in res.biela) {
          newBiela[k] = res.biela[k];
        }
        newBiela.edited = 1;
        if (newBiela.default) {
          this.bielas$.value?.forEach((biela: any) => (biela.default = 0));
          newBiela.default = 1;
        }
        this.bielas$.next([...this.bielas$.value]);
      }
    });
  }

  public deleteBiela(biela: any) {
    const dialog = this._dialog.open(ConfirmDialog, {
      data: {
        texto: `¿Está seguro de que desea eliminar la biela ${
            biela.nombre} del producto?`,
      },
    });
    dialog.afterClosed().subscribe((res) => {
      if (res) {
        let index = this.bielas$.value.findIndex(
            (b: any) => b.id === biela.id && !b.deleted);
        if (!index) {
          index = this.bielas$.value.findIndex(
              (a: any) => a.bielaId === biela.bielaId && !a.deleted);
        }
        const newBielas = [...this.bielas$.value];
        newBielas[index].deleted = 1;
        this.bielas$.next([...newBielas]);
      }
    });
  }

  public addPotencia(): void {
    const dialog = this._dialog.open(AddEditProductoPotenciaPopup, {
      data: {action: 'add'},
      width: '400px',
      height: '400px',
    });
    dialog.afterClosed().subscribe((res) => {
      if (res) {
        if (res.potencia?.default) {
          this.potencias$.value?.forEach(
              (potencia: any) => (potencia.default = 0));
          res.potencia.default = 1;
        }
        this.potencias$.next([
          ...this.potencias$.value,
          {
            ...res.potencia,
            edited: 1,
          },
        ]);
      }
    });
  }

  public editPotencia(potencia: any) {
    const dialog = this._dialog.open(AddEditProductoPotenciaPopup, {
      data: {action: 'add', potencia},
      width: '400px',
      height: '400px',
    });
    dialog.afterClosed().subscribe((res) => {
      if (res) {
        let newPotencia = this.potencias$.value.find(
            (p: any) => p.id && p.id === potencia.id && !p.deleted);
        if (!newPotencia) {
          newPotencia = this.potencias$.value.find(
              (p: any) => p.potenciaId === p.potenciaId && !p.deleted);
        }
        for (const k in res.potencia) {
          newPotencia[k] = res.potencia[k];
        }
        newPotencia.edited = 1;
        if (newPotencia.default) {
          this.potencias$.value?.forEach(
              (potencia: any) => (potencia.default = 0));
          newPotencia.default = 1;
        }
        this.potencias$.next([...this.potencias$.value]);
      }
    });
  }

  public deletePotencia(potencia: any) {
    const dialog = this._dialog.open(ConfirmDialog, {
      data: {
        texto: `¿Está seguro de que desea eliminar la potencia ${
            potencia.nombre} del producto?`,
      },
    });
    dialog.afterClosed().subscribe((res) => {
      if (res) {
        let index = this.potencias$.value.findIndex(
            (p: any) => p.id === potencia.id && !p.deleted);
        if (!index) {
          index = this.potencias$.value.findIndex(
              (a: any) => a.potenciaId === potencia.potenciaId && !a.deleted);
        }
        const newPotencias = [...this.potencias$.value];
        newPotencias[index].deleted = 1;
        this.potencias$.next([...newPotencias]);
      }
    });
  }

  public addManillar(): void {
    const dialog = this._dialog.open(AddEditProductoManillarPopup, {
      data: {action: 'add'},
      width: '400px',
      height: '400px',
    });
    dialog.afterClosed().subscribe((res) => {
      if (res) {
        if (res.manillar?.default) {
          this.manillares$.value?.forEach(
              (manillar: any) => (manillar.default = 0));
          res.manillar.default = 1;
        }
        this.manillares$.next([
          ...this.manillares$.value,
          {
            ...res.manillar,
            edited: 1,
          },
        ]);
      }
    });
  }

  public editManillar(manillar: any) {
    const dialog = this._dialog.open(AddEditProductoManillarPopup, {
      data: {action: 'add', manillar},
      width: '400px',
      height: '400px',
    });
    dialog.afterClosed().subscribe((res) => {
      if (res) {
        let newManillar = this.manillares$.value.find(
            (m: any) => m.id && m.id === manillar.id && !m.deleted);
        if (!newManillar) {
          newManillar = this.manillares$.value.find(
              (m: any) => m.manillarId === m.manillarId && !m.deleted);
        }
        for (const k in res.manillar) {
          newManillar[k] = res.manillar[k];
        }
        newManillar.edited = 1;
        if (newManillar.default) {
          this.manillares$.value?.forEach(
              (manillar: any) => (manillar.default = 0));
          newManillar.default = 1;
        }
        this.manillares$.next([...this.manillares$.value]);
      }
    });
  }

  public deleteManillar(manillar: any) {
    const dialog = this._dialog.open(ConfirmDialog, {
      data: {
        texto: `¿Está seguro de que desea eliminar el manillar ${
            manillar.nombre} del producto?`,
      },
    });
    dialog.afterClosed().subscribe((res) => {
      if (res) {
        let index = this.manillares$.value.findIndex(
            (m: any) => m.id === manillar.id && !m.deleted);
        if (!index) {
          index = this.manillares$.value.findIndex(
              (a: any) => a.manillarId === manillar.manillarId && !a.deleted);
        }
        const newManillares = [...this.manillares$.value];
        newManillares[index].deleted = 1;
        this.manillares$.next([...newManillares]);
      }
    });
  }

  public addPrime(): void {
    const dialog = this._dialog.open(AddEditProductoPrimePopup, {
      data: {action: 'add'},
      width: '400px',
      height: '400px',
    });
    dialog.afterClosed().subscribe((res) => {
      if (res) {
        if (res.prime?.default) {
          this.primes$.value?.forEach((prime: any) => (prime.default = 0));
          res.prime.default = 1;
        }
        this.primes$.next([
          ...this.primes$.value,
          {
            ...res.prime,
            edited: 1,
          },
        ]);
      }
    });
  }

  public editPrime(prime: any) {
    const dialog = this._dialog.open(AddEditProductoPrimePopup, {
      data: {action: 'add', prime},
      width: '400px',
      height: '400px',
    });
    dialog.afterClosed().subscribe((res) => {
      if (res) {
        let newPrime = this.primes$.value.find(
            (p: any) => p.id && p.id === prime.id && !p.deleted);
        if (!newPrime) {
          newPrime = this.primes$.value.find(
              (p: any) => p.primeId === p.primeId && !p.deleted);
        }
        for (const k in res.prime) {
          newPrime[k] = res.prime[k];
        }
        newPrime.edited = 1;
        if (newPrime.default) {
          this.primes$.value?.forEach((prime: any) => (prime.default = 0));
          newPrime.default = 1;
        }
        this.primes$.next([...this.primes$.value]);
      }
    });
  }

  public deletePrime(prime: any) {
    const dialog = this._dialog.open(ConfirmDialog, {
      data: {
        texto: `¿Está seguro de que desea eliminar el prime ${
            prime.nombre} del producto?`,
      },
    });
    dialog.afterClosed().subscribe((res) => {
      if (res) {
        let index = this.primes$.value.findIndex(
            (p: any) => p.id === prime.id && !p.deleted);
        if (!index) {
          index = this.primes$.value.findIndex(
              (a: any) => a.primeId === prime.primeId && !a.deleted);
        }
        const newPrimes = [...this.primes$.value];
        newPrimes[index].deleted = 1;
        this.primes$.next([...newPrimes]);
      }
    });
  }

  public editModelo(modelo: any) {
    const dialog = this._dialog.open(AddEditProductoModeloPopup, {
      data: {action: 'add', modelo},
      width: '500px',
      height: '500px',
    });
    dialog.afterClosed().subscribe((res) => {
      if (res) {
        const newModelo =
            this.modelos$.value.find((m: any) => m.id && m.id === modelo.id);
        for (const k in res.modelo) {
          newModelo[k] = res.modelo[k];
        }
        newModelo.edited = 1;
        this.modelos$.next([...this.modelos$.value]);
      }
    });
  }

  public deleteModelo(modelo: any) {
    const dialog = this._dialog.open(ConfirmDialog, {
      data: {
        texto: `¿Está seguro de que desea eliminar el modelo ${
            modelo.nombre} del producto?`,
      },
    });
    dialog.afterClosed().subscribe((res) => {
      if (res) {
        const index =
            this.modelos$.value.findIndex((m: any) => m.id === modelo.id);
        const newModelos = [...this.modelos$.value];
        newModelos[index].deleted = 1;
        this.modelos$.next([...newModelos]);
      }
    });
  }

  public editVariante(variante: any) {
    const dialog = this._dialog.open(ConfirmDialog, {
      data: {
        texto:
            `¿Está seguro de que desea salir de la página del producto? Se perderán todos los datos no guardados.`,
      },
    });
    dialog.afterClosed().subscribe((res) => {
      if (res) {
        this.__router.navigate([
          `${Rutas.ConfiguracionDeProductos}/${Rutas.Variantes}/${Rutas.Edit}/${
              variante.id}`,
        ]);
      }
    });
  }

  public updateField(
      dropdownElement: DropdownElement|DropdownElement[],
      field: EntityField): void {
    this.entityForm.controls[field.name].setValue(dropdownElement);
  }

  public getFieldsForGroup({id}: EntityFieldGroup) {
    return this.fields.filter((field) => field.group === id);
  }

  private _handleInvalidSubmit() {
    const data: WarningPopupInput = {
      headerMessage: 'Error: producto inválido',
    };
    const errors = [];
    if (this.potenciasHasError()) {
      errors.push('Debe haber al menos una potencia permitida.');
    }
    if (this.bielasHasError()) {
      errors.push('Debe haber al menos una biela permitida.');
    }
    if (this.manillaresHasError()) {
      errors.push('Debe haber al menos un maniillar permitido.');
    }
    if (this.modelosHasError()) {
      errors.push('Todos los modelos han de tener una rueda asignada.');
    }
    if (!this.entityForm.getRawValue().cuadro) {
      errors.push('Cuadro es obligatorio.');
    } else if (this.cuadroHasError()) {
      data.cuadroId = this.entityForm.getRawValue().cuadro.id;
      errors.push(
          'Todos los cuadros han de tener al menos un color asignado a todos sus servicios de pintura.');
    }
    if (!this.entityForm.getRawValue().grupoCambio) {
      errors.push('Grupo de cambio es obligatorio.');
    }

    data.conflictLines = errors;
    this._dialog.open(WarningPopupComponent, {
      data,
    });
  }

  public cuadroHasError() {
    return !(this.entityToEdit?.cuadro && this._cuadroIsValid);
  }

  public modelosHasError() {
    return !this.modelos$?.value?.every((modelo: any) => !!modelo.rueda);
  }

  public manillaresHasError() {
    return !(
        this.manillares$?.value?.filter((item: any) => !item.deleted).length >
        0);
  }

  public bielasHasError() {
    return !(
        this.bielas$?.value?.filter((item: any) => !item.deleted).length > 0);
  }

  public potenciasHasError() {
    return !(
        this.potencias$?.value?.filter((item: any) => !item.deleted).length >
        0);
  }

  private _productIsValid(): boolean {
    return (
        !this.cuadroHasError() && !this.potenciasHasError() &&
        !this.bielasHasError() && !this.manillaresHasError() &&
        !this.modelosHasError());
  }

  private _buildFieldsArray(): void {
    this.fields.forEach((field) => {
      if (field.optionsUrl) {
        this._api.getData(field.optionsUrl)
            .pipe(
                map(field.filterOptions || ((item) => item)),
                tap((res: any) => {
                  const productos: any[] = [];
                  if (field.name === 'productoRelacionado') {
                    res.forEach((re: any) => {
                      productos.push({
                        nombre: re.sku + ' - ' + re.nombre,
                        id: re.id,
                      });
                    });
                    field.options = productos;
                  } else {
                    field.options = res;
                  }
                }))
            .subscribe();
      }
    });

    for (let j = 0; j < this.groups.length; j++) {
      this.fillerFields[j] = [];
      const group = this.groups[j];
      const numberOfFieldsInGroup =
          this.fields.filter((field) => field.group === group.id).length;
      for (let i = 4; i > numberOfFieldsInGroup % 4; i--) {
        this.fillerFields[j].push(i);
      }
    }
  }

  private _buildForm(): void {
    const formFields: any[] = [];
    this.fields.forEach((field) => {
      if (field.type === FieldTypes.Image) {
        formFields.push({
          name: field.name + '-file',
          control: new FormControl(
              {
                value: '',
                disabled: field.disabled ||
                    (this.action === 'edit' && field.editDisabled) || false,
              },
              field.required ? [Validators.required] : []),
        });
        formFields.push({
          name: field.name + '-image',
          control: new FormControl(
              {
                value: this.entityToEdit && this.entityToEdit[field.name],
                disabled: field.disabled ||
                    (this.action === 'edit' && field.editDisabled) || false,
              },
              field.required ? [Validators.required] : []),
        });
        formFields.push({
          name: field.name,
          control: new FormControl(
              {
                value: '',
                disabled: field.disabled ||
                    (this.action === 'edit' && field.editDisabled) || false,
              },
              field.required ? [Validators.required] : []),
        });
      } else {
        formFields.push({
          name: field.name,
          control: new FormControl(
              {
                value: this.entityToEdit && this.entityToEdit[field.name],
                disabled: field.disabled ||
                    (this.action === 'edit' && field.editDisabled) || false,
              },
              field.required ? [Validators.required] : []),
        });
      }
    });
    this.entityForm = new FormGroup({});
    formFields.forEach((formField) => {
      this.entityForm.addControl(formField.name, formField.control);
    });
    this.entityForm.get('cuadro')?.valueChanges.subscribe((value) => {
      this._api
          .getDataById(
              value?.id,
              `${environment.api}${Domains.Cuadros}/checkItContainsColor`)
          .subscribe((res) => {
            this._cuadroIsValid = res?.isValid || false;
          });
    });
  }

  private _loadTraducciones() {
    this.traducciones$
        ?.pipe(tap((traducciones: Translation[]) => {
          this._traducciones = traducciones;
          const traduccionesSpanish = traducciones.filter(
              (traduccion: Translation) => traduccion.idiomaId ===
                  this.idiomas$?.value
                      ?.find((idioma: Language) => idioma.codigo === 'es')
                      ?.id);
          traduccionesSpanish.forEach((traduccion: Translation) => {
            this.entityForm.patchValue({
              [traduccion.campo]: traduccion.valorVarchar,
            });
          });
        }))
        .subscribe();
    this._api.getData(`${PRODUCTO_TRADUCCIONES_URL}&registerId=${this._id}`)
        .subscribe((res) => this.traducciones$.next(res));
    this._api.getData(`${environment.api}${Domains.IdiomasShared}`)
        .subscribe((res) => this.idiomas$.next(res));
  }

  public getNonDeletedCategorias() {
    return this.categorias$.value?.filter(
        (categoria: any) => !categoria.deleted);
  }

  public getNonDeletedTecnologias() {
    return this.entityToEdit.productoTecnologias?.filter(
        (tecnologia: any) => !tecnologia.deleted);
  }

  public getNonDeletedBielas() {
    return this.bielas$.value?.filter((biela: any) => !biela.deleted);
  }

  public getNonDeletedPotencias() {
    return this.potencias$.value?.filter((potencia: any) => !potencia.deleted);
  }

  public getNonDeletedManillares() {
    return this.manillares$.value?.filter((manillar: any) => !manillar.deleted);
  }

  public getNonDeletedPrimes() {
    return this.primes$.value?.filter((prime: any) => !prime.deleted);
  }

  public getNonDeletedAtributos() {
    return this.atributos$.value?.filter((atributo: any) => !atributo.deleted);
  }

  public deleteImage() {
    const dialogRef = this._dialog.open(ConfirmDialog, {
      data: {
        texto: '¿Está seguro de que desea eliminar la imagen?',
      },
    });
    dialogRef.afterClosed()
        .pipe(filter((res: boolean) => !!res), tap(() => {
                this.entityToEdit['imagen-image'] = null;
                this.entityToEdit['imagen-file'] = null;
              }))
        .subscribe();
  }

  private _loadCategorias() {
    this._api.getData(CATEGORIAS_BASE_URL).subscribe((cat: Categoria[]) => {
      this._categorias = cat;

      this.entityToEdit.categorias = this.entityToEdit.productoCategorias;
      this.entityToEdit.categorias?.forEach((categoria: any) => {
        this.categorias$.next([
          ...this.categorias$.value,
          {
            ...categoria.categoria,
            ...categoria,
            nombreCompleto:
                this._getNombreCompletoCategoria(categoria.categoriaId),
          },
        ]);
      });
    });
  }

  private _loadBielas() {
    this.entityToEdit.bielas = this.entityToEdit.productoBielas;
    this.entityToEdit.bielas?.forEach((biela: any) => {
      this.bielas$.next([...this.bielas$.value, {...biela.biela, ...biela}]);
    });
    this.bielasOptions$ = this._api.getData(
        `${environment.api}${Domains.Dropdown}?table=${Domains.Bielas}`);
  }

  private _loadPotencias() {
    this.entityToEdit.potencias = this.entityToEdit.productoPotencias;
    this.entityToEdit.potencias?.forEach((potencia: any) => {
      this.potencias$.next([
        ...this.potencias$.value,
        {...potencia.potencia, ...potencia},
      ]);
    });
    this.potenciasOptions$ = this._api.getData(
        `${environment.api}${Domains.Dropdown}?table=${Domains.Potencias}`);
  }

  private _loadManillares() {
    this.entityToEdit.manillares = this.entityToEdit.productoManillares;
    this.entityToEdit.manillares?.forEach((manillar: any) => {
      this.manillares$.next([
        ...this.manillares$.value,
        {...manillar.manillar, ...manillar},
      ]);
    });
    this.manillaresOptions$ = this._api.getData(
        `${environment.api}${Domains.Dropdown}?table=${Domains.Manillares}`);
  }

  private _loadPrimes() {
    this.entityToEdit.primes = this.entityToEdit.productoPrimes;
    this.entityToEdit.primes?.forEach((prime: any) => {
      this._api.getDataById(prime.primeId!, PRIMES_BASE_URL)
          .subscribe((p: any) => {
            this.primes$.next([...this.primes$.value, {...p, ...prime}]);
          });
    });
    this.primesOptions$ = this._api.getData(
        `${environment.api}${Domains.Dropdown}?table=${Domains.PRIMES}`);
  }

  private _loadModelos() {
    this.entityToEdit.modelos =
        this.entityToEdit.productoModelos?.map((modelo: any) => {
          return {id: modelo.id};
        });
    this.entityToEdit.modelos?.forEach((modelo: any) => {
      this._api.getDataById(modelo.id!, MODELOS_BASE_URL)
          .subscribe((m: any) => {
            this.modelos$.next([...this.modelos$.value, m]);
          });
    });
  }

  private _loadAtributos() {
    this.entityToEdit.atributos = this.entityToEdit.productoAtributos;
    this.entityToEdit.atributos?.forEach((atributo: any) => {
      this._api.getDataById(atributo.atributoId!, ATRIBUTOS_BASE_URL)
          .subscribe((a: any) => {
            this.atributos$.next([
              ...this.atributos$.value,
              {...a, ...atributo},
            ]);
          });
    });
    this.atributosOptions$ = this._api.getData(
        `${environment.api}${Domains.Dropdown}?table=${Domains.Atributos}`);
  }

  private _loadDisponibilidadYPlanDeProduccion() {
    const ruedas$ = this._api.getData(
        `${environment.api}${Domains.Dropdown}?table=${Domains.Ruedas}`);
    const tallas$ = this._api.getData(
        `${environment.api}${Domains.Dropdown}?table=${Domains.Tallas}`);

    const tallasYRuedas$ =
        forkJoin([ruedas$, tallas$]).pipe(map(([ruedas, tallas]) => {
          this.entityToEdit.productoDisponibilidades.forEach(
              (disponibilidad: any) => {
                if (disponibilidad.modelo) {
                  disponibilidad.modelo.rueda =
                      ruedas
                          .find(
                              (rueda: any) =>
                                  rueda.id === disponibilidad.modelo?.ruedaId)
                          ?.nombre;
                }
                if (disponibilidad.variante) {
                  disponibilidad.variante.talla =
                      tallas
                          .find(
                              (talla: any) =>
                                  talla.id === disponibilidad.variante?.tallaId)
                          ?.nombre;
                }
                if (disponibilidad.colorFondo) {
                  disponibilidad.colorFondo.tipo = 'Fondo';
                }
                if (disponibilidad.colorCalca1) {
                  disponibilidad.colorCalca1.tipo = 'Calca 1';
                }
                if (disponibilidad.colorCalca2) {
                  disponibilidad.colorCalca2.tipo = 'Calca 2';
                }
                if (disponibilidad.colorColor) {
                  disponibilidad.colorColor.tipo = 'Color';
                }
                if (disponibilidad.colorAcabado) {
                  disponibilidad.colorAcabado.tipo = 'Acabado';
                }
              });

          this.entityToEdit.productoPlanDeProduccion.forEach(
              (planDeProduccion: any) => {
                if (planDeProduccion.modelo) {
                  planDeProduccion.modelo.rueda =
                      ruedas
                          .find(
                              (rueda: any) =>
                                  rueda.id === planDeProduccion.modelo?.ruedaId)
                          ?.nombre;
                }
                if (planDeProduccion.variante) {
                  planDeProduccion.variante.talla =
                      tallas
                          .find(
                              (talla: any) => talla.id ===
                                  planDeProduccion.variante?.tallaId)
                          ?.nombre;
                }
                if (planDeProduccion.colorFondo) {
                  planDeProduccion.colorFondo.tipo = 'Fondo';
                }
                if (planDeProduccion.colorCalca1) {
                  planDeProduccion.colorCalca1.tipo = 'Calca 1';
                }
                if (planDeProduccion.colorCalca2) {
                  planDeProduccion.colorCalca2.tipo = 'Calca 2';
                }
                if (planDeProduccion.colorColor) {
                  planDeProduccion.colorColor.tipo = 'Color';
                }
                if (planDeProduccion.colorAcabado) {
                  planDeProduccion.colorAcabado.tipo = 'Acabado';
                }
              });
        }));
    tallasYRuedas$.subscribe();
  }

  private _loadVariantes() {
    this.entityToEdit.variantes = this.entityToEdit.tVariantes;
    this.entityToEdit.variantes?.forEach((variante: any) => {
      this.variantes$.next([...this.variantes$.value, {...variante}]);
    });
  }

  private _loadData() {
    this._api.getDataById(this._id!, this._baseUrl).subscribe((entity: any) => {
      this.entityToEdit = entity;
      this._wasActivoB2b = !!this.entityToEdit.activoB2b;
      this._wasActivoB2c = !!this.entityToEdit.activoB2c;
      this.entityToEdit['imagen-image'] = this.entityToEdit.imagen;
      this._buildFieldsArray();
      this._buildForm();
      this._loadCategorias();
      this._loadBielas();
      this._loadPotencias();
      this._loadManillares();
      this._loadPrimes();
      this.entityToEdit.cuadro = {id: this.entityToEdit.cuadroId};
      if (this.entityToEdit.cuadroId) {
        this._api
            .getDataById(
                this.entityToEdit.cuadroId,
                `${environment.api}${Domains.Cuadros}/checkItContainsColor`)
            .subscribe((res) => {
              this._cuadroIsValid = res?.isValid || false;
            });
      }
      if (this.entityToEdit.productoTecnologias) {
        this.entityToEdit.productoTecnologias =
            this.entityToEdit.productoTecnologias.map((tecnologia: any) => {
              const tec = {
                ...tecnologia.tecnologia,
                ...tecnologia,
              };
              delete tec.tecnologia;
              return tec;
            });
      }
      this.cuadroOptions$ = this._api.getData(
          `${environment.api}${Domains.Dropdown}?table=${Domains.Cuadros}`);
      this._loadModelos();
      this._loadAtributos();
      this._loadVariantes();
      this._loadDisponibilidadYPlanDeProduccion();
    });
  }

  public objectComparisonFunction(item: any, selected: any) {
    return typeof item === 'object' ? item.id === selected.id :
                                      item === selected;
  }

  private _getNombreCompletoCategoria(categoriaId: any) {
    let categoria: Categoria;
    categoria = this._categorias.find((c: Categoria) => c.id === categoriaId)!;
    let nombre = categoria?.nombre;
    if (categoria?.padre) {
      nombre =
          `${this._getNombreCompletoCategoria(categoria.padreId)} > ${nombre}`;
    }
    return nombre;
  }
}
