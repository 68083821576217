import { ColumnTypes, TableHeaders } from 'ekiba-master-table';
import { Domains, FieldTypes } from 'src/app/constants/constants';
import { environment } from 'src/environments/environment';
import {
  EntityField,
  EntityFieldGroup,
} from '../../add-edit-entity/add-edit-entity.component';

export const TABLE_HEADERS_TRADUCCIONES: TableHeaders[] = [
  {
    name: 'id',
    displayName: 'ID',
    type: ColumnTypes.Standard,
  },
  {
    name: 'tabla',
    displayName: 'Tabla',
    type: ColumnTypes.Standard,
  },
  {
    name: 'campo',
    displayName: 'Campo',
    type: ColumnTypes.Standard,
  },
  {
    name: 'registroId',
    displayName: 'Registro ID',
    type: ColumnTypes.Standard,
  },
  {
    name: 'idioma',
    displayName: 'Idioma',
    type: ColumnTypes.Standard,
  },
  {
    name: 'valorVarchar',
    displayName: 'Valor',
    type: ColumnTypes.Standard,
  },
  {
    name: 'valorTxt',
    displayName: 'Valor Txt',
    type: ColumnTypes.Standard,
  },
];

export const TRADUCCIONES_BASE_URL: string = `${environment.api}${Domains.Traducciones}`;

export const TRADUCCIONES_FIELDS: EntityField[] = [
  {
    name: 'tabla',
    displayName: 'Tabla',
    type: FieldTypes.Standard,
    required: true,
    group: 1,
  },
  {
    name: 'campo',
    displayName: 'Campo',
    type: FieldTypes.Standard,
    required: true,
    group: 1,
  },
  {
    name: 'registroId',
    displayName: 'Registro ID',
    type: FieldTypes.Number,
    required: true,
    group: 1,
  },
  {
    name: 'idioma',
    displayName: 'Idioma',
    type: FieldTypes.Selector,
    required: true,
    optionsUrl: `${environment.api}${Domains.Dropdown}?table=${Domains.Idiomas}`,
    isSingleSelector: true,
    group: 1,
  },
  {
    name: 'valorVarchar',
    displayName: 'Valor',
    type: FieldTypes.Standard,
    group: 1,
  },
  {
    name: 'valorTxt',
    displayName: 'Valor Txt',
    type: FieldTypes.Standard,
    group: 1,
  },
];

export const TRADUCCIONES_GROUPS: EntityFieldGroup[] = [
  {
    label: 'Información específica',
    id: 1,
  },
];
