<h1 mat-dialog-title>
  {{ data?.action === "add" ? "Añadir" : "Editar" }} Tecnologia
</h1>
<div mat-dialog-content>
  <mat-form-field
    floatLabel="always"
    appearance="outline"
    *ngIf="tecnologias$ | async as tecnologias"
  >
    <mat-label>Tecnologia</mat-label>
    <mat-select
      [(ngModel)]="tecnologia"
      [compareWith]="objectComparisonFunction"
    >
      <mat-option *ngFor="let option of tecnologias" [value]="option">
        {{ option?.nombre || option?.descripcion || option }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field floatLabel="always" appearance="outline">
    <mat-label>Orden</mat-label>
    <input matInput [(ngModel)]="orden" type="number" />
  </mat-form-field>
</div>
<div class="buttons-section" mat-dialog-actions>
  <button
    type="submit"
    class="cancel-button"
    mat-raised-button
    color="secondary"
    (click)="closeDialog()"
  >
    Salir
  </button>
  <button
    type="submit"
    class="confirm-button"
    mat-raised-button
    color="primary"
    (click)="confirm()"
  >
    Confirmar
  </button>
</div>
