import { ColumnTypes, TableHeaders } from 'ekiba-master-table';
import { Domains, FieldTypes } from 'src/app/constants/constants';
import { AbstractBerria } from 'src/app/constants/models';
import { environment } from 'src/environments/environment';
import {
  EntityField,
  EntityFieldGroup,
} from '../../add-edit-entity/add-edit-entity.component';

export const TABLE_HEADERS_CLIENTES: TableHeaders[] = [
  {
    name: 'id',
    displayName: 'ID',
    type: ColumnTypes.Standard,
  },
  {
    name: 'codigo',
    displayName: 'Código',
    type: ColumnTypes.Standard,
  },
  {
    name: 'nombre',
    displayName: 'Nombre',
    type: ColumnTypes.Standard,
  },
  {
    name: 'direcciones',
    displayName: 'Direcciones',
    type: ColumnTypes.Standard,
  },
];

export const CLIENTES_BASE_URL: string = `${environment.api}${Domains.Clientes}`;

export const CLIENTES_FIELDS: EntityField[] = [
  {
    name: 'codigo',
    displayName: 'Código',
    type: FieldTypes.Standard,
    group: 1,
  },
  {
    name: 'nombre',
    displayName: 'Nombre',
    type: FieldTypes.Translatable,
    editDisabledForDefaultTranslationEntityField: true,
    group: 1,
  },
  {
    name: 'codNegocio',
    displayName: 'Código de negocio',
    type: FieldTypes.Standard,
    group: 2,
  },
  {
    name: 'correo',
    displayName: 'Correo',
    type: FieldTypes.Standard,
    group: 2,
  },
  {
    name: 'direccion',
    displayName: 'Dirección',
    type: FieldTypes.Standard,
    group: 2,
  },
  {
    name: 'direcciones',
    displayName: 'Otras direcciones',
    type: FieldTypes.Selector,
    optionsUrl: `${environment.api}${Domains.Direcciones}`,
    group: 2,
  },
  {
    name: 'grupoCliente',
    displayName: 'Grupo',
    type: FieldTypes.Selector,
    optionsUrl: `${environment.api}${Domains.GrupoClientes}`,
    isSingleSelector: true,
    group: 2,
  },
  {
    name: 'telefono',
    displayName: 'Teléfono Fijo',
    type: FieldTypes.Standard,
    group: 2,
  },
  {
    name: 'movil',
    displayName: 'Teléfono Móvil',
    type: FieldTypes.Standard,
    group: 2,
  },
  {
    name: 'nif',
    displayName: 'NIF',
    type: FieldTypes.Standard,
    group: 2,
  },
  {
    name: 'pais',
    displayName: 'País',
    type: FieldTypes.Standard,
    group: 2,
  },
  {
    name: 'poblacion',
    displayName: 'Población',
    type: FieldTypes.Standard,
    group: 2,
  },
  {
    name: 'provincia',
    displayName: 'Provincia',
    type: FieldTypes.Standard,
    group: 2,
  },
];

export const CLIENTES_GROUPS: EntityFieldGroup[] = [
  {
    label: 'Información general',
    id: 1,
  },
  {
    label: 'Información específica',
    id: 2,
  },
];

export interface Cliente extends AbstractBerria {
  nombre: string;
  direcciones: any[];
  precios: string;
}
