import { ChangeDetectorRef, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { DropdownElement } from 'ekiba-dropdown';
import { filter } from 'rxjs/internal/operators/filter';
import { tap } from 'rxjs/internal/operators/tap';
import { Domains, FieldTypes, Labels } from 'src/app/constants/constants';
import { EntityApiCalls } from 'src/app/shared/entity.api-calls';
import { environment } from 'src/environments/environment';
import {
  AddEditEntityComponent,
  EntityField,
} from '../../add-edit-entity/add-edit-entity.component';
import {
  Categoria,
  CATEGORIAS_BASE_URL,
  CATEGORIAS_FIELDS,
  CATEGORIAS_GROUPS,
} from './categorias.constants';

@Component({
  templateUrl: '../../add-edit-entity/add-edit-entity.component.html',
  styleUrls: ['../../add-edit-entity/add-edit-entity.component.scss'],
})
export class AddEditCategoriasComponent extends AddEditEntityComponent {
  categorias: Categoria[] = [];
  constructor(
    protected readonly route: ActivatedRoute,
    protected readonly api: EntityApiCalls,
    protected readonly router: Router,
    private readonly _cd: ChangeDetectorRef,
    protected readonly dialog: MatDialog
  ) {
    super(route, api, router, dialog);
    this.api
      .getData(`${environment.api}${Domains.Categorias}`)
      .subscribe((c: Categoria[]) => {
        this.formBuilt$
          .pipe(
            filter((formBuilt$) => formBuilt$),
            tap(() => {
              // Formatear nombre de selector de padres
              this.categorias = c;
              let padreField = this.fields.find(
                (field: EntityField) => field.name === 'padre'
              );
              this.api
                .getData(padreField!.optionsUrl!)
                .pipe(
                  tap((res: any) => {
                    padreField!.options = res;

                    padreField!.options = padreField!.options!.map(
                      (option: DropdownElement) => ({
                        id: option.id,
                        nombre: this._getNombreCompleto(option.id),
                      })
                    );
                    // ActivoB2b y ActivoB2c activo por defecto al crear
                    if (this.action === 'add') {
                      this.entityForm.patchValue({
                        ...this._entityToEdit,
                        activoB2b: true,
                        activoB2c: true,
                      });
                    } else {
                      this.entityForm.patchValue(this._entityToEdit);
                    }
                    this._cd.detectChanges();
                  })
                )
                .subscribe();
            })
          )
          .subscribe();
      });
  }

  private _getNombreCompleto(categoriaId: any) {
    let categoria: Categoria;
    categoria = this.categorias.find((c: Categoria) => c.id === categoriaId)!;
    let nombre = categoria?.nombre;
    if (categoria?.padre) {
      nombre = `${this._getNombreCompleto(categoria.padreId)} > ${nombre}`;
    }
    return nombre;
  }

  protected _buildFieldsArray(): void {
    this.fields = CATEGORIAS_FIELDS;
    this._baseUrl = CATEGORIAS_BASE_URL;
    this.groups = CATEGORIAS_GROUPS;
    this.label = Labels.Categorias;
    this.translationsTable = Domains.Categorias;
    let translationsLoaded: boolean = false;
    this.fields.forEach((field) => {
      if (field.type === FieldTypes.Translatable && !translationsLoaded) {
        this._loadTraducciones();
        translationsLoaded = true;
      }
    });
    for (let j = 0; j < this.groups.length; j++) {
      this.fillerFields[j] = [];
      const group = this.groups[j];
      const numberOfFieldsInGroup = this.fields.filter(
        (field) => field.group === group.id
      ).length;
      for (let i = 4; i > numberOfFieldsInGroup % 4; i--) {
        this.fillerFields[j].push(i);
      }
    }
  }
}
