<h1 mat-dialog-title>Confirmación requerida</h1>
<div mat-dialog-content>
  <p>
    {{ data?.texto }}
  </p>
</div>
<div
  mat-dialog-actions
  style="width: 100%; display: flex; justify-content: space-between"
>
  <button mat-button mat-dialog-close cdkFocusInitial>Cancelar</button>
  <button
    mat-raised-button
    color="primary"
    [mat-dialog-close]="true"
    cdkFocusInitial
  >
    Aceptar
  </button>
</div>
