<ekiba-nav-table
  [addItemName]="'Guardar'"
  [showFilter]="false"
  (addedItem)="saveData()"
>
</ekiba-nav-table>
<div class="page-container" style="margin-bottom: 20px">
  <div class="page-container__header">
    <span
      >Producto:
      {{ entityToEdit ? entityToEdit.nombre + " (Configurable)" : "" }}</span
    >
  </div>
  <div
    class="page-container__body"
    *ngIf="action === 'add' || (action === 'edit' && entityToEdit)"
  >
    <mat-tab-group>
      <mat-tab label="Ficha">
        <div class="flex-container--column">
          <div class="flex-container form-container">
            <div class="flex-container" style="justify-content: space-between">
              <div class="flex-container--column form-container__left-box">
                <div class="flex-container form-container__left-box__row">
                  <mat-form-field
                    floatLabel="always"
                    class="form-container__field form-container__field--no-padding-bottom"
                    appearance="outline"
                  >
                    <mat-label>SKU</mat-label>
                    <input
                      matInput
                      [(ngModel)]="entityToEdit.sku"
                      autocomplete="off"
                      disabled
                    />
                    <mat-icon matSuffix>edit_off</mat-icon>
                  </mat-form-field>
                  <mat-form-field
                    floatLabel="always"
                    class="form-container__field form-container__field--no-padding-bottom"
                    appearance="outline"
                  >
                    <mat-label>Familia</mat-label>
                    <input
                      matInput
                      [(ngModel)]="
                        entityToEdit.familia && entityToEdit.familia.nombre
                      "
                      autocomplete="off"
                      disabled
                    />
                    <mat-icon matSuffix>edit_off</mat-icon>
                  </mat-form-field>
                  <div class="form-container__field filler-hideable"></div>
                  <mat-checkbox
                    [(ngModel)]="entityToEdit.activoB2b"
                    class="form-container__field form-container__field--no-padding-bottom"
                    color="primary"
                    >Activo en web B2B</mat-checkbox
                  >
                  <mat-checkbox
                    [(ngModel)]="entityToEdit.activoB2c"
                    class="form-container__field form-container__field--no-padding-bottom"
                    color="primary"
                    >Activo en web B2C</mat-checkbox
                  >

                  <div class="form-container__field filler-hideable"></div>
                </div>
              </div>
              <div class="flex-container--column form-container__right-box">
                <div class="producto-image-container">
                  <input
                    style="
                      visibility: hidden;
                      position: absolute;
                      top: -20000px;
                      left: -20000px;
                    "
                    type="file"
                    (change)="fileChange($event)"
                    #productoImagenInput
                  />
                  <img
                    *ngIf="entityToEdit && entityToEdit['imagen-image']"
                    [src]="entityToEdit && entityToEdit['imagen-image']"
                  />
                  <mat-icon
                    *ngIf="entityToEdit && entityToEdit['imagen-image']"
                    (click)="deleteImage()"
                    class="delete-image-button"
                    mat-mini-fab
                    >delete</mat-icon
                  >
                  <button
                    class="update-image-button"
                    (click)="productoImagenInput.click()"
                  >
                    {{
                      entityToEdit["imagen-image"]
                        ? "Actualizar"
                        : "Añadir imagen"
                    }}
                  </button>
                </div>
              </div>
            </div>

            <div class="flex-container">
              <form
                *ngIf="entityForm"
                [formGroup]="entityForm"
                style="width: 100%; margin-top: 22px"
              >
                <div
                  class="form-group"
                  *ngFor="let group of groups; let i = index"
                >
                  <h2>{{ group.label }}</h2>
                  <div class="form-fields-container">
                    <ng-container
                      *ngFor="let field of getFieldsForGroup(group)"
                    >
                      <div
                        class="filler-field"
                        *ngIf="field.type === fieldTypes.Filler"
                      ></div>
                      <ekiba-translatable-field
                        class="form-container__field form-container__field--25"
                        [class.double]="field.doubleLength"
                        [class.full]="field.fullLength"
                        *ngIf="field.type === fieldTypes.Translatable"
                        [campo]="field.name"
                        [languages]="idiomas$"
                        tabla="gp_producto"
                        [translations]="traducciones$"
                        [disabled]="field.disabled || false"
                        [required]="field.required || false"
                        [defaultValue]="entityForm.get(field.name)!.value"
                        [displayName]="field.displayName"
                        [touched]="touched$"
                      ></ekiba-translatable-field>
                      <ekiba-translatable-textarea
                        class="form-container__field form-container__field--full-width"
                        [class.double]="field.doubleLength"
                        [class.full]="field.fullLength"
                        *ngIf="field.type === fieldTypes.TranslatableTextarea"
                        [campo]="field.name"
                        [languages]="idiomas$"
                        tabla="gp_producto"
                        [translations]="traducciones$"
                        [disabled]="field.disabled || false"
                        [required]="field.required || false"
                        [defaultValue]="entityForm.get(field.name)!.value"
                        [displayName]="field.displayName"
                        [touched]="touched$"
                      ></ekiba-translatable-textarea>
                      <mat-form-field
                        class="form-container__field form-container__field--25"
                        [class.double]="field.doubleLength"
                        [class.full]="field.fullLength"
                        floatLabel="always"
                        *ngIf="field.type === fieldTypes.Standard"
                        appearance="outline"
                      >
                        <mat-label
                          >{{ field.displayName
                          }}{{ field.required && "*" }}</mat-label
                        >
                        <input
                          matInput
                          [formControlName]="field.name"
                          autocomplete="off"
                        />
                      </mat-form-field>
                      <ekiba-dropdown
                        class="form-container__field form-container__field--25"
                        [class.double]="field.doubleLength"
                        [class.full]="field.fullLength"
                        *ngIf="field.type === fieldTypes.Selector"
                        [selectedArray]="field.options!"
                        [loadedValues]="entityForm.get(field.name)!.value"
                        [multipleSelect]="!field.isSingleSelector"
                        [placeholder]="
                          field.isSingleSelector
                            ? 'Selecciona un elemento'
                            : 'Selecciona elementos'
                        "
                        [noEntriesFound]="'No se encontraron elementos'"
                        title="{{ field.displayName }}{{
                          field.required && '*'
                        }}"
                        (changedValue)="updateField($event, field)"
                        [includeEmptyOption]="true"
                        floatLabel="always"
                        [required]="field.required || false"
                        [disabled]="field.disabled || false"
                        [touched]="touched$"
                      >
                      </ekiba-dropdown>
                      <mat-checkbox
                        class="form-container__field form-container__field--25"
                        [class.double]="field.doubleLength"
                        [class.full]="field.fullLength"
                        *ngIf="field.type === fieldTypes.Boolean"
                        [formControlName]="field.name"
                        color="primary"
                        >{{ field.displayName
                        }}{{ field.required && "*" }}</mat-checkbox
                      >
                      <mat-form-field
                        class="form-container__field form-container__field--25"
                        [class.double]="field.doubleLength"
                        [class.full]="field.fullLength"
                        floatLabel="always"
                        *ngIf="field.type === fieldTypes.Image"
                        appearance="outline"
                      >
                        <mat-label
                          >{{ field.displayName
                          }}{{ field.required && "*" }}</mat-label
                        >
                        <input
                          matInput
                          type="file"
                          (change)="fileChange($event)"
                        />
                      </mat-form-field>
                      <p *ngIf="field.type === fieldTypes.Image">
                        Previsualización de {{ field.displayName }}
                      </p>
                      <img
                        class="image-field-preview"
                        [src]="entityForm.get(field.name)?.value"
                        *ngIf="field.type === fieldTypes.Image"
                      />
                    </ng-container>

                    <div
                      class="form-container__field form-container__field--25"
                      *ngFor="let ff of fillerFields[i]"
                    ></div>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <div class="flex-container">
            <div class="table-container-50">
              <h2>Categorías</h2>
              <ekiba-nav-table
                [addItemName]="'Añadir'"
                [showFilter]="false"
                (addedItem)="addCategoria()"
                class="negative-margin"
              >
              </ekiba-nav-table>
              <table
                style="margin-bottom: 20px"
                class="small-table custom-table"
                *ngIf="categorias$ | async as categorias"
              >
                <tr>
                  <th class="align-left">Nombre</th>
                  <th class="align-left">Orden</th>
                  <th class="align-center">Acciones</th>
                </tr>
                <tr *ngIf="!getNonDeletedCategorias()?.length">
                  <td colspan="3" class="align-left">
                    No hay categorias permitidas en este producto
                  </td>
                </tr>
                <tr *ngFor="let categoria of categorias">
                  <ng-container *ngIf="!categoria.deleted">
                    <td class="align-left">
                      {{ categoria?.nombreCompleto }}
                    </td>
                    <td class="align-left">
                      {{ categoria?.orden }}
                    </td>

                    <td class="actions-column">
                      <mat-icon
                        class="edit-button"
                        (click)="editCategoria(categoria)"
                        >edit</mat-icon
                      >
                      <mat-icon
                        class="delete-button"
                        (click)="deleteCategoria(categoria)"
                        >delete</mat-icon
                      >
                    </td>
                  </ng-container>
                </tr>
              </table>
            </div>

            <div class="table-container-50">
              <h2>Tecnologías</h2>
              <ekiba-nav-table
                [addItemName]="'Añadir'"
                [showFilter]="false"
                (addedItem)="addTecnologia()"
                class="negative-margin"
              >
              </ekiba-nav-table>
              <table
                style="margin-bottom: 20px"
                class="small-table custom-table"
              >
                <tr>
                  <th class="align-left">Nombre</th>
                  <th class="align-left">Orden</th>
                  <th class="align-center">Acciones</th>
                </tr>
                <tr *ngIf="!getNonDeletedTecnologias()?.length">
                  <td colspan="3" class="align-left">
                    No hay tecnologías permitidas en este producto
                  </td>
                </tr>
                <tr *ngFor="let tecnologia of entityToEdit.productoTecnologias">
                  <ng-container *ngIf="!tecnologia.deleted">
                    <td class="align-left">
                      {{ tecnologia?.nombre }}
                    </td>
                    <td class="align-left">
                      {{ tecnologia?.orden }}
                    </td>

                    <td class="actions-column">
                      <mat-icon
                        class="edit-button"
                        (click)="editTecnologia(tecnologia)"
                        >edit</mat-icon
                      >
                      <mat-icon
                        class="delete-button"
                        (click)="deleteTecnologia(tecnologia)"
                        >delete</mat-icon
                      >
                    </td>
                  </ng-container>
                </tr>
              </table>
            </div>
          </div>

          <div style="margin-top: 20px" class="flex-container">
            <div class="table-container-50">
              <h2>Bielas permitidas</h2>
              <ekiba-nav-table
                [addItemName]="'Añadir'"
                [showFilter]="false"
                (addedItem)="addBiela()"
                class="negative-margin"
              >
              </ekiba-nav-table>
              <table
                style="margin-bottom: 20px"
                class="small-table custom-table"
                *ngIf="bielas$ | async as bielas"
              >
                <tr>
                  <th class="align-left">Nombre</th>
                  <th class="align-left">Por defecto</th>
                  <th class="align-center">Acciones</th>
                </tr>
                <tr *ngIf="!getNonDeletedBielas()?.length">
                  <td
                    colspan="3"
                    class="align-left"
                    [class.has-error]="bielasHasError() && touched$?.value"
                  >
                    No hay bielas permitidas en este producto
                  </td>
                </tr>
                <tr *ngFor="let biela of bielas">
                  <ng-container *ngIf="!biela.deleted">
                    <td class="align-left">
                      {{ biela?.nombre }}
                    </td>
                    <td class="align-left">
                      {{ biela?.default ? "Sí" : "No" }}
                    </td>

                    <td class="actions-column">
                      <mat-icon class="edit-button" (click)="editBiela(biela)"
                        >edit</mat-icon
                      >
                      <mat-icon
                        class="delete-button"
                        (click)="deleteBiela(biela)"
                        >delete</mat-icon
                      >
                    </td>
                  </ng-container>
                </tr>
              </table>
            </div>

            <div class="table-container-50">
              <h2>Potencias permitidas</h2>
              <ekiba-nav-table
                [addItemName]="'Añadir'"
                [showFilter]="false"
                (addedItem)="addPotencia()"
                class="negative-margin"
              >
              </ekiba-nav-table>
              <table
                style="margin-bottom: 20px"
                class="small-table custom-table"
                *ngIf="potencias$ | async as potencias"
              >
                <tr>
                  <th class="align-left">Nombre</th>
                  <th class="align-left">Por defecto</th>
                  <th class="align-center">Acciones</th>
                </tr>
                <tr *ngIf="!getNonDeletedPotencias()?.length">
                  <td
                    colspan="3"
                    class="align-left"
                    [class.has-error]="potenciasHasError() && touched$?.value"
                  >
                    No hay potencias permitidas en este producto
                  </td>
                </tr>
                <tr *ngFor="let potencia of potencias">
                  <ng-container *ngIf="!potencia.deleted">
                    <td class="align-left">
                      {{ potencia?.nombre }}
                    </td>
                    <td class="align-left">
                      {{ potencia?.default ? "Sí" : "No" }}
                    </td>

                    <td class="actions-column">
                      <mat-icon
                        class="edit-button"
                        (click)="editPotencia(potencia)"
                        >edit</mat-icon
                      >
                      <mat-icon
                        class="delete-button"
                        (click)="deletePotencia(potencia)"
                        >delete</mat-icon
                      >
                    </td>
                  </ng-container>
                </tr>
              </table>
            </div>
          </div>

          <div class="flex-container">
            <div class="table-container-50">
              <h2>Manillares permitidos</h2>
              <ekiba-nav-table
                [addItemName]="'Añadir'"
                [showFilter]="false"
                (addedItem)="addManillar()"
                class="negative-margin"
              >
              </ekiba-nav-table>
              <table
                style="margin-bottom: 20px"
                class="small-table custom-table"
                *ngIf="manillares$ | async as manillares"
              >
                <tr>
                  <th class="align-left">Nombre</th>
                  <th class="align-left">Por defecto</th>
                  <th class="align-center">Acciones</th>
                </tr>
                <tr *ngIf="!getNonDeletedManillares()?.length">
                  <td
                    colspan="3"
                    class="align-left"
                    [class.has-error]="manillaresHasError() && touched$?.value"
                  >
                    No hay manillares permitidos en este producto
                  </td>
                </tr>
                <tr *ngFor="let manillar of manillares">
                  <ng-container *ngIf="!manillar.deleted">
                    <td class="align-left">
                      {{ manillar?.nombre }}
                    </td>
                    <td class="align-left">
                      {{ manillar?.default ? "Sí" : "No" }}
                    </td>

                    <td class="actions-column">
                      <mat-icon
                        class="edit-button"
                        (click)="editManillar(manillar)"
                        >edit</mat-icon
                      >
                      <mat-icon
                        class="delete-button"
                        (click)="deleteManillar(manillar)"
                        >delete</mat-icon
                      >
                    </td>
                  </ng-container>
                </tr>
              </table>
            </div>

            <div class="table-container-50">
              <h2>PRIMES permitidos</h2>
              <ekiba-nav-table
                [addItemName]="'Añadir'"
                [showFilter]="false"
                (addedItem)="addPrime()"
                class="negative-margin"
              >
              </ekiba-nav-table>
              <table
                style="margin-bottom: 20px"
                class="small-table custom-table"
                *ngIf="primes$ | async as primes"
              >
                <tr>
                  <th class="align-left">Nombre</th>
                  <th class="align-left">Por defecto</th>
                  <th class="align-center">Acciones</th>
                </tr>
                <tr *ngIf="!getNonDeletedPrimes()?.length">
                  <td colspan="3" class="align-left">
                    No hay PRIMES permitidos en este producto
                  </td>
                </tr>
                <tr *ngFor="let prime of primes">
                  <ng-container *ngIf="!prime.deleted">
                    <td class="align-left">
                      {{ prime?.nombre }}
                    </td>
                    <td class="align-left">
                      {{ prime?.default ? "Sí" : "No" }}
                    </td>

                    <td class="actions-column">
                      <mat-icon class="edit-button" (click)="editPrime(prime)"
                        >edit</mat-icon
                      >
                      <mat-icon
                        class="delete-button"
                        (click)="deletePrime(prime)"
                        >delete</mat-icon
                      >
                    </td>
                  </ng-container>
                </tr>
              </table>
            </div>
          </div>

          <div class="flex-container">
            <div class="modelos-y-atributos-container">
              <div style="margin-bottom: 20px">
                <div class="table-container">
                  <h2 style="margin-bottom: 5px">Modelos</h2>
                  <table
                    style="margin-bottom: 20px"
                    class="small-table custom-table"
                    *ngIf="modelos$ | async as modelos"
                  >
                    <tr>
                      <th class="align-left">ID</th>
                      <th class="align-left">Nombre</th>
                      <th class="align-center">Activo B2B</th>
                      <th class="align-center">Activo B2C</th>
                      <th class="align-left">Rueda</th>
                      <th class="align-center">Orden</th>
                      <th class="align-center">Acciones</th>
                    </tr>
                    <tr *ngIf="!modelos?.length">
                      <td colspan="3" class="align-left">
                        No hay modelos en este producto
                      </td>
                    </tr>
                    <tr *ngFor="let modelo of modelos">
                      <td
                        class="align-left"
                        [class.has-error]="
                          modelosHasError() && !modelo.rueda && touched$?.value
                        "
                      >
                        {{ modelo?.id }}
                      </td>
                      <td
                        class="align-left"
                        [class.has-error]="
                          modelosHasError() && !modelo.rueda && touched$?.value
                        "
                      >
                        {{ modelo?.nombre }}
                      </td>
                      <td
                        class="align-center"
                        [class.has-error]="
                          modelosHasError() && !modelo.rueda && touched$?.value
                        "
                      >
                        {{ modelo?.activoB2b ? "Sí" : "No" }}
                      </td>
                      <td
                        class="align-center"
                        [class.has-error]="
                          modelosHasError() && !modelo.rueda && touched$?.value
                        "
                      >
                        {{ modelo?.activoB2c ? "Sí" : "No" }}
                      </td>
                      <td
                        class="align-left"
                        [class.has-error]="
                          modelosHasError() && !modelo.rueda && touched$?.value
                        "
                      >
                        {{ modelo?.rueda?.nombre }}
                      </td>
                      <td
                      class="align-left"
                      [class.has-error]="
                        modelosHasError() && !modelo.rueda && touched$?.value
                      "
                    >
                      {{ modelo?.orden }}
                    </td>

                      <td class="actions-column">
                        <mat-icon
                          class="edit-button"
                          (click)="editModelo(modelo)"
                          >edit</mat-icon
                        >
                      </td>
                    </tr>
                  </table>
                </div>
              </div>

              <div class="table-container">
                <h2 class="no-margin-bottom">Atributos adicionales</h2>
                <ekiba-nav-table
                  [addItemName]="'Añadir'"
                  [showFilter]="false"
                  (addedItem)="addAtributo()"
                  class="negative-margin"
                >
                </ekiba-nav-table>
                <table
                  style="margin-bottom: 20px"
                  class="small-table custom-table"
                  *ngIf="atributos$ | async as atributos"
                >
                  <tr>
                    <th class="align-left">ID</th>
                    <th class="align-left">Atributo</th>
                    <th class="align-left">Valor</th>
                    <th class="align-center">Acciones</th>
                  </tr>
                  <tr *ngIf="!getNonDeletedAtributos()?.length">
                    <td colspan="3" class="align-left">
                      No hay atributos en este producto
                    </td>
                  </tr>
                  <tr *ngFor="let atributo of atributos">
                    <ng-container *ngIf="!atributo.deleted">
                      <td class="align-left">
                        {{ atributo?.id }}
                      </td>
                      <td class="align-left">
                        {{ atributo?.nombre }}
                      </td>
                      <td class="align-left">
                        {{ atributo?.valor?.nombre }}
                      </td>

                      <td class="actions-column">
                        <mat-icon
                          class="edit-button"
                          (click)="editAtributo(atributo)"
                          >edit</mat-icon
                        >
                        <mat-icon
                          class="edit-button"
                          (click)="deleteAtributo(atributo)"
                          >delete</mat-icon
                        >
                      </td>
                    </ng-container>
                  </tr>
                </table>
              </div>
            </div>
            <div class="variantes-container">
              <div class="table-container">
                <h2 class="no-margin-bottom">Variantes asociadas</h2>
                <table
                  style="margin-bottom: 20px"
                  class="small-table custom-table"
                  *ngIf="variantes$ | async as variantes"
                >
                  <tr>
                    <th class="align-left">ID</th>
                    <th class="align-left">Referencia</th>
                    <th class="align-left">Nombre</th>
                    <th class="align-center">Activo B2B</th>
                    <th class="align-center">Activo B2C</th>
                    <th class="align-right">PVP</th>
                    <th class="align-left">Talla</th>
                    <th class="align-left">Modelo</th>
                    <th class="align-center">Acciones</th>
                  </tr>
                  <tr *ngIf="!variantes?.length">
                    <td colspan="3" class="align-left">
                      No hay variantes en este producto
                    </td>
                  </tr>
                  <tr *ngFor="let variante of variantes">
                    <td class="align-left">
                      {{ variante?.id }}
                    </td>
                    <td class="align-left">
                      {{ variante?.sku }}
                    </td>
                    <td class="align-left">
                      {{ variante?.nombre }}
                    </td>
                    <td class="align-center">
                      {{ variante?.activo_b2b ? "Sí" : "No" }}
                    </td>
                    <td class="align-center">
                      {{ variante?.activo_b2c ? "Sí" : "No" }}
                    </td>
                    <td class="align-right">
                      {{ variante?.pvp || 0 | number: "0.2-2":"es-ES" }}
                    </td>
                    <td class="align-left">
                      {{ variante?.talla_nombre }}
                    </td>
                    <td class="align-left">
                      {{ variante?.modelo_nombre }}
                    </td>

                    <td class="actions-column">
                      <mat-icon
                        class="edit-button"
                        (click)="editVariante(variante)"
                        >edit</mat-icon
                      >
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>

          <div class="disponibilidad-container">
            <div class="table-container">
              <h2 class="no-margin-bottom">Disponibilidad</h2>
              <table
                style="margin-bottom: 20px"
                class="small-table custom-table"
                *ngIf="entityToEdit?.productoDisponibilidades"
              >
                <tr>
                  <th class="align-left">Modelo</th>
                  <th class="align-left">Variante</th>
                  <th class="align-center">Talla</th>
                  <th class="align-center">Colores</th>
                  <th class="align-right">Stock</th>
                </tr>
                <tr *ngIf="!entityToEdit?.productoDisponibilidades?.length">
                  <td colspan="3" class="align-left">
                    No hay disponibilidad en este producto
                  </td>
                </tr>
                <tr
                  *ngFor="
                    let disponibilidad of entityToEdit?.productoDisponibilidades
                  "
                >
                  <td class="align-left">
                    {{ disponibilidad?.modelo?.nombre }}
                  </td>
                  <td class="align-left">
                    {{ disponibilidad?.variante?.nombre }}
                  </td>
                  <td class="align-center">
                    {{ disponibilidad?.variante?.talla }}
                  </td>
                  <td class="align-center">
                    <div
                      class="color-circle-producto"
                      *ngFor="
                        let color of [
                          disponibilidad?.colorFondo,
                          disponibilidad?.colorCalca1,
                          disponibilidad?.colorCalca2,
                          disponibilidad?.colorColor,
                          disponibilidad?.colorAcabado
                        ]
                      "
                      [matTooltip]="
                        color?.codigo
                          ? color?.codigo + ': ' + color?.nombre
                          : ''
                      "
                    >
                      <div
                        class="color-circle"
                        [class.color-is-white]="
                          color?.hexadecimal === '#FFFFFF' ||
                          color?.hexadecimal === '#ffffff' ||
                          color?.hexadecimal === '#FFF' ||
                          color?.hexadecimal === '#fff'
                        "
                        [style.background-color]="color?.hexadecimal"
                      >
                        <img
                          *ngIf="color?.imagen"
                          [src]="color?.imagen"
                          class="imagen-color"
                        />
                      </div>
                      <span class="color-circle-producto__tipo-text">{{
                        color?.tipo
                      }}</span>
                    </div>
                  </td>
                  <td class="align-right">
                    {{ disponibilidad?.cantidad }}
                  </td>
                </tr>
              </table>
            </div>
          </div>

          <div class="plan-de-produccion-container">
            <div class="table-container">
              <h2 class="no-margin-bottom">Plan de Producción</h2>
              <table
                style="margin-bottom: 20px"
                class="small-table custom-table"
                *ngIf="entityToEdit?.productoPlanDeProduccion"
              >
                <tr>
                  <th class="align-left">Modelo</th>
                  <th class="align-left">Variante</th>
                  <th class="align-left">Fecha</th>
                  <th class="align-center">Talla</th>
                  <th class="align-center">Colores</th>
                  <th class="align-right">Cantidad</th>
                </tr>
                <tr *ngIf="!entityToEdit?.productoPlanDeProduccion.length">
                  <td colspan="3" class="align-left">
                    No hay plan de producción en este producto
                  </td>
                </tr>
                <tr
                  *ngFor="
                    let planDeProduccion of entityToEdit?.productoPlanDeProduccion
                  "
                >
                  <td class="align-left">
                    {{ planDeProduccion?.modelo?.nombre }}
                  </td>
                  <td class="align-left">
                    {{ planDeProduccion?.variante?.nombre }}
                  </td>
                  <td class="align-left">
                    {{ planDeProduccion?.fecha | date: "dd/MM/yyyy" }}
                  </td>
                  <td class="align-center">
                    {{ planDeProduccion?.variante?.talla }}
                  </td>
                  <td class="align-center">
                    <div
                      class="color-circle-producto"
                      *ngFor="
                        let color of [
                          planDeProduccion?.colorFondo,
                          planDeProduccion?.colorCalca1,
                          planDeProduccion?.colorCalca2,
                          planDeProduccion?.colorColor,
                          planDeProduccion?.colorAcabado
                        ]
                      "
                      [matTooltip]="
                        color?.codigo
                          ? color?.codigo + ': ' + color?.nombre
                          : ''
                      "
                    >
                      <div
                        class="color-circle"
                        [class.color-is-white]="
                          color?.hexadecimal === '#FFFFFF' ||
                          color?.hexadecimal === '#ffffff' ||
                          color?.hexadecimal === '#FFF' ||
                          color?.hexadecimal === '#fff'
                        "
                        [style.background-color]="color?.hexadecimal"
                      >
                        <img
                          *ngIf="color?.imagen"
                          [src]="color?.imagen"
                          class="imagen-color"
                        />
                      </div>
                      <span class="color-circle-producto__tipo-text">{{
                        color?.tipo
                      }}</span>
                    </div>
                  </td>
                  <td class="align-right">
                    {{ planDeProduccion?.cantidad }}
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Precios">Precios</mat-tab>
    </mat-tab-group>
  </div>
</div>
