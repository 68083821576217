import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Domains, Rutas, SKIP_AUTH } from '../constants/constants';
import { LoginService } from '../pages/login/login.service';
import { AuthService } from '../shared/auth.service';

@Injectable({ providedIn: 'root' })
export class LoginGuard implements CanActivate {
  constructor(
    private readonly _loginService: LoginService,
    private readonly _authService: AuthService,
    private readonly _router: Router
  ) {}

  public canActivate(
    _route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ): boolean | Observable<boolean> {
    if (SKIP_AUTH) return true;
    if (!this._authService.getToken()) {
      return this._goToNotLoged();
    }
    const loginDetails = this._authService.getLoginDetails();
    if (
      !this._authService.authPermissions$.value ||
      !this._authService.authPermissions$.value.length ||
      this._authService.authPermissions$.value.length == 0
    ) {
      return new Observable<boolean>((observer) => {
        this._authService
          .getAuth(
            loginDetails.berriaGestorDeProductosToken,
            `${environment.api}${Domains.Autenticacion}/${Domains.Auth}`
          )
          .pipe(
            map((auth: any) => {
              this._authService.authPermissions$.next(auth.permissions);
              this._router.navigate([_state.url]);
              observer.next(true);
              observer.complete();
            })
          )
          .subscribe();
      });
    } else {
      this._loginService.logged$.next(loginDetails);
      return true;
    }
  }

  private _goToNotLoged(): boolean {
    this._router.navigate([Rutas.Login]);
    return false;
  }
}
