import { ColumnTypes, TableHeaders } from 'ekiba-master-table';
import { Domains, FieldTypes } from 'src/app/constants/constants';
import { AbstractBerria } from 'src/app/constants/models';
import { environment } from 'src/environments/environment';
import {
  EntityField,
  EntityFieldGroup,
} from '../../add-edit-entity/add-edit-entity.component';

export const TABLE_HEADERS_TIENDAS: TableHeaders[] = [
  {
    name: 'id',
    displayName: 'ID',
    type: ColumnTypes.Standard,
  },
  {
    name: 'nombre',
    displayName: 'Usuario',
    type: ColumnTypes.Standard,
  },
  {
    name: 'activo',
    displayName: 'Activo',
    type: ColumnTypes.Boolean,
  },
  {
    name: 'webId',
    displayName: 'Web ID',
    type: ColumnTypes.Standard,
  },
];

export const TIENDAS_BASE_URL: string = `${environment.api}${Domains.Tiendas}`;

export const TIENDAS_FIELDS: EntityField[] = [
  {
    name: 'nombre',
    displayName: 'Nombre',
    type: FieldTypes.Standard,
    required: true,
    group: 1,
  },
  {
    name: 'activo',
    displayName: 'Activo',
    type: FieldTypes.Boolean,
    group: 1,
  },
  {
    name: 'webId',
    displayName: 'Web ID',
    type: FieldTypes.Standard,
    disabled: true,
    group: 2,
  },
];

export const TIENDAS_GROUPS: EntityFieldGroup[] = [
  {
    label: 'Información general',
    id: 1,
  },
  {
    label: 'Información específica',
    id: 2,
  },
];

export interface Tienda extends AbstractBerria {
  nombre: string;
}
