import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Language } from 'ekiba-translatable-field';
import { filter, tap } from 'rxjs/operators';
import { Rutas } from 'src/app/constants/constants';
import { EntityApiCalls } from 'src/app/shared/entity.api-calls';
import {
  AddEditEntityComponent,
  EntityField,
} from '../../add-edit-entity/add-edit-entity.component';

@Component({
  templateUrl: '../../add-edit-entity/add-edit-entity.component.html',
  styleUrls: ['../../add-edit-entity/add-edit-entity.component.scss'],
})
export class AddEditTraduccionesComponent
  extends AddEditEntityComponent
  implements OnInit
{
  constructor(
    protected readonly route: ActivatedRoute,
    protected readonly api: EntityApiCalls,
    protected readonly router: Router,
    protected readonly dialog: MatDialog
  ) {
    super(route, api, router, dialog);
    this.formBuilt$
      .pipe(
        filter((formBuilt$) => formBuilt$),
        tap(() => {
          if (this._entityToEdit) {
            if (this._entityToEdit.idiomaId) {
              this._entityToEdit.idioma = this.fields
                .find((field: EntityField) => field.name === 'idioma')!
                .options!.find(
                  (idioma: Language) =>
                    idioma.id === this._entityToEdit.idiomaId
                );
              this.entityForm.patchValue(this._entityToEdit);
            }
          }
        })
      )
      .subscribe();
  }

  public saveData(): void {
    if (this.entityForm.valid) {
      const formFieldsMap: any = {};
      this.fields.forEach((field) => {
        formFieldsMap[field.name] = this.entityForm.getRawValue()[field.name];
        // Send only ids for array and object fields
        if (field.name === 'idioma') {
          formFieldsMap[`${field.name}Id`] = formFieldsMap[field.name].id;
          delete formFieldsMap[field.name];
        }
        // if (!formFieldsMap[field.name]) {
        //   delete formFieldsMap[field.name];
        // }
      });
      const data: any = {
        ...formFieldsMap,
        id: this._id,
      };

      switch (this.action) {
        case 'add':
          this.api.addData(data, this._baseUrl).subscribe(() => {
            this.router.navigate([`${this._returnUrl}/${Rutas.List}`]);
          });
          break;
        case 'edit':
          this.api.updateData(data, this._baseUrl).subscribe(() => {
            this.router.navigate([`${this._returnUrl}/${Rutas.List}`]);
          });
          break;
      }
    } else {
      this.entityForm.markAllAsTouched();
      this.touched$.next(true);
    }
  }
}
