<ekiba-nav-table
  [addItemName]="'Guardar'"
  [showFilter]="false"
  (addedItem)="saveData()"
  *ngIf="action !== 'view'"
>
</ekiba-nav-table>
<div class="page-container">
  <div class="page-container__header">
    <span
      >{{ action === "add" ? "Crear" : action === "edit" ? "Editar" : "Ver" }}
      {{ label }}</span
    >
  </div>
  <div class="page-container__body">
    <form *ngIf="entityForm" [formGroup]="entityForm">
      <div class="form-group" *ngFor="let group of groups; let i = index">
        <h2>{{ group.label }}</h2>
        <div class="form-fields-container">
          <ng-container *ngFor="let field of getFieldsForGroup(group)">
            <div
              class="filler-field"
              *ngIf="field.type === fieldTypes.Filler"
            ></div>
            <ekiba-translatable-field
              *ngIf="field.type === fieldTypes.Translatable"
              [class.three-columns]="numberOfColumns === 3"
              [class.two-columns]="numberOfColumns === 2"
              [class.one-column]="numberOfColumns === 1"
              [class.double]="field.doubleLength"
              [class.full]="field.fullLength"
              [campo]="field.name"
              [languages]="idiomas$"
              [tabla]="translationsTable"
              [translations]="traducciones$"
              [disabled]="action === 'view' || field.disabled || false"
              [required]="field.required || false"
              [defaultValue]="entityForm.get(field.name)!.value"
              [displayName]="field.displayName"
              [touched]="touched$"
            ></ekiba-translatable-field>
            <ekiba-translatable-textarea
              *ngIf="field.type === fieldTypes.TranslatableTextarea"
              [class.three-columns]="numberOfColumns === 3"
              [class.two-columns]="numberOfColumns === 2"
              [class.one-column]="numberOfColumns === 1"
              [class.double]="field.doubleLength"
              [class.full]="field.fullLength"
              [campo]="field.name"
              [languages]="idiomas$"
              [tabla]="translationsTable"
              [translations]="traducciones$"
              [disabled]="action === 'view' || field.disabled || false"
              [required]="field.required || false"
              [defaultValue]="entityForm.get(field.name)!.value"
              [displayName]="field.displayName"
              [touched]="touched$"
            ></ekiba-translatable-textarea>
            <mat-form-field
              floatLabel="always"
              *ngIf="field.type === fieldTypes.Standard"
              appearance="outline"
              [class.three-columns]="numberOfColumns === 3"
              [class.two-columns]="numberOfColumns === 2"
              [class.one-column]="numberOfColumns === 1"
              [class.double]="field.doubleLength"
              [class.full]="field.fullLength"
            >
              <mat-label
                >{{ field.displayName }}{{ field.required && "*" }}</mat-label
              >
              <input
                matInput
                [formControlName]="field.name"
                autocomplete="off"
              />
            </mat-form-field>

            <mat-form-field
              floatLabel="always"
              *ngIf="field.type === fieldTypes.Number"
              appearance="outline"
              [class.three-columns]="numberOfColumns === 3"
              [class.two-columns]="numberOfColumns === 2"
              [class.one-column]="numberOfColumns === 1"
              [class.double]="field.doubleLength"
              [class.full]="field.fullLength"
            >
              <mat-label
                >{{ field.displayName }}{{ field.required && "*" }}</mat-label
              >
              <input
                matInput
                [formControlName]="field.name"
                autocomplete="off"
                type="number"
              />
            </mat-form-field>
            <ekiba-dropdown
              *ngIf="field.type === fieldTypes.Selector"
              [selectedArray]="field.options!"
              [loadedValues]="entityForm.get(field.name)!.value"
              [multipleSelect]="!field.isSingleSelector"
              [placeholder]="
                field.isSingleSelector
                  ? 'Selecciona un elemento'
                  : 'Selecciona elementos'
              "
              [noEntriesFound]="'No se encontraron elementos'"
              [title]="
                field.required ? field.displayName + '*' : field.displayName
              "
              [class.three-columns]="numberOfColumns === 3"
              [class.two-columns]="numberOfColumns === 2"
              [class.one-column]="numberOfColumns === 1"
              [class.double]="field.doubleLength"
              [class.full]="field.fullLength"
              (changedValue)="updateField($event, field)"
              [includeEmptyOption]="true"
              floatLabel="always"
              [required]="field.required || false"
              [disabled]="action === 'view' || field.disabled || false"
              [touched]="touched$"
            >
            </ekiba-dropdown>

            <mat-checkbox
              *ngIf="field.type === fieldTypes.Boolean"
              [formControlName]="field.name"
              color="primary"
              [class.three-columns]="numberOfColumns === 3"
              [class.two-columns]="numberOfColumns === 2"
              [class.one-column]="numberOfColumns === 1"
              [class.double]="field.doubleLength"
              [disabled]="action === 'view'"
              >{{ field.displayName }}{{ field.required && "*" }}</mat-checkbox
            >
            <div
              class="mat-form-field add-edit-entity__image-selector"
              *ngIf="field.type === fieldTypes.Image"
              [class.three-columns]="numberOfColumns === 3"
              [class.two-columns]="numberOfColumns === 2"
              [class.one-column]="numberOfColumns === 1"
              [class.double]="field.doubleLength"
              [class.full]="field.fullLength"
            >
              <input
                #fileInput
                hidden
                type="file"
                (change)="fileChange($event, field.name)"
                [formControlName]="field.name"
              />
              <mat-icon
                *ngIf="entityForm.get(field.name + '-image')?.value"
                (click)="deleteImage(field.name)"
                class="delete-image-button"
                mat-mini-fab
                >delete</mat-icon
              >
              <img
                *ngIf="entityForm.get(field.name + '-image')?.value"
                [src]="entityForm.get(field.name + '-image')?.value"
              />
              <button class="update-image-button" (click)="fileInput.click()">
                {{
                  entityForm.get(field.name + "-image")?.value
                    ? "Actualizar"
                    : "Añadir imagen"
                }}
              </button>
            </div>
          </ng-container>

          <div
            class="filler-field"
            *ngFor="let ff of fillerFields[i]"
            [class.three-columns]="numberOfColumns === 3"
            [class.two-columns]="numberOfColumns === 2"
            [class.one-column]="numberOfColumns === 1"
          ></div>
        </div>
      </div>
    </form>
  </div>
</div>
