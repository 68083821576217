import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable } from 'rxjs/internal/Observable';
import {
  AuthPermission,
  LoggedSubject,
  LoginInput,
} from '../pages/login/login.models';
import * as crypto from 'crypto-js';
import { environment } from 'src/environments/environment';

export interface Credentials {
  username: string;
  password: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public authPermissions$: BehaviorSubject<AuthPermission[]> =
    new BehaviorSubject<AuthPermission[]>([]);

  constructor(private readonly _http: HttpClient) {}

  public getAuth(token: string, url: string): Observable<any> {
    return this._http.get<any>(url, { headers: { 'x-access-token': token } });
  }

  public getToken() {
    const loginDetails = this.getLoginDetails();
    return loginDetails?.berriaGestorDeProductosToken;
  }

  public getLoginDetails() {
    const loginDetails = localStorage.getItem('berriaGestorDeProductosLogin');
    if (loginDetails) {
      const bytes = crypto.AES.decrypt(loginDetails, environment.ek);
      const decryptedLoginDetails = JSON.parse(bytes.toString(crypto.enc.Utf8));
      return decryptedLoginDetails;
    } else {
      return false;
    }
  }

  public setLoginDetails(loginDetails: LoggedSubject) {
    const encryptedLoginDetails = crypto.AES.encrypt(
      JSON.stringify(loginDetails),
      environment.ek
    ).toString();
    localStorage.setItem('berriaGestorDeProductosLogin', encryptedLoginDetails);
  }

  public getCredentials() {
    const credentials = localStorage.getItem(
      'berriaGestorDeProductosCredentials'
    );
    if (credentials) {
      const bytes = crypto.AES.decrypt(credentials, environment.ek);
      const decryptedCredentials = JSON.parse(bytes.toString(crypto.enc.Utf8));
      return decryptedCredentials;
    } else {
      return false;
    }
  }

  public setCredentials(credentials: LoginInput) {
    const encryptedCredentials = crypto.AES.encrypt(
      JSON.stringify(credentials),
      environment.ek
    ).toString();
    localStorage.setItem(
      'berriaGestorDeProductosCredentials',
      encryptedCredentials
    );
  }

  /**
   * @param baseRoute route in which given action is performed. E.g. 'gestor-de-usuarios/usuario'.
   * @param action action to perform in given route. E.g. 'add'.
   * @returns boolean value determining if user has access to perform given action in given route.
   */
  public hasPermissionTo(baseRoute: string, action: string): boolean {
    return !!this.authPermissions$.value.find(
      (permission) => permission.ruta === `${baseRoute}/${action}`
    );
  }
}
