import { Component, Inject, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface ConfiguracionDeColoresInput {
  sdp: any;
  colores: any[];
}

export const enum TiposColor {
  Fondo = 'Fondo',
  Color = 'Color',
  Calca1 = 'Calca 1',
  Calca2 = 'Calca 2',
  Acabado = 'Acabado',
}

@Component({
  templateUrl: './configuracion-de-colores.dialog.html',
  styleUrls: ['./configuracion-de-colores.dialog.scss'],
})
export class ConfiguracionDeColoresDialog {
  public toggleAll: boolean = false;

  public constructor(
    public dialogRef: MatDialogRef<ConfiguracionDeColoresDialog>,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: ConfiguracionDeColoresInput
  ) {}

  public closeDialog(): void {
    this.dialogRef.close(false);
  }

  public confirm(): void {
    if (this.data.sdp) {
      this.data.sdp.cuadroServicioPinturaColores = this.data?.colores;
    }
    this.dialogRef.close(this.data.sdp);
  }

  public toggleAllColors(event: boolean, tipo: string) {
    this.data?.colores?.forEach((colores: any) => {
      colores.forEach((color: any) => {
        if (color.tipo === tipo) color.active = event;
      });
    });
  }

  public setDefault(tipo: {
    active: boolean;
    color: any;
    default: number;
    tipo: string;
  }) {
    this.data?.colores?.forEach((colores: any) => {
      colores.forEach((color: any) => {
        if (color.tipo === tipo.tipo) color.default = 0;
      });
    });
    tipo.default = 1;
  }
}
