import { Injector, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatTreeModule } from '@angular/material/tree';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatTabsModule, MAT_TABS_CONFIG } from '@angular/material/tabs';

import { EkibaNavbarModule } from 'ekiba-navbar';
import { EkibaMasterTableModule } from 'ekiba-master-table';
import { EkibaDropdownModule } from 'ekiba-dropdown';
import { EkibaTranslatableFieldModule } from 'ekiba-translatable-field';

import { AppInjector } from './shared/app-injector';
import { EntityApiCalls } from './shared/entity.api-calls';
import { DateManagerService } from './shared/date-manager.service';

import { NotAuthorizedInterceptor } from './interceptors/not-authorized.interceptor';
import { LoadingInterceptor } from './interceptors/loading.interceptor';
import { ErrorInterceptor } from './interceptors/error.interceptor';

import { RolesComponent } from './pages/gestion-de-usuarios/roles/roles.component';
import { AddEditEntityComponent } from './pages/add-edit-entity/add-edit-entity.component';
import { UsuariosComponent } from './pages/gestion-de-usuarios/users-list/usuarios.component';
import { PermissionsComponent } from './pages/gestion-de-usuarios/permissions/permissions.component';
import { PaginaNoEncontradaComponent } from './pages/errors/pagina-no-encontrada/pagina-no-encontrada.component';
import { NoAutorizadoComponent } from './pages/errors/no-autorizado/no-autorizado.component';
import { LoginComponent } from './pages/login/login.component';
import { FamiliasComponent } from './pages/configuracion-de-maestros/familias/familias.component';
import { TallasComponent } from './pages/configuracion-de-maestros/tallas/tallas.component';
import { ServiciosDePinturaComponent } from './pages/configuracion-de-maestros/servicios-de-pintura/servicios-de-pintura.component';
import { BielasComponent } from './pages/configuracion-de-maestros/bielas/bielas.component';
import { PotenciasComponent } from './pages/configuracion-de-maestros/potencias/potencias.component';
import { ComponentesComponent } from './pages/configuracion-de-maestros/componentes/componentes.component';
import { GruposDeCambioComponent } from './pages/configuracion-de-maestros/grupos-de-cambio/grupos-de-cambio.component';
import { IdiomasComponent } from './pages/configuracion-general/idiomas/idiomas.component';
import { TiendasComponent } from './pages/configuracion-general/tiendas/tiendas.component';
import { HomeComponent } from './pages/home/home.component';
import { CategoriasComponent } from './pages/configuracion-de-productos/categorias/categorias.component';
import { ColoresComponent } from './pages/configuracion-de-maestros/colores/colores.component';
import { ManillaresComponent } from './pages/configuracion-de-maestros/manillares/manillares.component';
import { AtributosComponent } from './pages/configuracion-de-maestros/atributos/atributos.component';
import { CuadrosComponent } from './pages/configuracion-de-maestros/cuadros/cuadros.component';
import { RuedasComponent } from './pages/configuracion-de-maestros/ruedas/ruedas.component';
import { ProductosComponent } from './pages/configuracion-de-productos/productos/productos.component';
import { VariantesComponent } from './pages/configuracion-de-productos/variantes/variantes.component';
import { HistoricoDeSincronizacionComponent } from './pages/integraciones/historico-sincronizacion/historico-sincronizacion.component';
import { ValoresDeAtributosComponent } from './pages/configuracion-de-maestros/valores-de-atributo/valores-de-atributo.component';
import { AddEditCuadroComponent } from './pages/configuracion-de-maestros/cuadros/add-edit-cuadro/add-edit-cuadro.component';

import { ConfirmDialog } from './popups/confirm-dialog/confirm-dialog.component';
import { ConfiguracionDeColoresDialog } from './popups/configuracion-de-colores/configuracion-de-colores.dialog';
import { AddEditCuadroServicioDePinturaPopup } from './popups/add-edit-cuadro-servicio-de-pintura/add-edit-cuadro-servicio-de-pintura.component';
import { WarningPopupComponent } from './popups/warning/warning-popup.component';
import { AddEditProductoComponent } from './pages/configuracion-de-productos/productos/add-edit-producto/add-edit-producto.component';
import { AddEditComponentesComponent } from './pages/configuracion-de-maestros/componentes/add-edit-componentes.component';
import { AddEditProductoAtributoPopup } from './popups/add-edit-producto-atributo/add-edit-producto-atributo.component';
import { AddEditProductoBielaPopup } from './popups/add-edit-producto-biela/add-edit-producto-biela.component';
import { AddEditProductoPotenciaPopup } from './popups/add-edit-producto-potencia/add-edit-producto-potencia.component';
import { AddEditProductoManillarPopup } from './popups/add-edit-producto-manillar/add-edit-producto-manillar.component';
import { AddEditProductoModeloPopup } from './popups/add-edit-producto-modelo/add-edit-producto-modelo.component';

import es from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
import { TraduccionesComponent } from './pages/configuracion-general/traducciones/traducciones.component';
import { AddEditTraduccionesComponent } from './pages/configuracion-general/traducciones/add-edit-traducciones.component';
import { TareasComponent } from './pages/integraciones/tareas/tareas.component';
import { AddEditProductoPrimePopup } from './popups/add-edit-producto-prime/add-edit-producto-prime.component';
import { AddEditProductoCategoriaPopup } from './popups/add-edit-producto-categoria/add-edit-producto-categoria.component';
import { ActivateProductoModelosVariantesPopup } from './popups/activate-producto-modelos-variantes/activate-producto-modelos-variantes.popup';
import { ClientesComponent } from './pages/gestion-de-clientes/clientes/clientes.component';
import { DireccionesComponent } from './pages/gestion-de-clientes/direcciones/direcciones.component';
import { GruposClientesComponent } from './pages/gestion-de-clientes/grupos-clientes/grupos-clientes.component';
import { TecnologiasComponent } from './pages/configuracion-de-maestros/tecnologias/tecnologias.component';
import { EditVarianteComponent } from './pages/configuracion-de-productos/variantes/edit-variante.component';
import { AddEditProductoTecnologiaPopup } from './popups/add-edit-producto-tecnologia/add-edit-producto-tecnologia.component';
import { AddEditServicioDePinturaComponent } from './pages/configuracion-de-maestros/servicios-de-pintura/add-edit-servicio-de-pintura.component';
import { AddEditCategoriasComponent } from './pages/configuracion-de-productos/categorias/add-edit-categoria.component';

registerLocaleData(es);

@NgModule({
  declarations: [
    AppComponent,
    RolesComponent,
    UsuariosComponent,
    AddEditEntityComponent,
    PermissionsComponent,
    PaginaNoEncontradaComponent,
    NoAutorizadoComponent,
    WarningPopupComponent,
    LoginComponent,
    FamiliasComponent,
    TallasComponent,
    ServiciosDePinturaComponent,
    BielasComponent,
    PotenciasComponent,
    ComponentesComponent,
    GruposDeCambioComponent,
    IdiomasComponent,
    HomeComponent,
    CategoriasComponent,
    ConfirmDialog,
    ColoresComponent,
    ManillaresComponent,
    AtributosComponent,
    CuadrosComponent,
    RuedasComponent,
    ProductosComponent,
    VariantesComponent,
    TiendasComponent,
    TareasComponent,
    HistoricoDeSincronizacionComponent,
    AddEditCuadroComponent,
    ValoresDeAtributosComponent,
    ConfiguracionDeColoresDialog,
    AddEditProductoComponent,
    AddEditCuadroServicioDePinturaPopup,
    AddEditComponentesComponent,
    AddEditProductoAtributoPopup,
    AddEditProductoBielaPopup,
    AddEditProductoPotenciaPopup,
    AddEditProductoManillarPopup,
    AddEditProductoModeloPopup,
    AddEditProductoTecnologiaPopup,
    TraduccionesComponent,
    AddEditTraduccionesComponent,
    AddEditProductoPrimePopup,
    AddEditProductoCategoriaPopup,
    ActivateProductoModelosVariantesPopup,
    ClientesComponent,
    DireccionesComponent,
    GruposClientesComponent,
    TecnologiasComponent,
    EditVarianteComponent,
    AddEditServicioDePinturaComponent,
    AddEditCategoriasComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    EkibaNavbarModule,
    EkibaMasterTableModule,
    MatButtonModule,
    MatSelectModule,
    MatTreeModule,
    MatCheckboxModule,
    MatCardModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    EkibaDropdownModule,
    EkibaTranslatableFieldModule,
    MatTabsModule,
  ],
  providers: [
    EntityApiCalls,
    DateManagerService,
    { provide: LOCALE_ID, useValue: 'es-ES' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NotAuthorizedInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true,
    },
    { provide: MAT_TABS_CONFIG, useValue: { animationDuration: '0ms' } },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(injector: Injector) {
    AppInjector.setInjector(injector);
  }
}
