<div class="login-container" *ngIf="loginForm">
  <img
    src="assets/img/logo-berria.svg"
    alt="Berria"
    class="login-container__logo"
  />
  <mat-card class="login-container__body">
    <span *ngIf="defaultErrorMesssage" class="mat-subheading-2 error-color">
      No se ha podido hacer el login
    </span>
    <span *ngIf="errorMesssage" class="mat-subheading-2 error-color">
      {{ errorMesssage }}
    </span>
    <form [formGroup]="loginForm" (ngSubmit)="login()">
      <mat-form-field floatLabel="always" appearance="fill">
        <mat-label>Usuario</mat-label>
        <mat-icon matPrefix>person</mat-icon>
        <input
          matInput
          type="text"
          class="form-control"
          id="user-input"
          autocomplete="off"
          formControlName="username"
          (cdkAutofill)="loginForm.updateValueAndValidity()"
        />
      </mat-form-field>
      <mat-form-field floatLabel="always" appearance="fill">
        <mat-label>Contraseña</mat-label>
        <mat-icon matPrefix>vpn_key</mat-icon>
        <input
          matInput
          [type]="hidePassword ? 'password' : 'text'"
          class="form-control"
          formControlName="password"
          (cdkAutofill)="loginForm.updateValueAndValidity()"
        />
        <button
          mat-icon-button
          matSuffix
          (click)="hidePassword = !hidePassword"
          [attr.aria-label]="'Ocultar contraseña'"
          [attr.aria-pressed]="hidePassword"
          type="button"
        >
          <mat-icon>{{
            hidePassword ? "visibility_off" : "visibility"
          }}</mat-icon>
        </button>
      </mat-form-field>
      <mat-checkbox
        color="primary"
        class="form-control"
        formControlName="rememberMe"
        >Recuérdame</mat-checkbox
      >
      <ng-container [ngSwitch]="loginForm.valid">
        <button
          *ngSwitchCase="true"
          mat-raised-button
          color="primary"
          (click)="login()"
          type="submit"
          class="submit-button"
        >
          Entrar
        </button>
        <button
          *ngSwitchDefault
          mat-raised-button
          color="primary"
          disabled
          matTooltip="Rellena los campos obligatorios. (*)"
          class="submit-button"
        >
          Entrar
        </button>
      </ng-container>
    </form>
  </mat-card>
</div>
