import { Component, Inject, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { SERVICIOS_DE_PINTURA_BASE_URL } from 'src/app/pages/configuracion-de-maestros/servicios-de-pintura/servicios-de-pintura.constants';
import { EntityApiCalls } from 'src/app/shared/entity.api-calls';

export interface AddEditCuadroServicioDePinturaPopupInput {
  action: string;
  sdp: any;
  default: boolean;
  sdpsIncorporados: any[];
}

export interface AddEditCuadroServicioDePinturaPopupOutput {
  sdp: any;
  default: boolean;
}

@Component({
  templateUrl: './add-edit-cuadro-servicio-de-pintura.component.html',
  styleUrls: ['./add-edit-cuadro-servicio-de-pintura.component.scss'],
})
export class AddEditCuadroServicioDePinturaPopup {
  sdp: any;
  sdp$: Observable<any> | undefined;
  noMoreSdpsToAdd: boolean = false;
  default: boolean = false;

  public constructor(
    public dialogRef: MatDialogRef<AddEditCuadroServicioDePinturaPopup>,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: AddEditCuadroServicioDePinturaPopupInput,
    private _api: EntityApiCalls
  ) {
    if (this.data?.sdpsIncorporados) {
      // Show only SDPs that are not included
      this.sdp$ = this._api.getData(SERVICIOS_DE_PINTURA_BASE_URL).pipe(
        map((sdps: any) =>
          sdps.filter(
            (sdp: any) =>
              (this.data?.action === 'edit' && // Show SDP that we are editing
                this.data?.sdp.servicioPintura?.id === sdp.id) ||
              !this.data?.sdpsIncorporados.find(
                (sdpI: any) =>
                  sdp.id === sdpI.servicioPintura?.id && !sdpI.deleted
              )
          )
        ),
        tap((sdps: any) => {
          if (!sdps?.length) {
            this.noMoreSdpsToAdd = true;
          } else {
            this.noMoreSdpsToAdd = false;
          }
        })
      );
    } else {
      this.sdp$ = this._api.getData(SERVICIOS_DE_PINTURA_BASE_URL);
    }
    this.sdp = this.data.sdp?.servicioPintura;
    this.default = this.data.default;
  }

  public closeDialog(): void {
    this.dialogRef.close(false);
  }

  public confirm(): void {
    this.dialogRef.close({
      servicioPintura: this.sdp,
      default: this.default ? 1 : 0,
    });
  }

  public objectComparisonFunction(item: any, selected: any) {
    return typeof item === 'object'
      ? item.id === selected.id
      : item === selected;
  }
}
