import { ColumnTypes, TableHeaders } from 'ekiba-master-table';
import { Domains, FieldTypes } from 'src/app/constants/constants';
import { environment } from 'src/environments/environment';
import {
  EntityField,
  EntityFieldGroup,
} from '../../add-edit-entity/add-edit-entity.component';

export const TABLE_HEADERS_LISTADO_USUARIOS: TableHeaders[] = [
  {
    name: 'id',
    displayName: 'ID',
    type: ColumnTypes.Standard,
  },
  {
    name: 'nombre',
    displayName: 'Nombre',
    type: ColumnTypes.Standard,
  },
  {
    name: 'apellidos',
    displayName: 'Apellidos',
    type: ColumnTypes.Standard,
  },
  {
    name: 'username',
    displayName: 'Usuario',
    type: ColumnTypes.Standard,
  },
  {
    name: 'rolesFormatted',
    displayName: 'Roles',
    type: ColumnTypes.Standard,
  },
];

export const LISTADO_USUARIOS_BASE_URL: string = `${environment.api}${Domains.Listado}`;

export const LISTADO_USUARIOS_FIELDS: EntityField[] = [
  {
    name: 'nombre',
    displayName: 'Nombre',
    type: FieldTypes.Standard,
    required: true,
    group: 1,
  },
  {
    name: 'apellidos',
    displayName: 'Apellidos',
    type: FieldTypes.Standard,
    required: true,
    group: 1,
  },
  {
    name: 'username',
    displayName: 'Usuario',
    type: FieldTypes.Standard,
    required: true,
    group: 2,
  },
  {
    name: 'password',
    displayName: 'Contraseña',
    type: FieldTypes.Standard,
    group: 2,
  },
  {
    name: 'roles',
    displayName: 'Roles',
    type: FieldTypes.Selector,
    required: true,
    optionsUrl: `${environment.api}${Domains.Dropdown}?table=${Domains.Roles}`,
    group: 2,
  },
];

export const LISTADO_USUARIOS_GROUPS: EntityFieldGroup[] = [
  {
    label: 'Información general',
    id: 1,
  },
  {
    label: 'Información específica',
    id: 2,
  },
];
