import { ColumnTypes, TableHeaders } from 'ekiba-master-table';
import { Domains } from 'src/app/constants/constants';
import { AbstractBerria } from 'src/app/constants/models';
import { environment } from 'src/environments/environment';
import { Atributo } from '../../configuracion-de-maestros/atributos/atributos.constants';
import { Biela } from '../../configuracion-de-maestros/bielas/bielas.constants';
import { Manillar } from '../../configuracion-de-maestros/manillares/manillares.constants';
import { Potencia } from '../../configuracion-de-maestros/potencias/potencias.constants';
import { Tienda } from '../../configuracion-general/tiendas/tiendas.constants';
import { Categoria } from '../categorias/categorias.constants';
import { Variante } from '../variantes/variantes.constants';

export const TABLE_HEADERS_PRODUCTOS: TableHeaders[] = [
  {
    name: 'id',
    displayName: 'ID',
    type: ColumnTypes.Standard,
  },
  {
    name: 'sku',
    displayName: 'SKU',
    type: ColumnTypes.Standard,
  },
  {
    name: 'nombre',
    displayName: 'Nombre',
    type: ColumnTypes.Standard,
  },
  {
    name: 'activoB2b',
    displayName: 'Activo B2B',
    type: ColumnTypes.Boolean,
  },
  {
    name: 'activoB2c',
    displayName: 'Activo B2C',
    type: ColumnTypes.Boolean,
  },
  {
    name: 'familia',
    displayName: 'Familia',
    type: ColumnTypes.Standard,
  },
  {
    name: 'cuadro',
    displayName: 'Cuadro',
    type: ColumnTypes.Standard,
  },
];

export const PRODUCTOS_BASE_URL: string = `${environment.api}${Domains.Productos}`;

export interface Producto extends AbstractBerria {
  nombre: string;
  id: number;
  sku: string;
  activoB2b: boolean;
  activoB2c: boolean;
  familia: any;
  cuadro: any;
  urlkey: string;
  descripcion: string;
  imagen: string;
  productoAtributos: Atributo[];
  productoBielas: Biela[];
  productoCategorias: Categoria[];
  productoManillares: Manillar[];
  productoModelos: any[];
  productoPotencias: Potencia[];
  productoTiendas: Tienda[];
  productoVariantes: Variante[];
  pvp: string;
}
