import { ColumnTypes, TableHeaders } from 'ekiba-master-table';
import { Domains, FieldTypes } from 'src/app/constants/constants';
import { EntityField } from 'src/app/pages/add-edit-entity/add-edit-entity.component';
import { environment } from 'src/environments/environment';

export const TABLE_HEADERS_ADD_EDIT_CUADRO: TableHeaders[] = [
  {
    name: 'nombre',
    displayName: 'Servicio de pintura',
    type: ColumnTypes.Standard,
  },
  {
    name: 'precio',
    displayName: 'Incremento en precio',
    type: ColumnTypes.Standard,
  },

  {
    name: 'acciones',
    displayName: 'Acciones',
    type: ColumnTypes.Custom,
  },
];

export const CUADROS_FIELDS: EntityField[] = [
  {
    name: 'navId',
    disabled: true,
    displayName: 'ERP ID',
    type: FieldTypes.Standard,
  },
  {
    name: 'webId',
    disabled: true,
    displayName: 'Web ID',
    type: FieldTypes.Standard,
    group: 1,
  },
  {
    name: 'nombre',
    displayName: 'Nombre',
    type: FieldTypes.Translatable,
    editDisabledForDefaultTranslationEntityField: true,
  },
  {
    name: 'filler1',
    displayName: 'filler',
    type: FieldTypes.Filler,
  },
  {
    name: 'descripcion',
    displayName: 'Descripción',
    type: FieldTypes.TranslatableTextarea,
  },
];

export const GEOMETRIA_BASE_URL: string = `${environment.api}${Domains.Geometria}`;
export const TIPOS_GEOMETRIA_BASE_URL: string = `${environment.api}${Domains.TiposGeometria}`;
