import { Component, OnInit } from '@angular/core';
import { CustomAction } from 'ekiba-master-table';

import { Labels, Rutas, SKIP_AUTH } from 'src/app/constants/constants';
import { AuthService } from 'src/app/shared/auth.service';
import { Entity } from 'src/app/shared/entity';
import { PageIndexerService } from 'src/app/shared/page-indexer.service';
import {
  Direccion,
  DIRECCIONES_BASE_URL,
  TABLE_HEADERS_DIRECCIONES,
} from './direcciones.constants';

@Component({
  templateUrl: './direcciones.component.html',
})
export class DireccionesComponent extends Entity implements OnInit {
  public formattedDirecciones!: Direccion[];
  public readonly Labels: typeof Labels = Labels;
  public readonly Rutas: typeof Rutas = Rutas;
  public readonly authURL: string = `${Rutas.GestionDeClientes}/${Rutas.Direcciones}`;
  public readonly SKIP_AUTH: boolean = SKIP_AUTH;
  public readonly customActions: CustomAction[] = [
    {
      iconName: 'visibility',
      labelName: 'Detalle',
      idAction: 0,
      redirection: `${Rutas.GestionDeClientes}/${Rutas.Direcciones}/${Rutas.View}`,
      index: 'id',
    },
  ];

  constructor(
    public auth: AuthService,
    public pageIndexer: PageIndexerService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.columns = TABLE_HEADERS_DIRECCIONES;
    this._baseUrl = DIRECCIONES_BASE_URL;
    this.data$ = this.getData();
    this._loadData();
    this.data$.subscribe((data) => {
      this.formattedDirecciones = this.formatDirecciones(data);
    });
  }

  public formatDirecciones(data: Direccion[]): Direccion[] {
    return data.map((item: Direccion) => {
      return {
        ...item,
        cliente: item.cliente?.nombre,
      };
    });
  }

  public ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }
}
