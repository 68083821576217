<div class="page-container">
  <div class="page-container__header page-container__header--grey">
    <span>Configuración de colores</span>
    <span class="material-icons close-dialog-button" (click)="closeDialog()">
      close
    </span>
  </div>
  <div class="page-container__body">
    <h2>Colores de {{ data?.sdp?.servicioPintura?.nombre }}</h2>
    <div class="colores-table">
      <table class="custom-table">
        <tr>
          <th colspan="2">Color</th>
          <th>
            <mat-checkbox
              class="toggle-all-checkbox"
              color="primary"
              [ngModel]="toggleAll"
              (ngModelChange)="toggleAllColors($event, 'Fondo')"
            ></mat-checkbox>
            Fondo
          </th>
          <th>
            <mat-checkbox
              class="toggle-all-checkbox"
              color="primary"
              [ngModel]="toggleAll"
              (ngModelChange)="toggleAllColors($event, 'Color')"
            ></mat-checkbox>
            Color
          </th>
          <th>
            <mat-checkbox
              class="toggle-all-checkbox"
              color="primary"
              [ngModel]="toggleAll"
              (ngModelChange)="toggleAllColors($event, 'Calca 1')"
            ></mat-checkbox>
            Calca1
          </th>
          <th>
            <mat-checkbox
              class="toggle-all-checkbox"
              color="primary"
              [ngModel]="toggleAll"
              (ngModelChange)="toggleAllColors($event, 'Calca 2')"
            ></mat-checkbox>
            Calca2
          </th>
          <th>
            <mat-checkbox
              class="toggle-all-checkbox"
              color="primary"
              [ngModel]="toggleAll"
              (ngModelChange)="toggleAllColors($event, 'Acabado')"
            ></mat-checkbox>
            Acabado
          </th>
        </tr>
        <tr *ngFor="let color of data?.colores">
          <td style="width: 30px">
            <div
              class="color-circle"
              [class.color-is-white]="color[0].color.hexadecimal === '#FFFFFF' || color[0].color.hexadecimal === '#ffffff' || color[0].color.hexadecimal === '#FFF' || color[0].color.hexadecimal === '#fff'"
              [style.background-color]="color[0].color.hexadecimal"
            >
              <img
                *ngIf="color[0]?.color?.imagen"
                [src]="color[0]?.color?.imagen"
                class="imagen-color"
              />
            </div>
          </td>
          <td class="color-name-cell">{{color[0].color.nombre}}</td>
          <td
            class="align-center color-selection-cell"
            *ngFor="let tipo of color"
          >
            <mat-checkbox
              color="primary"
              [(ngModel)]="tipo.active"
            ></mat-checkbox>
            <mat-radio-group
              [ngModel]="tipo.default"
              (ngModelChange)="setDefault(tipo)"
              [name]="tipo.tipo"
            >
              <mat-radio-button color="primary" [value]="1"></mat-radio-button>
            </mat-radio-group>
          </td>
        </tr>
      </table>
    </div>
    <div class="leyenda">
      <mat-checkbox
        style="margin-right: 13px"
        color="primary"
        [checked]="true"
      ></mat-checkbox>
      Activar/desactivar color
      <mat-radio-group style="margin-left: 20px">
        <mat-radio-button color="primary"></mat-radio-button>
      </mat-radio-group>
      Marcar por defecto (1 por columna)
    </div>

    <button
      class="save-button"
      mat-raised-button
      color="primary"
      (click)="confirm()"
    >
      <span class="material-icons"> save </span> Guardar Configuración de
      Colores
    </button>
  </div>
</div>
