import { ColumnTypes, TableHeaders } from 'ekiba-master-table';
import { Domains, FieldTypes } from 'src/app/constants/constants';
import { AbstractBerria } from 'src/app/constants/models';
import { environment } from 'src/environments/environment';
import {
  EntityField,
  EntityFieldGroup,
} from '../../add-edit-entity/add-edit-entity.component';

export const TABLE_HEADERS_RUEDAS: TableHeaders[] = [
  {
    name: 'id',
    displayName: 'ID',
    type: ColumnTypes.Standard,
  },
  {
    name: 'codigo',
    displayName: 'Código',
    type: ColumnTypes.Standard,
  },
  {
    name: 'nombre',
    displayName: 'Nombre',
    type: ColumnTypes.Standard,
  },
  {
    name: 'descripcion',
    displayName: 'Descripción',
    type: ColumnTypes.Standard,
  },
  {
    name: 'detalles',
    displayName: 'Detalles',
    type: ColumnTypes.Standard,
  },
];

export const RUEDAS_BASE_URL: string = `${environment.api}${Domains.Ruedas}`;

export const RUEDAS_FIELDS: EntityField[] = [
  {
    name: 'codigo',
    displayName: 'Código',
    disabled: true,
    type: FieldTypes.Standard,
    group: 1,
  },
  {
    name: 'navId',
    disabled: true,
    displayName: 'ERP ID',
    type: FieldTypes.Standard,
    group: 1,
  },
  {
    name: 'webId',
    disabled: true,
    displayName: 'Web ID',
    type: FieldTypes.Standard,
    group: 1,
  },
  {
    name: 'nombre',
    displayName: 'Nombre',
    type: FieldTypes.Translatable,
    editDisabledForDefaultTranslationEntityField: true,
    group: 1,
  },
  {
    name: 'descripcion',
    displayName: 'Descripción',
    type: FieldTypes.Translatable,
    group: 1,
  },
  {
    name: 'filler1',
    displayName: 'filler',
    type: FieldTypes.Filler,
  },
  {
    name: 'filler2',
    displayName: 'filler',
    type: FieldTypes.Filler,
  },
  {
    name: 'filler3',
    displayName: 'filler',
    type: FieldTypes.Filler,
  },
  {
    name: 'detalles',
    displayName: 'Detalles',
    type: FieldTypes.Translatable,
    group: 2,
  },
  {
    name: 'imagen',
    displayName: 'Imagen',
    type: FieldTypes.Image,
    required: true,
    group: 2,
    imageUploadUrl: `${environment.api}${Domains.UploadRuedaImagen}`,
  },
];

export const RUEDAS_GROUPS: EntityFieldGroup[] = [
  {
    label: 'Información general',
    id: 1,
  },
  {
    label: 'Información específica',
    id: 2,
  },
];

export interface Rueda extends AbstractBerria {
  nombre: string;
}
