<ekiba-nav-table
  [addItemName]="'Guardar'"
  [showFilter]="false"
  (addedItem)="saveData()"
  *ngIf="action !== 'view'"
>
</ekiba-nav-table>
<div class="page-container">
  <div class="page-container__header">
    <span
      >{{ action === "add" ? "Crear" : action === "edit" ? "Editar" : "Ver" }}
      {{ label }}</span
    >
  </div>
  <div class="page-container__body">
    <form *ngIf="entityForm" [formGroup]="entityForm">
      <div class="form-group" *ngFor="let group of groups; let i = index">
        <h2>{{ group.label }}</h2>
        <div class="form-fields-container">
          <ng-container *ngFor="let field of getFieldsForGroup(group)">
            <ekiba-translatable-field
              *ngIf="field.type === fieldTypes.Translatable"
              [class.three-columns]="numberOfColumns === 3"
              [class.two-columns]="numberOfColumns === 2"
              [class.one-column]="numberOfColumns === 1"
              [class.double]="field.doubleLength"
              [class.full]="field.fullLength"
              [campo]="field.name"
              [languages]="idiomas$"
              [tabla]="translationsTable"
              [translations]="traducciones$"
              [disabled]="action === 'view' || field.disabled || false"
              [required]="field.required || false"
              [defaultValue]="entityForm.get(field.name)!.value"
              [displayName]="field.displayName"
              [touched]="touched$"
            ></ekiba-translatable-field>
            <mat-form-field
              floatLabel="always"
              *ngIf="field.type === fieldTypes.Standard"
              appearance="outline"
              [class.three-columns]="numberOfColumns === 3"
              [class.two-columns]="numberOfColumns === 2"
              [class.one-column]="numberOfColumns === 1"
              [class.double]="field.doubleLength"
              [class.full]="field.fullLength"
            >
              <mat-label
                >{{ field.displayName }}{{ field.required && "*" }}</mat-label
              >
              <input
                matInput
                [formControlName]="field.name"
                autocomplete="off"
              />
            </mat-form-field>

            <mat-form-field
              floatLabel="always"
              *ngIf="field.type === fieldTypes.Number"
              appearance="outline"
              [class.three-columns]="numberOfColumns === 3"
              [class.two-columns]="numberOfColumns === 2"
              [class.one-column]="numberOfColumns === 1"
              [class.double]="field.doubleLength"
              [class.full]="field.fullLength"
            >
              <mat-label
                >{{ field.displayName }}{{ field.required && "*" }}</mat-label
              >
              <input
                matInput
                [formControlName]="field.name"
                autocomplete="off"
                type="number"
              />
            </mat-form-field>

            <ekiba-dropdown
              *ngIf="field.type === fieldTypes.Selector"
              [selectedArray]="field.options!"
              [loadedValues]="entityForm.get(field.name)!.value"
              [multipleSelect]="!field.isSingleSelector"
              [placeholder]="
                field.isSingleSelector
                  ? 'Selecciona un elemento'
                  : 'Selecciona elementos'
              "
              [noEntriesFound]="'No se encontraron elementos'"
              [title]="
                field.required ? field.displayName + '*' : field.displayName
              "
              [class.three-columns]="numberOfColumns === 3"
              [class.two-columns]="numberOfColumns === 2"
              [class.one-column]="numberOfColumns === 1"
              [class.double]="field.doubleLength"
              [class.full]="field.fullLength"
              (changedValue)="updateField($event, field)"
              [includeEmptyOption]="true"
              floatLabel="always"
              [required]="field.required || false"
              [disabled]="action === 'view' || field.disabled || false"
              [touched]="touched$"
            >
            </ekiba-dropdown>

            <mat-checkbox
              *ngIf="field.type === fieldTypes.Boolean"
              [formControlName]="field.name"
              color="primary"
              [class.three-columns]="numberOfColumns === 3"
              [class.two-columns]="numberOfColumns === 2"
              [class.one-column]="numberOfColumns === 1"
              [class.double]="field.doubleLength"
              [disabled]="action === 'view'"
              >{{ field.displayName }}{{ field.required && "*" }}</mat-checkbox
            >
            <mat-form-field
              floatLabel="always"
              *ngIf="field.type === fieldTypes.Image"
              appearance="outline"
              [class.three-columns]="numberOfColumns === 3"
              [class.two-columns]="numberOfColumns === 2"
              [class.one-column]="numberOfColumns === 1"
              [class.double]="field.doubleLength"
              [class.full]="field.fullLength"
            >
              <mat-label>{{ field.displayName }}</mat-label>
              <input
                matInput
                type="file"
                (change)="fileChange($event, field.name)"
                [formControlName]="field.name"
              />
            </mat-form-field>
            <div
              class="mat-form-field"
              *ngIf="field.type === fieldTypes.Image"
              [class.three-columns]="numberOfColumns === 3"
              [class.two-columns]="numberOfColumns === 2"
              [class.one-column]="numberOfColumns === 1"
              [class.double]="field.doubleLength"
              [class.full]="field.fullLength"
            >
              <p>Previsualización de {{ field.displayName }}</p>
              <img
                class="image-field-preview"
                [src]="entityForm.get(field.name + '-image')?.value"
              />
            </div>
          </ng-container>

          <div
            class="filler-field"
            *ngFor="let ff of fillerFields[i]"
            [class.three-columns]="numberOfColumns === 3"
            [class.two-columns]="numberOfColumns === 2"
            [class.one-column]="numberOfColumns === 1"
          ></div>
        </div>
      </div>
    </form>

    <h2>Configuración de colores</h2>
    <div class="colores-table">
      <table class="custom-table">
        <tr>
          <th colspan="2">Color</th>
          <th>
            <mat-checkbox
              class="toggle-all-checkbox"
              color="primary"
              (change)="toggleAllColors($event, 'Fondo')"
            ></mat-checkbox>
            Fondo
          </th>
          <th>
            <mat-checkbox
              class="toggle-all-checkbox"
              color="primary"
              (change)="toggleAllColors($event, 'Color')"
            ></mat-checkbox>
            Color
          </th>
          <th>
            <mat-checkbox
              class="toggle-all-checkbox"
              color="primary"
              (change)="toggleAllColors($event, 'Calca 1')"
            ></mat-checkbox>
            Calca1
          </th>
          <th>
            <mat-checkbox
              class="toggle-all-checkbox"
              color="primary"
              (change)="toggleAllColors($event, 'Calca 2')"
            ></mat-checkbox>
            Calca2
          </th>
          <th>
            <mat-checkbox
              class="toggle-all-checkbox"
              color="primary"
              (change)="toggleAllColors($event, 'Acabado')"
            ></mat-checkbox>
            Acabado
          </th>
        </tr>
        <tr *ngFor="let color of colores">
          <td style="width: 30px">
            <div
              class="color-circle"
              [class.color-is-white]="
                color[0].color.hexadecimal === '#FFFFFF' ||
                color[0].color.hexadecimal === '#ffffff' ||
                color[0].color.hexadecimal === '#FFF' ||
                color[0].color.hexadecimal === '#fff'
              "
              [style.background-color]="color[0].color.hexadecimal"
            >
              <img
                *ngIf="color[0]?.color?.imagen"
                [src]="color[0]?.color?.imagen"
                class="imagen-color"
              />
            </div>
          </td>
          <td class="color-name-cell">{{ color[0].color.nombre }}</td>
          <td
            class="align-center color-selection-cell"
            *ngFor="let tipo of color"
          >
            <mat-checkbox
              color="primary"
              [(ngModel)]="tipo.active"
            ></mat-checkbox>
            <mat-radio-group
              [ngModel]="tipo.default"
              (ngModelChange)="setDefault(tipo)"
              [name]="tipo.tipo"
            >
              <mat-radio-button color="primary" [value]="1"></mat-radio-button>
            </mat-radio-group>
          </td>
        </tr>
      </table>
    </div>
    <div class="leyenda">
      <mat-checkbox
        style="margin-right: 13px"
        color="primary"
        [checked]="true"
      ></mat-checkbox>
      Activar/desactivar color
      <mat-radio-group style="margin-left: 20px">
        <mat-radio-button color="primary"></mat-radio-button>
      </mat-radio-group>
      Marcar por defecto (1 por columna)
    </div>
  </div>
</div>
