import { Component, Inject, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Domains } from 'src/app/constants/constants';
import { EntityApiCalls } from 'src/app/shared/entity.api-calls';
import { environment } from 'src/environments/environment';

export interface AddEditProductoManillarPopupInput {
  action: string;
  manillar: any;
}

export interface AddEditProductoManillarPopupOutput {
  manillar: any;
}

@Component({
  templateUrl: './add-edit-producto-manillar.component.html',
  styleUrls: ['./add-edit-producto-manillar.component.scss'],
})
export class AddEditProductoManillarPopup {
  manillar: any;
  manillares$: Observable<any> | undefined;
  default: boolean = false;

  public constructor(
    public dialogRef: MatDialogRef<AddEditProductoManillarPopup>,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: AddEditProductoManillarPopupInput,
    private _api: EntityApiCalls
  ) {
    this.manillares$ = this._api
      .getData(
        `${environment.api}${Domains.Dropdown}?table=${Domains.Manillares}`
      )
      .pipe(
        map((manillares: any) => {
          return manillares.map((manillar: any) => {
            return { nombre: manillar.nombre, manillarId: manillar.id };
          });
        })
      );
    this.manillar = this.data.manillar;
    this.default = this.manillar?.default;
  }

  public closeDialog(): void {
    this.dialogRef.close(false);
  }

  public confirm(): void {
    this.dialogRef.close({
      manillar: { ...this.manillar, default: this.default },
    });
  }

  public objectComparisonFunction(item: any, selected: any) {
    return typeof item === 'object'
      ? item.manillarId === selected.manillarId
      : item === selected;
  }
}
