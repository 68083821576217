import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Labels, Rutas, SKIP_AUTH } from 'src/app/constants/constants';
import { AuthService } from 'src/app/shared/auth.service';
import { Entity } from 'src/app/shared/entity';
import {
  HISTORICO_DE_SINCRONIZACION_BASE_URL,
  TABLE_HEADERS_HISTORICO_DE_SINCRONIZACION,
  HistoricoDeSincronizacion,
} from './historico-sincronizacion.constants';
@Component({
  templateUrl: './historico-sincronizacion.component.html',
})
export class HistoricoDeSincronizacionComponent
  extends Entity
  implements OnInit
{
  formattedHistoricoDeSincronizacion!: HistoricoDeSincronizacion[];
  public readonly Labels: typeof Labels = Labels;
  public readonly Rutas: typeof Rutas = Rutas;
  public readonly authURL: string = `${Rutas.Integraciones}/${Rutas.HistoricoDeSincronizacion}`;
  public SKIP_AUTH: boolean = SKIP_AUTH;

  constructor(public auth: AuthService, private _router: Router) {
    super();
  }

  public ngOnInit(): void {
    this.columns = TABLE_HEADERS_HISTORICO_DE_SINCRONIZACION;
    this._baseUrl = HISTORICO_DE_SINCRONIZACION_BASE_URL;
    this.data$ = this.getData();
    this._loadData();
    this.data$.subscribe((data) => {
      this.formattedHistoricoDeSincronizacion =
        this.formatHistoricoDeSincronizacion(data);
    });
  }

  public formatHistoricoDeSincronizacion(
    data: HistoricoDeSincronizacion[]
  ): HistoricoDeSincronizacion[] {
    return data;
  }

  public ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  public add(): void {
    this._router.navigate([
      `${Rutas.Integraciones}/${Rutas.HistoricoDeSincronizacion}/${Rutas.Add}`,
    ]);
  }

  public delete(historicoDeSincronizacion: HistoricoDeSincronizacion): void {
    this._deleteData(historicoDeSincronizacion);
  }

  public update(historicoDeSincronizacion: HistoricoDeSincronizacion): void {
    this._router.navigate([
      `${Rutas.Integraciones}/${Rutas.HistoricoDeSincronizacion}/${Rutas.Edit}/${historicoDeSincronizacion.id}`,
    ]);
  }
}
