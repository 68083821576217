import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  Domains,
  FieldTypes,
  Labels,
  Rutas,
} from 'src/app/constants/constants';
import { EntityApiCalls } from 'src/app/shared/entity.api-calls';
import {
  BIELAS_BASE_URL,
  BIELAS_FIELDS,
  BIELAS_GROUPS,
} from '../configuracion-de-maestros/bielas/bielas.constants';
import {
  CAMPANAS_BASE_URL,
  CAMPANAS_FIELDS,
  CAMPANAS_GROUPS,
} from '../configuracion-de-maestros/familias/familias.constants';
import {
  GRUPOS_DE_CAMBIO_BASE_URL,
  GRUPOS_DE_CAMBIO_FIELDS,
  GRUPOS_DE_CAMBIO_GROUPS,
} from '../configuracion-de-maestros/grupos-de-cambio/grupos-de-cambio.constants';
import {
  IDIOMAS_BASE_URL,
  IDIOMAS_FIELDS,
  IDIOMAS_GROUPS,
} from '../configuracion-general/idiomas/idiomas.constants';
import {
  COMPONENTES_BASE_URL,
  COMPONENTES_FIELDS,
  COMPONENTES_GROUPS,
} from '../configuracion-de-maestros/componentes/componentes.constants';
import {
  POTENCIAS_BASE_URL,
  POTENCIAS_FIELDS,
  POTENCIAS_GROUPS,
} from '../configuracion-de-maestros/potencias/potencias.constants';
import {
  SERVICIOS_DE_PINTURA_BASE_URL,
  SERVICIOS_DE_PINTURA_FIELDS,
  SERVICIOS_DE_PINTURA_GROUPS,
} from '../configuracion-de-maestros/servicios-de-pintura/servicios-de-pintura.constants';
import {
  TALLAS_BASE_URL,
  TALLAS_FIELDS,
  TALLAS_GROUPS,
} from '../configuracion-de-maestros/tallas/tallas.constants';
import {
  ROLES_BASE_URL,
  ROLES_FIELDS,
  ROLES_GROUPS,
} from '../gestion-de-usuarios/roles/roles.constants';
import {
  LISTADO_USUARIOS_BASE_URL,
  LISTADO_USUARIOS_FIELDS,
  LISTADO_USUARIOS_GROUPS,
} from '../gestion-de-usuarios/users-list/usuarios.constants';
import {
  CATEGORIAS_BASE_URL,
  CATEGORIAS_FIELDS,
  CATEGORIAS_GROUPS,
} from '../configuracion-de-productos/categorias/categorias.constants';
import {
  COLORES_BASE_URL,
  COLORES_FIELDS,
  COLORES_GROUPS,
} from '../configuracion-de-maestros/colores/colores.constants';
import {
  MANILLARES_BASE_URL,
  MANILLARES_FIELDS,
  MANILLARES_GROUPS,
} from '../configuracion-de-maestros/manillares/manillares.constants';
import {
  ATRIBUTOS_BASE_URL,
  ATRIBUTOS_FIELDS,
  ATRIBUTOS_GROUPS,
} from '../configuracion-de-maestros/atributos/atributos.constants';
import {
  RUEDAS_BASE_URL,
  RUEDAS_FIELDS,
  RUEDAS_GROUPS,
} from '../configuracion-de-maestros/ruedas/ruedas.constants';
import { filter, map, tap } from 'rxjs/operators';
import {
  VARIANTES_BASE_URL,
  VARIANTES_FIELDS,
  VARIANTES_GROUPS,
} from '../configuracion-de-productos/variantes/variantes.constants';
import {
  VALORES_DE_ATRIBUTOS_BASE_URL,
  VALORES_DE_ATRIBUTOS_FIELDS,
  VALORES_DE_ATRIBUTOS_GROUPS,
} from '../configuracion-de-maestros/valores-de-atributo/valores-de-atributo.constants';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import { DropdownElement } from 'ekiba-dropdown';
import { Language, Translation } from 'ekiba-translatable-field';
import { environment } from 'src/environments/environment';
import {
  TRADUCCIONES_BASE_URL,
  TRADUCCIONES_FIELDS,
  TRADUCCIONES_GROUPS,
} from '../configuracion-general/traducciones/traducciones.constants';
import {
  TAREAS_BASE_URL,
  TAREAS_FIELDS,
} from '../integraciones/tareas/tareas.constants';
import {
  TIENDAS_BASE_URL,
  TIENDAS_FIELDS,
  TIENDAS_GROUPS,
} from '../configuracion-general/tiendas/tiendas.constants';
import {
  CLIENTES_BASE_URL,
  CLIENTES_FIELDS,
  CLIENTES_GROUPS,
} from '../gestion-de-clientes/clientes/clientes.constants';
import {
  GRUPO_CLIENTESES_GROUPS,
  GRUPO_CLIENTES_BASE_URL,
  GRUPO_CLIENTES_FIELDS,
} from '../gestion-de-clientes/grupos-clientes/grupos-clientes.constants';
import {
  DIRECCIONES_BASE_URL,
  DIRECCIONES_FIELDS,
  DIRECCIONES_GROUPS,
} from '../gestion-de-clientes/direcciones/direcciones.constants';
import {
  TECNOLOGIAS_BASE_URL,
  TECNOLOGIAS_FIELDS,
  TECNOLOGIAS_GROUPS,
} from '../configuracion-de-maestros/tecnologias/tecnologias.constants';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialog } from 'src/app/popups/confirm-dialog/confirm-dialog.component';

export interface EntityField {
  disabled?: boolean;
  displayName: string;
  editDisabledForDefaultTranslationEntityField?: boolean;
  editDisabled?: boolean;
  doubleLength?: boolean;
  filterOptions?: any;
  fullLength?: boolean;
  group?: number;
  imageUploadUrl?: string;
  isSingleSelector?: boolean;
  name: string;
  options?: any[];
  optionsUrl?: string;
  required?: boolean;
  type: FieldTypes;
}

export interface EntityFieldGroup {
  label: string;
  id: number;
}

@Component({
  selector: 'app-add-edit-entity',
  templateUrl: './add-edit-entity.component.html',
  styleUrls: ['./add-edit-entity.component.scss'],
})
export class AddEditEntityComponent implements OnInit {
  public entityForm!: FormGroup;
  public fields: EntityField[] = [];
  public groups: EntityFieldGroup[] = [];
  public fillerFields: number[][] = [];
  public fieldTypes: typeof FieldTypes = FieldTypes;
  public action!: string;
  public label!: string;
  public numberOfColumns: number = 4;
  public translationsTable: string = '';
  public traducciones$: BehaviorSubject<Translation[]> = new BehaviorSubject<
    Translation[]
  >([]);
  public idiomas$: BehaviorSubject<Language[]> = new BehaviorSubject<
    Language[]
  >([]);
  public touched$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  protected formBuilt$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  protected _entityToEdit: any | undefined;
  protected _id: number | undefined;
  protected _baseUrl!: string;
  protected _returnUrl: string = '';
  protected _traducciones: Translation[] = [];

  private _parentUrl: string = '';

  constructor(
    protected readonly route: ActivatedRoute,
    protected readonly api: EntityApiCalls,
    protected readonly router: Router,
    protected readonly dialog: MatDialog
  ) {
    this.route.paramMap.subscribe((params) => {
      const split = this.router.url.split('/');
      // Get full parent (including parent's parent)
      if (params.get('id')) {
        this._returnUrl = split.slice(0, split.length - 2).join('/');
        this._parentUrl = split.slice(2, split.length - 2).join('/');
        this.action = split[split.length - 2];
        this._id = parseInt(params.get('id') as string);

        this._buildFieldsArray();
        this.api.getDataById(this._id, this._baseUrl).subscribe((entity) => {
          this._entityToEdit = entity;
          this._buildForm();
        });
      } else {
        this._returnUrl = split.slice(0, split.length - 1).join('/');
        this._parentUrl = split.slice(2, split.length - 1).join('/');
        this.action = split[split.length - 1];
        this._buildFieldsArray();

        this._buildForm();
      }
    });
  }

  public saveData(): void {
    if (this.entityForm.valid) {
      const formFieldsMap: any = {};
      this.fields.forEach((field) => {
        formFieldsMap[field.name] = this.entityForm.getRawValue()[field.name];
        if (field.type === FieldTypes.Image) {
          formFieldsMap[field.name] =
            this.entityForm.getRawValue()[field.name + '-file'] ||
            this.entityForm.getRawValue()[field.name + '-image']
              ? undefined
              : null;
          delete formFieldsMap[`${field.name}-image`];
          delete formFieldsMap[`${field.name}-file`];
        } else if (
          field.type === FieldTypes.Translatable ||
          field.type === FieldTypes.TranslatableTextarea
        ) {
          if (!field.editDisabledForDefaultTranslationEntityField) {
            formFieldsMap[field.name] =
              this._traducciones.find(
                (traduccion: Translation) =>
                  traduccion.campo === field.name &&
                  traduccion.idiomaId ===
                    this.idiomas$?.value?.find(
                      (idioma: Language) => idioma.codigo === 'es'
                    )?.id
              )?.valorVarchar || formFieldsMap[field.name];
          } else {
            formFieldsMap[field.name] =  this._entityToEdit !== undefined ? this._entityToEdit[field.name] : formFieldsMap[field.name];
          }
        } else if (field.type === FieldTypes.Boolean) {
          formFieldsMap[field.name] = formFieldsMap[field.name] ? 1 : 0;
        } else if (
          formFieldsMap[field.name] &&
          Array.isArray(formFieldsMap[field.name])
        ) {
          formFieldsMap[`${field.name}Ids`] = formFieldsMap[field.name].map(
            (f: any) => f.id
          );
          delete formFieldsMap[field.name];
        } else if (
          formFieldsMap[field.name] &&
          typeof formFieldsMap[field.name] === 'object' &&
          !Array.isArray(formFieldsMap[field.name])
        ) {
          formFieldsMap[`${field.name}Id`] = formFieldsMap[field.name].id;
          delete formFieldsMap[field.name];
        }
        /* Código comentado de debajo hace que no se envíen los campos vacíos */
        // else if (!formFieldsMap[field.name]) {
        //   delete formFieldsMap[field.name];
        // }
      });
      const data: any = {
        ...formFieldsMap,
        id: this._id,
      };

      switch (this.action) {
        case 'add':
          this.api.addData(data, this._baseUrl).subscribe((res: any) => {
            this._traducciones = this._traducciones.map(
              (traduccion: Translation) => ({
                ...traduccion,
                registroId: this._id,
              })
            );
            this.api
              .addData(
                { traducciones: this._traducciones },
                `${environment.api}${Domains.PostTraduccionesMultiple}`
              )
              .subscribe();
            const imageUploads$: Observable<any>[] = [];
            for (let field of this.fields.filter(
              (f) => f.type === this.fieldTypes.Image
            )) {
              const file = this.entityForm.getRawValue()[field.name + '-file'];
              if (file) {
                imageUploads$.push(
                  this.api.uploadImage(file, field.imageUploadUrl!, res?.id)
                );
              }
            }
            if (imageUploads$.length > 0) {
              forkJoin(imageUploads$).subscribe(() =>
                this.router.navigate([`${this._returnUrl}/${Rutas.List}`])
              );
            } else {
              this.router.navigate([`${this._returnUrl}/${Rutas.List}`]);
            }
          });
          break;
        case 'edit':
          this.api.updateData(data, this._baseUrl).subscribe(() => {
            this._traducciones = this._traducciones.map(
              (traduccion: Translation) => ({
                ...traduccion,
                registroId: this._id,
              })
            );
            this.api
              .addData(
                { traducciones: this._traducciones },
                `${environment.api}${Domains.PostTraduccionesMultiple}`
              )
              .subscribe();
            const imageUploads$: Observable<any>[] = [];
            for (let field of this.fields.filter(
              (f) => f.type === this.fieldTypes.Image
            )) {
              const file = this.entityForm.getRawValue()[field.name + '-file'];
              if (file) {
                imageUploads$.push(
                  this.api.uploadImage(file, field.imageUploadUrl!, this._id!)
                );
              }
            }
            if (imageUploads$.length > 0) {
              forkJoin(imageUploads$).subscribe(() =>
                this.router.navigate([`${this._returnUrl}/${Rutas.List}`])
              );
            } else {
              this.router.navigate([`${this._returnUrl}/${Rutas.List}`]);
            }
          });
          break;
      }
    } else {
      this.entityForm.markAllAsTouched();
      this.touched$.next(true);
    }
  }

  public ngOnInit(): void {}

  public fileChange(event: any, fieldName: string) {
    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      const file: File = fileList[0];
      this.entityForm.patchValue({ [fieldName + '-file']: file });
      const reader = new FileReader();
      reader.onload = (() => {
        return (e: any) => {
          this.entityForm.patchValue({
            [fieldName + '-image']: e.target.result,
          });
        };
      })();
      reader.readAsDataURL(file);
    }
  }

  public updateField(
    dropdownElement: DropdownElement | DropdownElement[],
    field: EntityField
  ): void {
    this.entityForm.controls[field.name].setValue(dropdownElement);
  }

  public getFieldsForGroup({ id }: EntityFieldGroup) {
    return this.fields.filter((field) => field.group === id);
  }

  public deleteImage(fieldName: string) {
    const dialogRef = this.dialog.open(ConfirmDialog, {
      data: {
        texto: '¿Está seguro de que desea eliminar la imagen?',
      },
    });
    dialogRef
      .afterClosed()
      .pipe(
        filter((res: boolean) => !!res),
        tap(() => {
          if (this.entityForm.controls[fieldName + '-image'])
            this.entityForm.controls[fieldName + '-image'].setValue(null);
          if (this.entityForm.controls[fieldName + '-field'])
            this.entityForm.controls[fieldName + '-field'].setValue(null);
        })
      )
      .subscribe();
  }

  protected _buildFieldsArray(): void {
    switch (this._parentUrl) {
      case Rutas.Atributos:
        this.fields = ATRIBUTOS_FIELDS;
        this._baseUrl = ATRIBUTOS_BASE_URL;
        this.groups = ATRIBUTOS_GROUPS;
        this.label = Labels.Atributos;
        this.translationsTable = Domains.Atributos;
        break;
      case Rutas.Bielas:
        this.fields = BIELAS_FIELDS;
        this._baseUrl = BIELAS_BASE_URL;
        this.groups = BIELAS_GROUPS;
        this.label = Labels.Bielas;
        this.translationsTable = Domains.Bielas;
        break;
      case Rutas.Categorias:
        this.fields = CATEGORIAS_FIELDS;
        this._baseUrl = CATEGORIAS_BASE_URL;
        this.groups = CATEGORIAS_GROUPS;
        this.label = Labels.Categorias;
        this.translationsTable = Domains.Categorias;
        break;
      case Rutas.Colores:
        this.fields = COLORES_FIELDS;
        this._baseUrl = COLORES_BASE_URL;
        this.groups = COLORES_GROUPS;
        this.label = Labels.Colores;
        this.translationsTable = Domains.Colores;
        break;
      case Rutas.Componentes:
        this.fields = COMPONENTES_FIELDS;
        this._baseUrl = COMPONENTES_BASE_URL;
        this.groups = COMPONENTES_GROUPS;
        this.label = Labels.Componentes;
        this.translationsTable = Domains.Componentes;
        break;
      case Rutas.Familias:
        this.fields = CAMPANAS_FIELDS;
        this._baseUrl = CAMPANAS_BASE_URL;
        this.groups = CAMPANAS_GROUPS;
        this.label = Labels.Familias;
        this.translationsTable = Domains.Familias;
        break;
      case Rutas.GruposDeCambio:
        this.fields = GRUPOS_DE_CAMBIO_FIELDS;
        this._baseUrl = GRUPOS_DE_CAMBIO_BASE_URL;
        this.groups = GRUPOS_DE_CAMBIO_GROUPS;
        this.label = Labels.GruposDeCambio;
        this.translationsTable = Domains.GruposDeCambio;
        break;
      case Rutas.Idiomas:
        this.fields = IDIOMAS_FIELDS;
        this._baseUrl = IDIOMAS_BASE_URL;
        this.groups = IDIOMAS_GROUPS;
        this.label = Labels.Idiomas;
        this.translationsTable = Domains.Idiomas;
        break;
      case Rutas.Listado:
        this.fields = LISTADO_USUARIOS_FIELDS;
        this._baseUrl = LISTADO_USUARIOS_BASE_URL;
        this.groups = LISTADO_USUARIOS_GROUPS;
        this.label = Labels.Listado;
        this.translationsTable = Domains.Listado;
        break;
      case Rutas.Manillares:
        this.fields = MANILLARES_FIELDS;
        this._baseUrl = MANILLARES_BASE_URL;
        this.groups = MANILLARES_GROUPS;
        this.label = Labels.Manillares;
        this.translationsTable = Domains.Manillares;
        break;
      case Rutas.Potencias:
        this.fields = POTENCIAS_FIELDS;
        this._baseUrl = POTENCIAS_BASE_URL;
        this.groups = POTENCIAS_GROUPS;
        this.label = Labels.Potencias;
        this.translationsTable = Domains.Potencias;
        break;
      case Rutas.Roles:
        this.fields = ROLES_FIELDS;
        this._baseUrl = ROLES_BASE_URL;
        this.groups = ROLES_GROUPS;
        this.label = Labels.Roles;
        this.translationsTable = Domains.Roles;
        break;
      case Rutas.Ruedas:
        this.fields = RUEDAS_FIELDS;
        this._baseUrl = RUEDAS_BASE_URL;
        this.groups = RUEDAS_GROUPS;
        this.label = Labels.Ruedas;
        this.translationsTable = Domains.Ruedas;
        break;
      case Rutas.ServiciosDePintura:
        this.fields = SERVICIOS_DE_PINTURA_FIELDS;
        this._baseUrl = SERVICIOS_DE_PINTURA_BASE_URL;
        this.groups = SERVICIOS_DE_PINTURA_GROUPS;
        this.label = Labels.ServiciosDePintura;
        this.translationsTable = Domains.ServiciosDePintura;
        break;
      case Rutas.Tallas:
        this.fields = TALLAS_FIELDS;
        this._baseUrl = TALLAS_BASE_URL;
        this.groups = TALLAS_GROUPS;
        this.label = Labels.Tallas;
        this.translationsTable = Domains.Tallas;
        break;
      case Rutas.Tareas:
        this.fields = TAREAS_FIELDS;
        this._baseUrl = TAREAS_BASE_URL;
        this.label = Labels.Tareas;
        this.translationsTable = Domains.Tareas;
        break;
      case Rutas.Tiendas:
        this.fields = TIENDAS_FIELDS;
        this._baseUrl = TIENDAS_BASE_URL;
        this.groups = TIENDAS_GROUPS;
        this.label = Labels.Tiendas;
        this.translationsTable = Domains.Tiendas;
        break;
      case Rutas.Traducciones:
        this.fields = TRADUCCIONES_FIELDS;
        this._baseUrl = TRADUCCIONES_BASE_URL;
        this.groups = TRADUCCIONES_GROUPS;
        this.label = Labels.Traducciones;
        this.translationsTable = Domains.Traducciones;
        break;
      case Rutas.ValoresDeAtributo:
        this.fields = VALORES_DE_ATRIBUTOS_FIELDS;
        this._baseUrl = VALORES_DE_ATRIBUTOS_BASE_URL;
        this.groups = VALORES_DE_ATRIBUTOS_GROUPS;
        this.label = Labels.ValoresDeAtributo;
        this.translationsTable = Domains.AtributoOpciones;
        break;
      case Rutas.Variantes:
        this.fields = VARIANTES_FIELDS;
        this._baseUrl = VARIANTES_BASE_URL;
        this.groups = VARIANTES_GROUPS;
        this.label = Labels.Variantes;
        this.translationsTable = Domains.Variantes;
        break;
      case Rutas.Clientes:
        this.fields = CLIENTES_FIELDS;
        this._baseUrl = CLIENTES_BASE_URL;
        this.groups = CLIENTES_GROUPS;
        this.label = Labels.Clientes;
        this.translationsTable = Domains.Clientes;
        break;
      case Rutas.GrupoClientes:
        this.fields = GRUPO_CLIENTES_FIELDS;
        this._baseUrl = GRUPO_CLIENTES_BASE_URL;
        this.groups = GRUPO_CLIENTESES_GROUPS;
        this.label = Labels.GrupoClientes;
        this.translationsTable = Domains.GrupoClientes;
        break;
      case Rutas.Direcciones:
        this.fields = DIRECCIONES_FIELDS;
        this._baseUrl = DIRECCIONES_BASE_URL;
        this.groups = DIRECCIONES_GROUPS;
        this.label = Labels.Direcciones;
        this.translationsTable = Domains.Direcciones;
        break;
      case Rutas.Tecnologias:
        this.fields = TECNOLOGIAS_FIELDS;
        this._baseUrl = TECNOLOGIAS_BASE_URL;
        this.groups = TECNOLOGIAS_GROUPS;
        this.label = Labels.Tecnologias;
        this.translationsTable = Domains.Tecnologias;
        break;
    }
    let translationsLoaded: boolean = false;
    this.fields.forEach((field) => {
      if (field.optionsUrl) {
        this.api
          .getData(field.optionsUrl)
          .pipe(
            map(field.filterOptions || ((item) => item)),
            tap((res: any) => {
              field.options = res;
            })
          )
          .subscribe();
      }
      if (
        field.type === FieldTypes.Translatable ||
        (field.type === FieldTypes.TranslatableTextarea && !translationsLoaded)
      ) {
        this._loadTraducciones();
        translationsLoaded = true;
      }
    });
    for (let j = 0; j < this.groups.length; j++) {
      this.fillerFields[j] = [];
      const group = this.groups[j];
      const numberOfFieldsInGroup = this.fields.filter(
        (field) => field.group === group.id
      ).length;
      for (let i = 4; i > numberOfFieldsInGroup % 4; i--) {
        this.fillerFields[j].push(i);
      }
    }
  }

  private _buildForm(): void {
    const formFields: any[] = [];
    this.fields.forEach((field) => {
      if (field.type === FieldTypes.Image) {
        formFields.push({
          name: field.name + '-file',
          control: new FormControl(
            {
              value: '',
              disabled:
                this.action === 'view' ||
                field.disabled ||
                (this.action === 'edit' && field.editDisabled) ||
                false,
            },
            field.required &&
            (this._entityToEdit ? !this._entityToEdit[field.name] : true)
              ? [Validators.required]
              : []
          ),
        });
        formFields.push({
          name: field.name + '-image',
          control: new FormControl(
            {
              value: this._entityToEdit && this._entityToEdit[field.name],
              disabled:
                this.action === 'view' ||
                field.disabled ||
                (this.action === 'edit' && field.editDisabled) ||
                false,
            },
            field.required &&
            (this._entityToEdit ? !this._entityToEdit[field.name] : true)
              ? [Validators.required]
              : []
          ),
        });
        formFields.push({
          name: field.name,
          control: new FormControl(
            {
              value: '',
              disabled:
                this.action === 'view' ||
                field.disabled ||
                (this.action === 'edit' && field.editDisabled) ||
                false,
            },
            field.required &&
            (this._entityToEdit ? !this._entityToEdit[field.name] : true)
              ? [Validators.required]
              : []
          ),
        });
      } else {
        formFields.push({
          name: field.name,
          control: new FormControl(
            {
              value: this._entityToEdit && this._entityToEdit[field.name],
              disabled:
                this.action === 'view' ||
                field.disabled ||
                (this.action === 'edit' && field.editDisabled) ||
                false,
            },
            field.required ? [Validators.required] : []
          ),
        });
      }
    });
    this.entityForm = new FormGroup({});
    formFields.forEach((formField) => {
      this.entityForm.addControl(formField.name, formField.control);
    });
    //this.entityForm.patchValue(this._entityToEdit);
    this.formBuilt$.next(true);
  }

  protected _loadTraducciones() {
    this.traducciones$
      ?.pipe(
        tap((traducciones: Translation[]) => {
          this._traducciones = traducciones;
          const traduccionesSpanish = traducciones.filter(
            (traduccion: Translation) =>
              traduccion.idiomaId ===
              this.idiomas$?.value?.find(
                (idioma: Language) => idioma.codigo === 'es'
              )?.id
          );
          this.formBuilt$
            .pipe(
              filter((value) => value),
              tap(() => {
                traduccionesSpanish.forEach((traduccion: Translation) => {
                  this.entityForm.patchValue({
                    [traduccion.campo]: traduccion.valorVarchar,
                  });
                });
              })
            )
            .subscribe();
        })
      )
      .subscribe();
    if (this._id) {
      this.api
        .getData(
          `${environment.api}${Domains.TraduccionesShared}?table=${this.translationsTable}&registerId=${this._id}`
        )
        .subscribe((res) => this.traducciones$.next(res));
    }
    this.api
      .getData(`${environment.api}${Domains.IdiomasShared}`)
      .subscribe((res) => this.idiomas$.next(res));
  }
}
