import { Component, OnInit } from '@angular/core';

@Component({
  templateUrl: './pagina-no-encontrada.component.html',
  styleUrls: ['./pagina-no-encontrada.component.scss'],
})
export class PaginaNoEncontradaComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
