<div class="page-container">
  <div class="page-container__header">
    <span>{{ Labels.Colores }}</span>
  </div>
  <div class="page-container__body">
    <ekiba-master-table
      *ngIf="data$ | async as data"
      [dataSource]="formattedColores"
      [showFilters]="true"
      [showDeleteButton]="false"
      [showEditButton]="false"
      [columns]="columns"
      [redirection]="
        SKIP_AUTH || auth.hasPermissionTo(authURL, Rutas.Edit) ? editUrl : ''
      "
      [redirectionType]="RedirectionType.Edit"
      [pageIndex]="pageIndexer.getIndex(Labels.Colores)"
      (changedPage)="pageIndexer.setIndex(Labels.Colores, $event.pageIndex)"
    >
    </ekiba-master-table>
  </div>
</div>
