import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { throwError } from 'rxjs/internal/observable/throwError';
import { catchError, tap } from 'rxjs/operators';
import { Domains, MAX_LENGTH } from 'src/app/constants/constants';
import { AuthService } from 'src/app/shared/auth.service';
import { environment } from 'src/environments/environment';
import { LoggedSubject, LoginInput } from './login.models';
import { LoginService } from './login.service';

@Component({
  selector: 'ekiba-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public loginForm!: FormGroup;
  public errorMesssage: string = '';
  public defaultErrorMesssage: boolean = false;
  public hidePassword: boolean = true;

  constructor(
    private _authService: AuthService,
    private _loginService: LoginService,
    private _router: Router
  ) {}

  ngOnInit(): void {
    if (this._authService.getToken()) {
      const loginDetails: LoggedSubject = this._authService.getLoginDetails();
      this._loginService.logged$.next(loginDetails);
      this.getAuth(loginDetails.berriaGestorDeProductosToken);
    } else {
      this.buildForm();
    }
  }

  public buildForm(): void {
    this.loginForm = new FormGroup({
      username: new FormControl('', [
        Validators.maxLength(MAX_LENGTH),
        Validators.required,
      ]),
      password: new FormControl('', [
        Validators.maxLength(MAX_LENGTH),
        Validators.required,
      ]),
      rememberMe: new FormControl(false, []),
    });

    const rememberedCredentials: LoginInput =
      this._authService.getCredentials();
    if (rememberedCredentials) {
      this.loginForm.patchValue(rememberedCredentials);
    }
  }

  public login(): void {
    const { username, password, rememberMe } = this.loginForm.value;
    const loginInput: LoginInput = {
      username,
      password,
    };
    this._loginService
      .login(
        loginInput,
        `${environment.api}${Domains.Autenticacion}/${Domains.Login}`
      )
      .pipe(
        tap((res) => {
          const loginDetails: LoggedSubject = {
            logged: true,
            berriaGestorDeProductosToken: res.accessToken,
            user: res.usuario,
          };
          if (rememberMe) {
            this._authService.setCredentials(loginInput);
          }
          this._loginService.logged$.next(loginDetails);
          this._authService.setLoginDetails(loginDetails);
          this.getAuth(res.accessToken);
        }),
        catchError((error: any) => {
          if (error.errorMessage) {
            this.errorMesssage = error.errorMesssage;
          } else {
            this.defaultErrorMesssage = true;
          }
          return throwError(error);
        })
      )
      .subscribe();
  }

  getAuth(accessToken: string) {
    this._authService
      .getAuth(
        accessToken,
        `${environment.api}${Domains.Autenticacion}/${Domains.Auth}`
      )
      .pipe(
        tap((auth) => {
          this._authService.authPermissions$.next(auth.permissions);
          this._router.navigate(['inicio']);
        })
      )
      .subscribe(
        () => {},
        () => {
          this.buildForm();
        }
      );
  }
}
