<ekiba-nav-table
  [addItemName]="'Guardar'"
  [showFilter]="false"
  (addedItem)="saveData()"
  *ngIf="action !== 'view'"
>
</ekiba-nav-table>
<div class="page-container">
  <div class="page-container__header">
    <span
      >{{ action === "add" ? "Crear" : action === "edit" ? "Editar" : "Ver" }}
      {{ label }}</span
    >
  </div>
  <div class="page-container__body">
    <form *ngIf="entityForm" [formGroup]="entityForm">
      <mat-tab-group>
        <mat-tab label="Ficha">
          <div class="form-group" *ngFor="let group of groups; let i = index">
            <h2>{{ group.label }}</h2>
            <div class="form-fields-container">
              <ng-container *ngFor="let field of getFieldsForGroup(group)">
                <ekiba-translatable-field
                  *ngIf="field.type === fieldTypes.Translatable"
                  [class.three-columns]="numberOfColumns === 3"
                  [class.two-columns]="numberOfColumns === 2"
                  [class.one-column]="numberOfColumns === 1"
                  [class.double]="field.doubleLength"
                  [class.full]="field.fullLength"
                  [campo]="field.name"
                  [languages]="idiomas$"
                  [tabla]="translationsTable"
                  [translations]="traducciones$"
                  [disabled]="action === 'view' || field.disabled || false"
                  [required]="field.required || false"
                  [defaultValue]="entityForm.get(field.name)!.value"
                  [displayName]="field.displayName"
                  [touched]="touched$"
                ></ekiba-translatable-field>
                <mat-form-field
                  floatLabel="always"
                  *ngIf="field.type === fieldTypes.Standard"
                  appearance="outline"
                  [class.three-columns]="numberOfColumns === 3"
                  [class.two-columns]="numberOfColumns === 2"
                  [class.one-column]="numberOfColumns === 1"
                  [class.double]="field.doubleLength"
                  [class.full]="field.fullLength"
                >
                  <mat-label
                    >{{ field.displayName
                    }}{{ field.required && "*" }}</mat-label
                  >
                  <input
                    matInput
                    [formControlName]="field.name"
                    autocomplete="off"
                  />
                </mat-form-field>

                <mat-form-field
                  floatLabel="always"
                  *ngIf="field.type === fieldTypes.Number"
                  appearance="outline"
                  [class.three-columns]="numberOfColumns === 3"
                  [class.two-columns]="numberOfColumns === 2"
                  [class.one-column]="numberOfColumns === 1"
                  [class.double]="field.doubleLength"
                  [class.full]="field.fullLength"
                >
                  <mat-label
                    >{{ field.displayName
                    }}{{ field.required && "*" }}</mat-label
                  >
                  <input
                    matInput
                    [formControlName]="field.name"
                    autocomplete="off"
                    type="number"
                  />
                </mat-form-field>

                <ekiba-dropdown
                  *ngIf="field.type === fieldTypes.Selector"
                  [selectedArray]="field.options!"
                  [loadedValues]="entityForm.get(field.name)!.value"
                  [multipleSelect]="!field.isSingleSelector"
                  [placeholder]="
                    field.isSingleSelector
                      ? 'Selecciona un elemento'
                      : 'Selecciona elementos'
                  "
                  [noEntriesFound]="'No se encontraron elementos'"
                  [title]="
                    field.required ? field.displayName + '*' : field.displayName
                  "
                  [class.three-columns]="numberOfColumns === 3"
                  [class.two-columns]="numberOfColumns === 2"
                  [class.one-column]="numberOfColumns === 1"
                  [class.double]="field.doubleLength"
                  [class.full]="field.fullLength"
                  (changedValue)="updateField($event, field)"
                  [includeEmptyOption]="true"
                  floatLabel="always"
                  [required]="field.required || false"
                  [disabled]="action === 'view' || field.disabled || false"
                  [touched]="touched$"
                >
                </ekiba-dropdown>

                <mat-checkbox
                  *ngIf="field.type === fieldTypes.Boolean"
                  [formControlName]="field.name"
                  color="primary"
                  [class.three-columns]="numberOfColumns === 3"
                  [class.two-columns]="numberOfColumns === 2"
                  [class.one-column]="numberOfColumns === 1"
                  [class.double]="field.doubleLength"
                  [disabled]="action === 'view'"
                  >{{ field.displayName
                  }}{{ field.required && "*" }}</mat-checkbox
                >
                <mat-form-field
                  floatLabel="always"
                  *ngIf="field.type === fieldTypes.Image"
                  appearance="outline"
                  [class.three-columns]="numberOfColumns === 3"
                  [class.two-columns]="numberOfColumns === 2"
                  [class.one-column]="numberOfColumns === 1"
                  [class.double]="field.doubleLength"
                  [class.full]="field.fullLength"
                >
                  <mat-label>{{ field.displayName }}</mat-label>
                  <input
                    matInput
                    type="file"
                    (change)="fileChange($event, field.name)"
                    [formControlName]="field.name"
                  />
                </mat-form-field>
                <div
                  class="mat-form-field"
                  *ngIf="field.type === fieldTypes.Image"
                  [class.three-columns]="numberOfColumns === 3"
                  [class.two-columns]="numberOfColumns === 2"
                  [class.one-column]="numberOfColumns === 1"
                  [class.double]="field.doubleLength"
                  [class.full]="field.fullLength"
                >
                  <p>Previsualización de {{ field.displayName }}</p>
                  <img
                    class="image-field-preview"
                    [src]="entityForm.get(field.name + '-image')?.value"
                  />
                </div>
              </ng-container>

              <div
                class="filler-field"
                *ngFor="let ff of fillerFields[i]"
                [class.three-columns]="numberOfColumns === 3"
                [class.two-columns]="numberOfColumns === 2"
                [class.one-column]="numberOfColumns === 1"
              ></div>
            </div>
          </div>

          <div class="disponibilidad-container">
            <div class="table-container">
              <h2 class="no-margin-bottom">Disponibilidad</h2>
              <table
                style="margin-bottom: 20px"
                class="small-table custom-table"
                *ngIf="disponibilidades$ | async as disponibilidades"
              >
                <tr>
                  <th class="align-left">Producto</th>
                  <th class="align-left">Modelo</th>
                  <th class="align-left">Variante</th>
                  <th class="align-center">Talla</th>
                  <th class="align-center">Colores</th>
                  <th class="align-right">Stock</th>
                </tr>
                <tr *ngIf="!disponibilidades?.length">
                  <td colspan="3" class="align-left">
                    No hay disponibilidad en esta variante
                  </td>
                </tr>
                <tr *ngFor="let disponibilidad of disponibilidades">
                  <td class="align-left">
                    {{ disponibilidad?.producto?.nombre }}
                  </td>
                  <td class="align-left">
                    {{ disponibilidad?.modelo?.nombre }}
                  </td>
                  <td class="align-left">
                    {{ disponibilidad?.variante?.nombre }}
                  </td>
                  <td class="align-center">
                    {{ disponibilidad?.variante?.talla }}
                  </td>
                  <td class="align-center">
                    <div
                      class="color-circle-producto"
                      *ngFor="
                        let color of [
                          disponibilidad?.colorFondo,
                          disponibilidad?.colorCalca1,
                          disponibilidad?.colorCalca2,
                          disponibilidad?.colorColor,
                          disponibilidad?.colorAcabado
                        ]
                      "
                      [matTooltip]="
                        color?.codigo
                          ? color?.codigo + ': ' + color?.nombre
                          : ''
                      "
                    >
                      <div
                        class="color-circle"
                        [class.color-is-white]="
                          color?.hexadecimal === '#FFFFFF' ||
                          color?.hexadecimal === '#ffffff' ||
                          color?.hexadecimal === '#FFF' ||
                          color?.hexadecimal === '#fff'
                        "
                        [style.background-color]="color?.hexadecimal"
                      >
                        <img
                          *ngIf="color?.imagen"
                          [src]="color?.imagen"
                          class="imagen-color"
                        />
                      </div>
                      <span class="color-circle-producto__tipo-text">{{
                        color?.tipo
                      }}</span>
                    </div>
                  </td>
                  <td class="align-right">{{ disponibilidad?.cantidad }}</td>
                </tr>
              </table>
            </div>
          </div>

          <div class="plan-de-produccion-container">
            <div class="table-container">
              <h2 class="no-margin-bottom">Plan de Producción</h2>
              <table
                style="margin-bottom: 20px"
                class="small-table custom-table"
                *ngIf="planDeProduccion$ | async as planesDeProduccion"
              >
                <tr>
                  <th class="align-left">Producto</th>
                  <th class="align-left">Modelo</th>
                  <th class="align-left">Variante</th>
                  <th class="align-left">Fecha</th>
                  <th class="align-center">Talla</th>
                  <th class="align-center">Colores</th>
                  <th class="align-right">Cantidad</th>
                </tr>
                <tr *ngIf="!planesDeProduccion?.length">
                  <td colspan="3" class="align-left">
                    No hay plan de producción en esta variante
                  </td>
                </tr>
                <tr *ngFor="let planDeProduccion of planesDeProduccion">
                  <td class="align-left">
                    {{ planDeProduccion?.producto?.nombre }}
                  </td>
                  <td class="align-left">
                    {{ planDeProduccion?.modelo?.nombre }}
                  </td>
                  <td class="align-left">
                    {{ planDeProduccion?.variante?.nombre }}
                  </td>
                  <td class="align-left">
                    {{ planDeProduccion?.fecha | date: "dd/MM/yyyy" }}
                  </td>
                  <td class="align-center">
                    {{ planDeProduccion?.variante?.talla }}
                  </td>
                  <td class="align-center">
                    <div
                      class="color-circle-producto"
                      *ngFor="
                        let color of [
                          planDeProduccion?.colorFondo,
                          planDeProduccion?.colorCalca1,
                          planDeProduccion?.colorCalca2,
                          planDeProduccion?.colorColor,
                          planDeProduccion?.colorAcabado
                        ]
                      "
                      [matTooltip]="
                        color?.codigo
                          ? color?.codigo + ': ' + color?.nombre
                          : ''
                      "
                    >
                      <div
                        class="color-circle"
                        [class.color-is-white]="
                          color?.hexadecimal === '#FFFFFF' ||
                          color?.hexadecimal === '#ffffff' ||
                          color?.hexadecimal === '#FFF' ||
                          color?.hexadecimal === '#fff'
                        "
                        [style.background-color]="color?.hexadecimal"
                      >
                        <img
                          *ngIf="color?.imagen"
                          [src]="color?.imagen"
                          class="imagen-color"
                        />
                      </div>
                      <span class="color-circle-producto__tipo-text">{{
                        color?.tipo
                      }}</span>
                    </div>
                  </td>
                  <td class="align-right">
                    {{ planDeProduccion?.cantidad }}
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </mat-tab>

        <mat-tab label="Precios"> Precios </mat-tab>
      </mat-tab-group>
    </form>
  </div>
</div>
