import { ColumnTypes, TableHeaders } from 'ekiba-master-table';
import { Domains, FieldTypes } from 'src/app/constants/constants';
import { AbstractBerria } from 'src/app/constants/models';
import { environment } from 'src/environments/environment';
import {
  EntityField,
  EntityFieldGroup,
} from '../../add-edit-entity/add-edit-entity.component';

export const TABLE_HEADERS_COLORES: TableHeaders[] = [
  {
    name: 'id',
    displayName: 'ID',
    type: ColumnTypes.Standard,
  },
  {
    name: 'codigo',
    displayName: 'Código',
    type: ColumnTypes.Standard,
  },
  {
    name: 'nombre',
    displayName: 'Nombre',
    type: ColumnTypes.Standard,
  },
  {
    name: 'bloqueado',
    displayName: 'Bloqueado',
    type: ColumnTypes.Boolean,
  },
  {
    name: 'imagen',
    displayName: 'Imagen',
    type: ColumnTypes.Image,
  },
  {
    name: 'hexadecimal',
    displayName: 'Código Hex.',
    type: ColumnTypes.Standard,
  },
  {
    name: 'precio',
    displayName: 'Precio',
    type: ColumnTypes.Standard,
  },
];

export const COLORES_BASE_URL: string = `${environment.api}${Domains.Colores}`;

export const COLORES_FIELDS: EntityField[] = [
  {
    name: 'codigo',
    displayName: 'Código',
    type: FieldTypes.Standard,
    required: true,
    editDisabled: true,
    group: 1,
  },
  {
    name: 'nombre',
    displayName: 'Nombre',
    type: FieldTypes.Translatable,
    editDisabledForDefaultTranslationEntityField: true,
    group: 1,
  },
  {
    name: 'descripcion',
    displayName: 'Descripción',
    type: FieldTypes.Translatable,
    group: 1,
  },
  {
    name: 'hexadecimal',
    displayName: 'Código Hex.',
    type: FieldTypes.Standard,
    group: 2,
  },
  {
    name: 'imagen',
    displayName: 'Imagen',
    type: FieldTypes.Image,
    group: 2,
    imageUploadUrl: `${environment.api}${Domains.UploadColorImagen}`,
  },
  {
    name: 'precio',
    displayName: 'Precio',
    type: FieldTypes.Standard,
    group: 2,
  },
  {
    name: 'bloqueado',
    displayName: 'Bloqueado',
    type: FieldTypes.Boolean,
    disabled: true,
    group: 2,
  },
];

export const COLORES_GROUPS: EntityFieldGroup[] = [
  {
    label: 'Información general',
    id: 1,
  },
  {
    label: 'Información específica',
    id: 2,
  },
];

export interface Color extends AbstractBerria {
  codigo: string;
  hexadecimal: string;
  imagen: string;
  nombre: string;
  descripcion?: string;
  bloqueado: boolean;
}
