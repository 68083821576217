import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { DropdownElement } from 'ekiba-dropdown';
import { TableHeaders } from 'ekiba-master-table';
import { Language, Translation } from 'ekiba-translatable-field';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import {
  Domains,
  FieldTypes,
  Labels,
  Rutas,
} from 'src/app/constants/constants';
import { EntityField } from 'src/app/pages/add-edit-entity/add-edit-entity.component';
import { AddEditCuadroServicioDePinturaPopup } from 'src/app/popups/add-edit-cuadro-servicio-de-pintura/add-edit-cuadro-servicio-de-pintura.component';
import {
  ConfiguracionDeColoresDialog,
  TiposColor,
} from 'src/app/popups/configuracion-de-colores/configuracion-de-colores.dialog';
import { ConfirmDialog } from 'src/app/popups/confirm-dialog/confirm-dialog.component';
import { EntityApiCalls } from 'src/app/shared/entity.api-calls';
import { environment } from 'src/environments/environment';
import { Color, COLORES_BASE_URL } from '../../colores/colores.constants';
import { SERVICIOS_DE_PINTURA_BASE_URL } from '../../servicios-de-pintura/servicios-de-pintura.constants';
import { TALLAS_BASE_URL } from '../../tallas/tallas.constants';
import {
  CUADROS_FIELDS,
  GEOMETRIA_BASE_URL,
  TABLE_HEADERS_ADD_EDIT_CUADRO,
  TIPOS_GEOMETRIA_BASE_URL,
} from './add-edit-cuadro.constants';

@Component({
  selector: 'ekiba-add-edit-cuadro',
  templateUrl: './add-edit-cuadro.component.html',
  styleUrls: ['./add-edit-cuadro.component.scss'],
})
export class AddEditCuadroComponent implements OnInit {
  public entityForm!: FormGroup;
  public fields: EntityField[] = [];
  public fieldTypes: typeof FieldTypes = FieldTypes;
  public action!: string;
  public labels = Labels;
  public serviciosDePintura$: BehaviorSubject<any> = new BehaviorSubject([]);
  public entityToEdit: any | undefined;
  public columns: TableHeaders[] = TABLE_HEADERS_ADD_EDIT_CUADRO;
  public geometrias$: BehaviorSubject<any> = new BehaviorSubject([]);
  public geometriasHeaders: string[] = [];
  public ergonomias$: BehaviorSubject<any> = new BehaviorSubject([]);
  public ergonomiasHeaders: string[] = [];
  public traducciones$: BehaviorSubject<Translation[]> = new BehaviorSubject<
    Translation[]
  >([]);
  public idiomas$: BehaviorSubject<Language[]> = new BehaviorSubject<
    Language[]
  >([]);
  public touched$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  private _id: number | undefined;
  private _baseUrl!: string;
  private _coloresOriginal: any[] = [];
  private _traducciones: Translation[] = [];

  constructor(
    private _api: EntityApiCalls,
    private _dialog: MatDialog,
    private _route: ActivatedRoute,
    private _router: Router
  ) {}

  public ngOnInit(): void {
    this._baseUrl = `${environment.api}${Domains.Cuadros}`;
    this.fields = CUADROS_FIELDS;
    this._route.paramMap.subscribe((params: any) => {
      const split = this._router.url.split('/');
      if (params.get('id')) {
        this.action = split[split.length - 2];
        this._id = parseInt(params.get('id') as string);
      } else {
        this.action = split[split.length - 1];
      }
      this._loadData();
    });
  }

  public saveData() {
    if (this.entityForm.valid) {
      const formFieldsMap: any = {};
      this.fields.forEach((field) => {
        formFieldsMap[field.name] = this.entityForm.getRawValue()[field.name];
        // Send only ids for array and object fields
        if (
          field.type === FieldTypes.Translatable ||
          field.type === FieldTypes.TranslatableTextarea
        ) {
          if (!field.editDisabledForDefaultTranslationEntityField) {
            formFieldsMap[field.name] =
              this._traducciones.find(
                (traduccion: Translation) =>
                  traduccion.campo === field.name &&
                  traduccion.idiomaId ===
                    this.idiomas$?.value?.find(
                      (idioma: Language) => idioma.codigo === 'es'
                    )?.id
              )?.valorVarchar || formFieldsMap[field.name];
          } else {
            formFieldsMap[field.name] = this.entityToEdit[field.name];
          }
        } else if (field.type === FieldTypes.Boolean) {
          formFieldsMap[field.name] = formFieldsMap[field.name] ? 1 : 0;
        } else if (
          formFieldsMap[field.name] &&
          Array.isArray(formFieldsMap[field.name])
        ) {
          formFieldsMap[`${field.name}Ids`] = formFieldsMap[field.name].map(
            (f: any) => f.id
          );
          delete formFieldsMap[field.name];
        } else if (
          formFieldsMap[field.name] &&
          typeof formFieldsMap[field.name] === 'object' &&
          !Array.isArray(formFieldsMap[field.name])
        ) {
          formFieldsMap[`${field.name}Id`] = formFieldsMap[field.name].id;
          delete formFieldsMap[field.name];
        }
        // else if (!formFieldsMap[field.name]) {
        //   delete formFieldsMap[field.name];
        // }
      });
      const data = {
        ...this.entityToEdit,
        ...formFieldsMap,
        cuadroGeometriasIds: [
          ...this._mapGeometriasToSave(this.geometrias$.value),
          ...this._mapGeometriasToSave(this.ergonomias$.value),
        ],

        cuadroServiciopinturasIds: this.serviciosDePintura$.value?.map(
          (sdp: any) => {
            return this._mapSDPColoresToSave(sdp);
          }
        ),
      };
      delete data.cuadroGeometrias;
      delete data.cuadroErgonomias;
      delete data.cuadroServiciopinturas;
      delete data.webId;
      delete data.geometriaImagen;
      if (
        this.entityToEdit['geometriaImagen-image'] === null &&
        this.entityToEdit['geometriaImagen-file'] === null
      ) {
        data.geometriaImagen = null;
      }
      if (this.action === 'edit') {
        this._api.updateData(data, this._baseUrl).subscribe(() => {
          this.saveTraducciones();

          if (this.entityToEdit['geometriaImagen-file']) {
            const file = this.entityToEdit['geometriaImagen-file'];
            this._api
              .uploadImage(
                file,
                `${environment.api}${Domains.UploadCuadroImagen}`,
                this._id!
              )
              .subscribe();
          }
          this._router.navigate([
            `${Rutas.ConfiguracionDeMaestros}/${Rutas.Cuadros}/${Rutas.List}`,
          ]);
        });
      } else {
        this._api.addData(data, this._baseUrl).subscribe((res) => {
          this._id = res?.id;
          this.saveTraducciones();

          if (this.entityToEdit['geometriaImagen-file']) {
            const file = this.entityToEdit['geometriaImagen-file'];
            this._api
              .uploadImage(
                file,
                `${environment.api}gp_cuadro/updateGeomPicture`,
                res?.id!
              )
              .subscribe();
          }
          this._router.navigate([
            `${Rutas.ConfiguracionDeMaestros}/${Rutas.Cuadros}/${Rutas.List}`,
          ]);
        });
      }
    } else {
      this.entityForm.markAllAsTouched();
      this.touched$.next(true);
    }
  }

  public configureColors(sdp: any) {
    this._dialog.open(ConfiguracionDeColoresDialog, {
      width: '100%',
      maxWidth: '600px',
      data: { sdp, colores: sdp.cuadroServicioPinturaColores },
      panelClass: 'configure-colors-dialog-container',
    });
  }

  public addServicioDePintura(): void {
    const dialog = this._dialog.open(AddEditCuadroServicioDePinturaPopup, {
      data: { action: 'add', sdpsIncorporados: this.serviciosDePintura$.value },
      width: '400px',
    });
    dialog.afterClosed().subscribe((res) => {
      if (res) {
        if (res.default) {
          this.serviciosDePintura$.value?.forEach(
            (sdp: any) => (sdp.default = 0)
          );
        }
        this._api
          .getDataById(res.servicioPintura.id, SERVICIOS_DE_PINTURA_BASE_URL)
          .pipe(
            tap((sdp: any) => {
              this.serviciosDePintura$.next([
                ...this.serviciosDePintura$.value,
                {
                  servicioPintura: sdp,
                  default: res.default,
                  cuadroServicioPinturaColores:
                    this._loadDefaultServicioDePinturaColores(sdp),
                },
              ]);
            })
          )
          .subscribe();
      }
    });
  }

  public editServicioDePintura(sdp: any): void {
    const dialog = this._dialog.open(AddEditCuadroServicioDePinturaPopup, {
      data: {
        action: 'edit',
        sdp,
        default: sdp.default,
        sdpsIncorporados: this.serviciosDePintura$.value,
      },
      width: '400px',
      height: '400px',
    });
    dialog.afterClosed().subscribe((res) => {
      if (res) {
        sdp.servicioPintura = res.servicioPintura;
        if (res.default) {
          this.serviciosDePintura$.value?.forEach(
            (sdp: any) => (sdp.default = 0)
          );
          sdp.default = 1;
        } else {
          sdp.default = 0;
        }
        this.serviciosDePintura$.next([...this.serviciosDePintura$.value]);
      }
    });
  }

  public deleteServicioDePintura(sdp: any): void {
    const dialog = this._dialog.open(ConfirmDialog, {
      data: {
        texto: `¿Está seguro de que desea eliminar el servicio de pintura ${sdp.servicioPintura?.nombre} del cuadro?`,
      },
    });
    dialog.afterClosed().subscribe((res) => {
      if (res) {
        let index = this.serviciosDePintura$.value.findIndex(
          (s: any) => s.id === sdp.id && !s.deleted
        );
        if (!index) {
          index = this.serviciosDePintura$.value.findIndex(
            (s: any) =>
              s.servicioPintura?.id === sdp.servicioPintura?.id && !s.deleted
          );
        }
        let newSdp = [...this.serviciosDePintura$.value];
        newSdp[index].deleted = 1;
        this.serviciosDePintura$.next([...newSdp]);
      }
    });
  }

  public getNonDeletedSdps() {
    return this.serviciosDePintura$.value?.filter((sdp: any) => !sdp.deleted);
  }

  public fileChange(event: any) {
    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      const file: File = fileList[0];
      this.entityToEdit['geometriaImagen-file'] = file;
      const reader = new FileReader();
      reader.onload = (() => {
        return (e: any) => {
          this.entityToEdit['geometriaImagen-image'] = e.target.result;
        };
      })();
      reader.readAsDataURL(file);
    }
  }

  public updateField(
    dropdownElement: DropdownElement | DropdownElement[],
    field: EntityField
  ): void {
    this.entityForm.controls[field.name].setValue(dropdownElement);
  }

  public deleteImage() {
    const dialogRef = this._dialog.open(ConfirmDialog, {
      data: {
        texto: '¿Está seguro de que desea eliminar la imagen?',
      },
    });
    dialogRef
      .afterClosed()
      .pipe(
        filter((res: boolean) => !!res),
        tap(() => {
          this.entityToEdit['geometriaImagen-image'] = null;
          this.entityToEdit['geometriaImagen-file'] = null;
        })
      )
      .subscribe();
  }

  private _mapGeometriasToSave(geometria: any): any[] {
    return geometria
      .map((g: any) => {
        return g.tallas
          .map((t: any) => {
            if (t.gid && !t.valor) {
              t.deleted = 1;
            }
            return t;
          })
          .filter((t: any) => t.valor || t.deleted)
          .map((t: any) => {
            let tallaResult: any = {
              tallaId: t.id,
              tipoId: g.tipo.id,
              valor: t.valor,
            };
            if (g.id) {
              tallaResult = {
                ...tallaResult,
                id: t.gid,
                deleted: t.deleted,
              };
            }
            return tallaResult;
          });
      })
      .flat();
  }

  private _mapSDPColoresToSave(sdp: any): any {
    return {
      id: sdp.id,
      incrementoPrecio: sdp.incrementoPrecio,
      default: sdp.default,
      servicioPinturaId: sdp.servicioPintura?.id,
      deleted: sdp.deleted ? 1 : 0,
      cuadroServicioPinturaColoresIds: sdp.cuadroServicioPinturaColores
        ?.map((color: any) =>
          color
            .filter((tipoColor: any) => tipoColor.active)
            .map((tipoColor: any) => {
              let tipoColorReturn: any = {
                tipo: tipoColor.tipo,
                default: tipoColor.default,
                colorId: tipoColor.color?.id,
              };
              if (tipoColor.id) {
                tipoColorReturn = { ...tipoColorReturn, id: tipoColor.id };
              }
              return tipoColorReturn;
            })
        )
        .flat(),
    };
  }

  private _loadData() {
    if (this.action === 'edit') {
      this._api
        .getDataById(this._id!, this._baseUrl)
        .subscribe((entity: any) => {
          this.entityToEdit = entity;
          this._buildFieldsArray();
          this._buildForm();
          this._loadServiciosDePintura();
          this._loadGeometriasAndErgonomias();
          this._loadIdiomas();
          this._loadTraducciones();
        });
    } else {
      this.entityToEdit = {};
      this._buildFieldsArray();
      this._buildForm();
      this._loadServiciosDePintura();
      this._loadGeometriasAndErgonomias();
      this._loadIdiomas();
      this._loadTraducciones();
    }
  }

  private _loadServiciosDePintura(): void {
    this._api.getData(COLORES_BASE_URL).subscribe((colores) => {
      this._coloresOriginal = [
        ...colores.filter((color: Color) => !color.bloqueado),
      ];

      this.entityToEdit.cuadroServiciopinturas =
        this.entityToEdit.cuadroServiciopinturas?.map((sdp: any) => {
          colores = this._mapColoresToTableStructure();
          colores = colores.map((color) => {
            return color.map((col: any) => {
              const sdpcolor = sdp.cuadroServicioPinturaColores?.find(
                (sdpc: any) =>
                  sdpc.color?.id === col.color.id && sdpc.tipo === col.tipo
              );
              return sdpcolor ? { ...sdpcolor, active: true } : col;
            });
          });
          return {
            ...sdp,
            cuadroServicioPinturaColores: colores,
          };
        }) || [];
      this.serviciosDePintura$.next(this.entityToEdit.cuadroServiciopinturas);
    });
  }

  private _loadDefaultServicioDePinturaColores(sdp: any): any[] {
    let colores = this._mapColoresToTableStructure();
    colores = colores.map((color: any) => {
      return color.map((col: any) => {
        const sdpcolor = sdp.pinturaColores?.find(
          (sdpc: any) =>
            sdpc.color?.id === col.color.id && sdpc.tipo === col.tipo
        );
        return sdpcolor ? { ...sdpcolor, active: true } : col;
      });
    });
    return [...colores];
  }

  private _mapColoresToTableStructure(): any[] {
    return this._coloresOriginal.map((color) => {
      return [
        { color, default: 0, active: 0, tipo: TiposColor.Fondo },
        { color, default: 0, active: 0, tipo: TiposColor.Color },
        { color, default: 0, active: 0, tipo: TiposColor.Calca1 },
        { color, default: 0, active: 0, tipo: TiposColor.Calca2 },
        { color, default: 0, active: 0, tipo: TiposColor.Acabado },
      ];
    });
  }

  private _loadGeometriasAndErgonomias(): void {
    this._api
      .getData(TIPOS_GEOMETRIA_BASE_URL)
      .pipe(
        tap((tiposGeometrias: any) => {
          combineLatest(
            this._loadErgonomiasCuadro(tiposGeometrias),
            this._loadGeometriasCuadro(tiposGeometrias),
            (ergonomias, geometrias) => ({ ergonomias, geometrias })
          ).subscribe(() => {
            this._loadTallas();
          });
        })
      )
      .subscribe();
  }

  private _loadGeometriasCuadro(tiposGeometrias: any): Observable<any> {
    return this._api.getData(GEOMETRIA_BASE_URL).pipe(
      map((geometrias: any) => {
        return geometrias
          .filter(
            (geometria: any) => geometria.cuadro?.id === this.entityToEdit?.id
          )
          .reduce(this._groupByTallas, []);
      }),
      tap((geometrias: any) => {
        this.geometrias$.next(
          tiposGeometrias
            .filter((tipoGeometria: any) => tipoGeometria.tipo === 'Geo')
            .map((tipoGeometria: any) => {
              const geometriaFound = geometrias.find(
                (geometria: any) => geometria.tipo?.id === tipoGeometria.id
              );
              return (
                geometriaFound || {
                  tallas: tipoGeometria.tallas,
                  tipo: {
                    id: tipoGeometria.id,
                    nombre: tipoGeometria.nombre,
                    numeral: tipoGeometria.numeral,
                    tipo: tipoGeometria.tipo,
                  },
                }
              );
            })
        );
      })
    );
  }

  private _loadErgonomiasCuadro(tiposGeometrias: any): Observable<any> {
    return this._api.getData(GEOMETRIA_BASE_URL).pipe(
      map((geometrias: any) => {
        return geometrias
          .filter(
            (geometria: any) => geometria.cuadro?.id === this.entityToEdit?.id
          )
          .reduce(this._groupByTallas, []);
      }),
      tap((geometrias: any) => {
        this.ergonomias$.next(
          tiposGeometrias
            .filter((tipoGeometria: any) => tipoGeometria.tipo === 'Ergo')
            .map((tipoGeometria: any) => {
              const geometriaFound = geometrias.find(
                (geometria: any) => geometria.tipo?.id === tipoGeometria.id
              );
              return (
                geometriaFound || {
                  tallas: tipoGeometria.tallas,
                  tipo: {
                    id: tipoGeometria.id,
                    nombre: tipoGeometria.nombre,
                    numeral: tipoGeometria.numeral,
                    tipo: tipoGeometria.tipo,
                  },
                }
              );
            })
        );
      })
    );
  }

  private _loadTallas() {
    this._api
      .getData(TALLAS_BASE_URL)
      .pipe(
        tap((tallas: any[]) => {
          this.ergonomiasHeaders = tallas
            ?.sort((a: any, b: any) => a.orden - b.orden)
            .map((tallas: any) => tallas.nombre);
          this.geometriasHeaders = tallas
            ?.sort((a: any, b: any) => a.orden - b.orden)
            .map((tallas: any) => tallas.nombre);
          this._addMissingTallasToGeometrias(tallas);
          this._addMissingTallasToErgonomias(tallas);
          this._orderTallasGeometrias();
          this._orderTallasErgonomias();
        })
      )
      .subscribe();
  }

  private _orderTallasGeometrias() {
    const geos = this.geometrias$.value;
    geos.forEach((geometria: any) => {
      geometria.tallas = geometria.tallas.sort(
        (a: any, b: any) => a.orden - b.orden
      );
    });
    this.geometrias$.next(geos);
  }

  private _orderTallasErgonomias() {
    const ergos = this.ergonomias$.value;
    ergos.forEach((ergonomia: any) => {
      ergonomia.tallas = ergonomia.tallas.sort(
        (a: any, b: any) => a.orden - b.orden
      );
    });
    this.ergonomias$.next(ergos);
  }

  private _addMissingTallasToGeometrias(tallas: any[]) {
    this.geometrias$.next(
      this.geometrias$.value.map((geometria: any) => {
        tallas.forEach((talla: any) => {
          if (!geometria.tallas?.length) geometria.tallas = [];
          if (!geometria.tallas.find((t: any) => t.id === talla.id))
            geometria.tallas.push({ ...talla });
        });
        return { ...geometria };
      })
    );
  }

  private _addMissingTallasToErgonomias(tallas: any[]) {
    this.ergonomias$.next(
      this.ergonomias$.value.map((ergonomia: any) => {
        tallas.forEach((talla: any) => {
          if (!ergonomia.tallas?.length) ergonomia.tallas = [];
          if (!ergonomia.tallas.find((t: any) => t.id === talla.id))
            ergonomia.tallas.push({ ...talla });
        });
        return { ...ergonomia };
      })
    );
  }

  private _groupByTallas(pV: any, cV: any) {
    if (pV.length === 0) {
      if (!cV.tallas?.length)
        cV.tallas = cV.talla?.id
          ? [{ ...cV.talla, valor: cV.valor, gid: cV.id }]
          : [];
      delete cV.talla;
      delete cV.valor;
      delete cV.tallaId;
      return [cV];
    } else {
      const pVmismoTipo = pV.find((v: any) => v.tipo?.id === cV.tipo?.id);
      if (pVmismoTipo) {
        pVmismoTipo.tallas = cV.talla?.id
          ? [
              ...pVmismoTipo.tallas,
              { ...cV.talla, valor: cV.valor, gid: cV.id },
            ]
          : [...pVmismoTipo.tallas];

        delete cV.talla;
        delete cV.valor;
        delete cV.tallaId;
        return pV;
      } else {
        cV.tallas = cV.talla?.id
          ? [{ ...cV.talla, valor: cV.valor, gid: cV.id }]
          : [];
        delete cV.talla;
        delete cV.valor;
        delete cV.tallaId;
        return [...pV, cV];
      }
    }
  }

  private _buildFieldsArray(): void {
    this.fields.forEach((field) => {
      if (field.optionsUrl) {
        this._api
          .getData(field.optionsUrl)
          .pipe(
            map(field.filterOptions || ((item) => item)),
            tap((res: any) => {
              field.options = res;
            })
          )
          .subscribe();
      }
    });
  }

  private _buildForm(): void {
    const formFields: any[] = [];
    this.fields.forEach((field) => {
      formFields.push({
        name: field.name,
        control: new FormControl(
          {
            value: this.entityToEdit && this.entityToEdit[field.name],
            disabled:
              field.disabled ||
              (this.action === 'edit' && field.editDisabled) ||
              false,
          },
          field.required ? [Validators.required] : []
        ),
      });
    });
    formFields.push({
      name: 'geometriaImagen' + '-file',
      control: new FormControl({
        value: '',
      }),
    });
    formFields.push({
      name: 'geometriaImagen' + '-image',
      control: new FormControl({
        value: this.entityToEdit && this.entityToEdit['geometriaImagen'],
      }),
    });
    formFields.push({
      name: 'geometriaImagen',
      control: new FormControl({
        value: '',
      }),
    });
    this.entityForm = new FormGroup({});
    formFields.forEach((formField) => {
      this.entityForm.addControl(formField.name, formField.control);
    });
    this.entityToEdit['geometriaImagen-image'] = this.entityForm.get(
      'geometriaImagen-image'
    )?.value?.value;
  }

  private saveTraducciones(): void {
    this._traducciones = this._traducciones.map((traduccion: Translation) => ({
      ...traduccion,
      registroId: this._id,
    }));
    this._api
      .addData(
        { traducciones: this._traducciones },
        `${environment.api}${Domains.PostTraduccionesMultiple}`
      )
      .subscribe();
  }

  private _loadTraducciones() {
    this.traducciones$
      ?.pipe(
        tap((traducciones: Translation[]) => {
          this._traducciones = traducciones;
          const traduccionesSpanish = traducciones.filter(
            (traduccion: Translation) =>
              traduccion.idiomaId ===
              this.idiomas$?.value?.find(
                (idioma: Language) => idioma.codigo === 'es'
              )?.id
          );
          traduccionesSpanish.forEach((traduccion: Translation) => {
            this.entityForm.patchValue({
              [traduccion.campo]: traduccion.valorVarchar,
            });
          });
        })
      )
      .subscribe();
    this._api
      .getData(
        `${environment.api}${Domains.TraduccionesShared}?table=${Domains.Cuadros}&registerId=${this._id}`
      )
      .subscribe((res) => this.traducciones$.next(res));
  }

  private _loadIdiomas(): void {
    this._api
      .getData(`${environment.api}${Domains.IdiomasShared}`)
      .subscribe((res) => this.idiomas$.next(res));
  }
}
