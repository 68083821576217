import { Component, Inject, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Rutas } from 'src/app/constants/constants';
import { Action } from './warning-popup.constants';

export interface WarningPopupInput {
  errorMessage?: string;
  conflictLines?: any[];
  headerMessage: string;
  continue?: boolean;
  cuadroId?: number;
}

export interface WarningPopupOutput {
  event: string;
}

@Component({
  templateUrl: './warning-popup.component.html',
  styleUrls: ['./warning-popup.component.scss'],
})
export class WarningPopupComponent {
  public cancelled = false;
  public cuadroUrl: string = '';

  public constructor(
    public dialogRef: MatDialogRef<WarningPopupComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: WarningPopupInput
  ) {
    dialogRef.disableClose = true;
    if (this.data?.cuadroId) {
      this.cuadroUrl = `${Rutas.ConfiguracionDeMaestros}/${Rutas.Cuadros}/${Rutas.Edit}/${this.data.cuadroId}`;
    }
  }

  public isAString(val: any): boolean {
    return typeof val === 'string';
  }

  public isAnObject(val: any): boolean {
    return val instanceof Object;
  }

  public closeDialog(): void {
    this.cancelled = true;
    this.dialogRef.close({ event: Action.cancel });
  }

  public confirm(): void {
    this.dialogRef.close({ event: Action.confirm });
  }
}
