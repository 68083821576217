import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Rutas, Labels } from 'src/app/constants/constants';
import { Entity } from 'src/app/shared/entity';
import { User } from 'src/app/constants/models';
import {
  LISTADO_USUARIOS_BASE_URL,
  TABLE_HEADERS_LISTADO_USUARIOS,
} from './usuarios.constants';
import { RedirectionType } from 'ekiba-master-table';
@Component({
  templateUrl: './usuarios.component.html',
})
export class UsuariosComponent extends Entity implements OnInit {
  formattedUsers!: User[];
  public readonly Labels: typeof Labels = Labels;
  public readonly RedirectionType: typeof RedirectionType = RedirectionType;
  public readonly editUrl: string = `${Rutas.Usuarios}/${Rutas.Listado}/${Rutas.Edit}`;

  constructor(private router: Router) {
    super();
  }

  public ngOnInit(): void {
    this.columns = TABLE_HEADERS_LISTADO_USUARIOS;
    this._baseUrl = LISTADO_USUARIOS_BASE_URL;
    this.data$ = this.getData();
    this._loadData();
    this.data$.subscribe((data) => {
      this.formattedUsers = this.formatUsers(data);
    });
  }

  public formatUsers(data: User[]): User[] {
    return data.map((item) => {
      const roles = item.roles.map((role) => role.nombre);
      return { ...item, rolesFormatted: roles };
    });
  }

  public ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  public addUser(): void {
    this.router.navigate([`${Rutas.Usuarios}/${Rutas.Listado}/${Rutas.Add}`]);
  }

  public deleteUser(user: User): void {
    this._deleteData(user);
  }
}
