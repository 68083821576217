<h1 mat-dialog-title>Activar modelos / variantes asociados</h1>
<div mat-dialog-content>
  <p>{{data?.message}}</p>
  <div class="activate-checkbox-container">
    <mat-checkbox [(ngModel)]="activarVariantesB2b"
      >Activar variantes B2b</mat-checkbox
    >
  </div>
  <div class="activate-checkbox-container">
    <mat-checkbox [(ngModel)]="activarVariantesB2c"
      >Activar variantes B2c</mat-checkbox
    >
  </div>
</div>
<div class="buttons-section" mat-dialog-actions>
  <button
    type="submit"
    class="cancel-button"
    mat-raised-button
    color="secondary"
    (click)="closeDialog()"
  >
    Salir
  </button>
  <button
    type="submit"
    class="confirm-button"
    mat-raised-button
    color="primary"
    (click)="confirm()"
  >
    Confirmar
  </button>
</div>
