import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  MatTreeFlatDataSource,
  MatTreeFlattener,
} from '@angular/material/tree';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { Domains, Labels, Status } from 'src/app/constants/constants';
import { Role } from 'src/app/constants/models';
import { Entity } from 'src/app/shared/entity';
import { environment } from 'src/environments/environment';
import {
  PERMISSIONS_BASE_URL,
  UPDATE_ROLE_PERMISSIONS_BASE_URL,
} from './permissions.constants';
import { FlatNode, Node, Permission } from './permissions.models';

const DEFAULT_ROLE_ADMIN = 1;
const ROLES_URL = `${environment.api}${Domains.Roles}`;

@Component({
  selector: 'app-permissions',
  templateUrl: './permissions.component.html',
  styleUrls: ['./permissions.component.scss'],
})
export class PermissionsComponent extends Entity implements OnInit {
  public readonly Labels: typeof Labels = Labels;
  public readonly status = Status;
  public permissionForm: FormGroup | undefined;
  public dataSource!: MatTreeFlatDataSource<Node, FlatNode, FlatNode>;
  public treeControl = new FlatTreeControl<FlatNode>(
    (node) => node.level,
    (node) => node.expandable
  );
  public roles$!: Observable<any[]>;

  private _roles$ = new BehaviorSubject<any[]>([]);
  private _treeFlattener = new MatTreeFlattener(
    this._transformer,
    (node) => node.level,
    (node) => node.expandable,
    (node) => node.children
  );
  private _rolesToUpdate: Role[] = [];
  private _updateTriggeredByRole: boolean = false;

  hasChild = (_: number, node: FlatNode) => node.expandable;
  constructor() {
    super();
  }

  public ngOnInit(): void {
    this.roles$ = this._roles$.asObservable();
    this.api
      .getData(ROLES_URL)
      .pipe(takeUntil(this._destroyed$))
      .subscribe(
        (data: any[]) => this._roles$.next(data),
        (error: any) => console.error(error)
      );
    this.dataSource = new MatTreeFlatDataSource(
      this.treeControl,
      this._treeFlattener
    );
    this.data$ = this.getData();
    this._baseUrl = PERMISSIONS_BASE_URL;
    this._loadData();
    this.data$.subscribe((permissions) => {
      if (permissions && permissions.length && permissions.length > 0) {
        this.dataSource.data = this._groupPermissions(permissions);
        this._buildForm(permissions);
      }
    });
  }

  public ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  public updatePermissions(): void {
    this._rolesToUpdate.forEach((role) => {
      const params = {
        permissionIds: role.permisos?.map((p) => p.id),
        rolId: role.id,
        updateType: 'Overwrite',
      };
      this._baseUrl = UPDATE_ROLE_PERMISSIONS_BASE_URL;
      this._addData(params, false);
      this._baseUrl = PERMISSIONS_BASE_URL;
    });
  }

  private _transformer(node: Node, level: number): FlatNode {
    return {
      expandable: !!node.children && node.children.length > 0,
      nombre: node.nombre,
      level,
      id: node.id!,
      type: !!node.type,
    };
  }

  private _buildForm(permissions: any[]): void {
    this.permissionForm = new FormGroup({
      roleSelected: new FormControl(DEFAULT_ROLE_ADMIN, [Validators.required]),
    });
    permissions.forEach((permission) => {
      this.permissionForm!.addControl(
        `permission${permission.id}`,
        new FormControl(
          this._roleHasPermission(
            permission,
            this.permissionForm?.get('roleSelected')?.value
          )
        )
      );
    });
    this._detectChanges(permissions);
  }

  private _groupPermissions(list: Node[]): Node[] {
    let map: any = {},
      node: Node,
      roots: Node[] = [],
      i;

    for (i = 0; i < list.length; i += 1) {
      // Initialization of permissions map and list
      map[list[i].id!] = i;
      list[i].children = [];
    }

    for (i = 0; i < list.length; i += 1) {
      node = list[i];
      if (node.padreId) {
        list[map[node.padreId]].children.push(node);
        list[map[node.padreId]].type = true;
      } else {
        // If the node is a root (does not have any parent), set it as a root
        roots.push(node);
      }
    }
    return roots;
  }

  private _detectChanges(permissions: any[]): void {
    this.permissionForm!.controls.roleSelected.valueChanges.pipe(
      takeUntil(this._destroyed$),
      filter((roleId) => roleId !== null || roleId !== undefined),
      tap((roleId: number) => {
        this._updateTriggeredByRole = true;
        permissions.forEach((permission: any) => {
          this.permissionForm?.controls[`permission${permission.id}`].setValue(
            this._roleHasPermission(permission, roleId)
          );
          this.permissionForm?.updateValueAndValidity();
        });
        this._updateTriggeredByRole = false;
      })
    ).subscribe();

    permissions.forEach((permission: Permission) => {
      this.permissionForm!.controls[
        `permission${permission.id}`
      ].valueChanges.pipe(
        filter(() => !this._updateTriggeredByRole),
        takeUntil(this._destroyed$),
        tap(() => {
          const roleId = this.permissionForm!.controls.roleSelected.value;
          const role = this._roles$.value.find((rol) => rol.id == roleId);
          const permissionIndex = role.permisos.findIndex(
            (p: Permission) => p.id === permission.id
          );

          if (permissionIndex != -1) {
            // Role has permission. Remove role from permission.
            role.permisos.splice(permissionIndex, 1);
          } else {
            // Role does not have permission. Add role to permission.
            role.permisos.push(permission);
          }
          const updatedRoleIndex = this._rolesToUpdate.findIndex(
            (r) => r.id === role.id
          );
          if (updatedRoleIndex !== -1) {
            this._rolesToUpdate.splice(updatedRoleIndex, 1);
          }
          this._rolesToUpdate.push(role);
        })
      ).subscribe();
    });
  }

  private _roleHasPermission(permission: any, roleId: any): any {
    const rol = this._roles$.value.find((rol) => rol.id == roleId);
    return !!rol?.permisos?.find((p: any) => p.id === permission.id);
  }
}
