import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/internal/operators/tap';
import { GenericAddEntitityOutput } from 'src/app/constants/models';
import { LoginService } from '../pages/login/login.service';

@Injectable({
  providedIn: 'root',
})
export class EntityApiCalls {
  private _headers: HttpHeaders = new HttpHeaders();

  constructor(
    private readonly _http: HttpClient,
    private _login: LoginService
  ) {
    this._login.logged$
      .pipe(
        tap((logged) => {
          if (logged.logged) {
            this._headers = new HttpHeaders({
              'x-access-token': logged.berriaGestorDeProductosToken,
            });
          }
        })
      )
      .subscribe();
  }

  public addData(data: any, url: string): Observable<GenericAddEntitityOutput> {
    return this._http.post<GenericAddEntitityOutput>(url, data, {
      headers: this._headers,
    });
  }

  public deleteData(data: any, url: string): Observable<any> {
    return this._http.delete(`${url}/${data.id}`, {
      headers: this._headers,
    });
  }

  public getData(url: string): Observable<any[]> {
    return this._http.get<any[]>(url, {
      headers: this._headers,
    });
  }

  public getDataById(id: number, url: string): Observable<any> {
    return this._http.get<any>(`${url}/${id}`, {
      headers: this._headers,
    });
  }

  public updateData(data: any, url: string): Observable<any> {
    return this._http.put(`${url}/${data.id}`, data, {
      headers: this._headers,
    });
  }

  public uploadImage(image: File, url: string, id: number): Observable<any> {
    const formData = new FormData();
    formData.append('imagen', image);
    formData.append('id', id.toString());
    return this._http.post(`${url}`, formData, {
      headers: {
        'x-access-token': this._headers.get('x-access-token')!,
      },
    });
  }
}
