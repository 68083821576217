import { ColumnTypes, TableHeaders } from 'ekiba-master-table';
import { Domains, FieldTypes } from 'src/app/constants/constants';
import { AbstractBerria } from 'src/app/constants/models';
import { environment } from 'src/environments/environment';
import {
  EntityField,
  EntityFieldGroup,
} from '../../add-edit-entity/add-edit-entity.component';

export const TABLE_HEADERS_COMPONENTES: TableHeaders[] = [
  {
    name: 'id',
    displayName: 'ID',
    type: ColumnTypes.Standard,
  },
  {
    name: 'codigo',
    displayName: 'Código',
    type: ColumnTypes.Standard,
  },
  {
    name: 'tipo',
    displayName: 'Tipo',
    type: ColumnTypes.Selector,
    selectFilterOptions: [
      { id: 21, nombre: 'Cambio' },
      { id: 1, nombre: 'Manetas' },
      { id: 2, nombre: 'Piñones' },
      { id: 3, nombre: 'Cadena' },
      { id: 4, nombre: 'Biela' },
      { id: 5, nombre: 'Frenos' },
      { id: 6, nombre: 'Llanta' },
      { id: 7, nombre: 'Neumático' },
      { id: 8, nombre: 'Radios' },
      { id: 9, nombre: 'Horquilla' },
      { id: 10, nombre: 'Material' },
      { id: 11, nombre: 'Manillar' },
      { id: 12, nombre: 'Potencia' },
      { id: 13, nombre: 'Tija sillín' },
      { id: 14, nombre: 'Sillín' },
      { id: 15, nombre: 'Rear Shox' },
      { id: 16, nombre: 'Patilla' },
      { id: 17, nombre: 'Motor' },
      { id: 18, nombre: 'Bateria' },
      { id: 19, nombre: 'Pantalla' },
      { id: 20, nombre: 'Cargador' },
      { id: 21, nombre: 'Desviador' },
    ],
  },
  {
    name: 'descripcion',
    displayName: 'Descripción',
    type: ColumnTypes.Standard,
  },
];

export const COMPONENTES_BASE_URL: string = `${environment.api}${Domains.Componentes}`;

export const COMPONENTES_FIELDS: EntityField[] = [
  {
    name: 'codigo',
    displayName: 'Código',
    type: FieldTypes.Standard,
    disabled: true,
    group: 1,
  },
  {
    name: 'navId',
    disabled: true,
    displayName: 'ERP ID',
    type: FieldTypes.Standard,
    group: 1,
  },
  {
    name: 'webId',
    disabled: true,
    displayName: 'Web ID',
    type: FieldTypes.Standard,
    group: 1,
  },
  {
    name: 'filler1',
    displayName: 'Filler',
    type: FieldTypes.Filler,
    group: 1,
  },
  {
    name: 'descripcion',
    displayName: 'Descripción',
    type: FieldTypes.TranslatableTextarea,
    group: 1,
  },
  {
    name: 'tipo',
    displayName: 'Tipo',
    type: FieldTypes.Selector,
    isSingleSelector: true,
    options: [
      { id: 21, nombre: 'Cambio' },
      { id: 1, nombre: 'Manetas' },
      { id: 2, nombre: 'Piñones' },
      { id: 3, nombre: 'Cadena' },
      { id: 4, nombre: 'Biela' },
      { id: 5, nombre: 'Frenos' },
      { id: 6, nombre: 'Llanta' },
      { id: 7, nombre: 'Neumático' },
      { id: 8, nombre: 'Radios' },
      { id: 9, nombre: 'Horquilla' },
      { id: 10, nombre: 'Material' },
      { id: 11, nombre: 'Manillar' },
      { id: 12, nombre: 'Potencia' },
      { id: 13, nombre: 'Tija sillín' },
      { id: 14, nombre: 'Sillín' },
      { id: 15, nombre: 'Rear Shox' },
      { id: 16, nombre: 'Patilla' },
      { id: 17, nombre: 'Motor' },
      { id: 18, nombre: 'Bateria' },
      { id: 19, nombre: 'Pantalla' },
      { id: 20, nombre: 'Cargador' },
      { id: 21, nombre: 'Desviador' },
    ],
    required: true,
    group: 2,
  },
];

export const COMPONENTES_GROUPS: EntityFieldGroup[] = [
  {
    label: 'Información general',
    id: 1,
  },
  {
    label: 'Información específica',
    id: 2,
  },
];

export interface Componente extends AbstractBerria {
  descripcion: string;
  tipo: any;
  grupo: string;
}
