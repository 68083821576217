import { Component, Inject, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  Categoria,
  CATEGORIAS_BASE_URL,
} from 'src/app/pages/configuracion-de-productos/categorias/categorias.constants';
import { EntityApiCalls } from 'src/app/shared/entity.api-calls';

export interface AddEditProductoCategoriaPopupInput {
  action: string;
  categoria: any;
}

export interface AddEditProductoCategoriaPopupOutput {
  categoria: any;
}

@Component({
  templateUrl: './add-edit-producto-categoria.component.html',
  styleUrls: ['./add-edit-producto-categoria.component.scss'],
})
export class AddEditProductoCategoriaPopup {
  categoria: any;
  categorias$: Observable<any> | undefined;
  orden: number = 0;

  public constructor(
    public dialogRef: MatDialogRef<AddEditProductoCategoriaPopup>,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: AddEditProductoCategoriaPopupInput,
    private _api: EntityApiCalls
  ) {
    this.categorias$ = this._api.getData(CATEGORIAS_BASE_URL).pipe(
      map((categorias: any) => {
        return categorias
          .filter(
            (categoria: any) =>
              !this._categoriaHasChildren(categoria, categorias)
          )
          .map((categoria: any) => {
            return {
              nombre: this._getNombreCompletoCategoria(
                categoria.id,
                categorias
              ),
              categoriaId: categoria.id,
            };
          });
      })
    );
    this.categoria = this.data.categoria;
    this.orden = this.categoria?.orden;
  }

  public closeDialog(): void {
    this.dialogRef.close(false);
  }

  public confirm(): void {
    this.dialogRef.close({
      categoria: { ...this.categoria, orden: this.orden },
    });
  }

  public objectComparisonFunction(item: any, selected: any) {
    return typeof item === 'object'
      ? item.categoriaId === selected.categoriaId
      : item === selected;
  }

  private _categoriaHasChildren(categoria: any, categorias: any) {
    return !!categorias.find((c: any) => c.padreId === categoria.id);
  }

  private _getNombreCompletoCategoria(categoriaId: any, categorias: any) {
    let categoria: Categoria;
    categoria = categorias.find((c: Categoria) => c.id === categoriaId)!;
    let nombre = categoria?.nombre;
    if (categoria?.padre) {
      nombre = `${this._getNombreCompletoCategoria(
        categoria.padreId,
        categorias
      )} > ${nombre}`;
    }
    return nombre;
  }
}
