import { Component, OnInit } from '@angular/core';
import { CustomAction } from 'ekiba-master-table';

import { Labels, Rutas, SKIP_AUTH } from 'src/app/constants/constants';
import { AuthService } from 'src/app/shared/auth.service';
import { Entity } from 'src/app/shared/entity';
import { PageIndexerService } from 'src/app/shared/page-indexer.service';
import {
  GrupoClientes,
  GRUPO_CLIENTES_BASE_URL,
  TABLE_HEADERS_GRUPO_CLIENTES,
} from './grupos-clientes.constants';

@Component({
  templateUrl: './grupos-clientes.component.html',
})
export class GruposClientesComponent extends Entity implements OnInit {
  public formattedGruposClientes!: GrupoClientes[];
  public readonly Labels: typeof Labels = Labels;
  public readonly Rutas: typeof Rutas = Rutas;
  public readonly authURL: string = `${Rutas.GestionDeClientes}/${Rutas.GrupoClientes}`;
  public readonly SKIP_AUTH: boolean = SKIP_AUTH;
  public readonly customActions: CustomAction[] = [
    {
      iconName: 'visibility',
      labelName: 'Detalle',
      idAction: 0,
      redirection: `${Rutas.GestionDeClientes}/${Rutas.GrupoClientes}/${Rutas.View}`,
      index: 'id',
    },
  ];

  constructor(
    public auth: AuthService,
    public pageIndexer: PageIndexerService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.columns = TABLE_HEADERS_GRUPO_CLIENTES;
    this._baseUrl = GRUPO_CLIENTES_BASE_URL;
    this.data$ = this.getData();
    this._loadData();
    this.data$.subscribe((data) => {
      this.formattedGruposClientes = this.formatGruposClientes(data);
    });
  }

  public formatGruposClientes(data: GrupoClientes[]): GrupoClientes[] {
    return data.map((item) => {
      return {
        ...item,
      };
    });
  }

  public ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }
}
