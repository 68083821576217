import { Component, OnInit } from '@angular/core';
import { CustomAction } from 'ekiba-master-table';

import { Labels, Rutas, SKIP_AUTH } from 'src/app/constants/constants';
import { AuthService } from 'src/app/shared/auth.service';
import { Entity } from 'src/app/shared/entity';
import { PageIndexerService } from 'src/app/shared/page-indexer.service';
import {
  Cliente,
  CLIENTES_BASE_URL,
  TABLE_HEADERS_CLIENTES,
} from './clientes.constants';

@Component({
  templateUrl: './clientes.component.html',
})
export class ClientesComponent extends Entity implements OnInit {
  public formattedClientes!: Cliente[];
  public readonly Labels: typeof Labels = Labels;
  public readonly Rutas: typeof Rutas = Rutas;
  public readonly authURL: string = `${Rutas.GestionDeClientes}/${Rutas.Clientes}`;
  public readonly SKIP_AUTH: boolean = SKIP_AUTH;
  public readonly customActions: CustomAction[] = [
    {
      iconName: 'visibility',
      labelName: 'Detalle',
      idAction: 0,
      redirection: `${Rutas.GestionDeClientes}/${Rutas.Clientes}/${Rutas.View}`,
      index: 'id',
    },
  ];

  constructor(
    public auth: AuthService,
    public pageIndexer: PageIndexerService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.columns = TABLE_HEADERS_CLIENTES;
    this._baseUrl = CLIENTES_BASE_URL;
    this.data$ = this.getData();
    this._loadData();
    this.data$.subscribe((data) => {
      this.formattedClientes = this.formatClientes(data);
    });
  }

  public formatClientes(data: Cliente[]): Cliente[] {
    return data.map((item) => {
      return {
        ...item,
        direcciones: item.direcciones?.map(
          (direccion: any) => direccion.nombre
        ),
      };
    });
  }

  public ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }
}
