<!-- Loading spinner -->
<div *ngIf="loading" class="loading-container">
  <mat-progress-spinner color="primary" mode="indeterminate">
  </mat-progress-spinner>
</div>

<ekiba-navbar
  logoSrc="/assets/img/logo-berria.svg"
  [showHomeIcon]="false"
  [homeRoute]="'inicio'"
  [menuItems]="menuItems"
  [name]="userData.nombre"
  [role]="userData.roles[0].nombre"
  (requestLogout)="logOut()"
  *ngIf="!hasError(); else Error"
  [activeMenuItem]="navbarService.activeMenuItem$"
>
</ekiba-navbar>

<ng-template #Error>
  <router-outlet></router-outlet>
</ng-template>
